import { Card, Container, Loader, SimpleGrid, Text } from "@mantine/core";
import { IconCategory } from "@tabler/icons";
import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import CatelogueContext from "../../../ContextFolder/CatelogueContext/CatelogueContext";
import useStyles from "../../../Components/Style/UseStyle";
import texture from "../../../Images/Texture.svg";
import { useState } from "react";
import "../../../App.css";
// import _ from "lodash";
import { useParams } from "react-router-dom";

function Designaccess({ name }) {
  const context = useContext(CatelogueContext);
  const { id } = useParams();
  const { classes } = useStyles();
  // const [size, setSize] = useState();
  const [loader, setLoader] = useState(false);
  // const [currentpage, setCurrentpage] = useState(1);
  // const [libraries, setLibraries] = useState([]);
  // console.log(name,"kk")
  // const [number, setNumber] = useState();

  const [posts, setPosts] = useState();
  useEffect(() => {
    const userlibrarydata = async () => {
      setLoader(true);
      const result = await context.getallUserDesigninMylibraryPage(id);

      setPosts(result.designs);
      // setNumber(result.count);
      // setSize(result.designs.length);
      setLoader(false);
    };
    userlibrarydata();
    // eslint-disable-next-line
  }, []);

  // const pageCount = number ? Math.ceil(number / size) : 0;
  // const pages = _.range(1, pageCount + 1);

  // const handleclick = async (num) => {
  //   const g = await context.getallDesign(num, libraries, searchstring);
  //   setPosts(g.designs);
  //   setCurrentpage(num);
  // };

  // const handleNext = async (num) => {
  //   const g = await context.getallDesign(num, libraries, searchstring);
  //   setPosts(g.designs);
  //   setCurrentpage(num);
  // };

  // const handlePrev = async (num) => {
  //   const g = await context.getallDesign(num, libraries, searchstring);
  //   setPosts(g.designs);
  //   setCurrentpage(num);
  // };

  const features = posts?.map((feature) => {
    let blob = new Blob([feature.patternImage], { type: "image/svg+xml" });
    let url = URL.createObjectURL(blob);
    // console.log(feature.libraries[0].name,"jiji")
    return (
      <div>
        <Card key={feature.id} shadow="md" radius="md" className={classes.card}>
          <div style={{ cursor: "pointer" }} className="container">
            <Card.Section className={classes.imageSection}>
              {" "}
              <div
                style={{
                  width: "100%",
                  borderRadius: "4px",
                  height: "100%",
                  backgroundImage: `url(${url})`,
                }}
              >
                <img
                  style={{
                    width: "100%",
                    borderRadius: "4px",
                    height: "100%",
                  }}
                  src={texture}
                  alt="not found"
                />
              </div>
            </Card.Section>
            <Text style={{ color: "#000066", fontSize: "16px" }} weight={400}>
              {feature.title}
            </Text>{" "}
          </div>
          <hr style={{ color: "#A5BBE2" }} />
          <div className={classes.carddisplay}>
            <div className={classes.carddisplay}>
              <IconCategory color="#A5BBE2" />

              <Text
                style={{
                  color: "#000066",
                  fontSize: "14px",
                  marginLeft: "10px",
                }}
                weight={500}
              >
                {feature?.libraries[0]?.name}
              </Text>
            </div>
          </div>
        </Card>
      </div>
    );
  });
  return (
    <div className={classes.backgroundColor} style={{ height: "100%" }}>
      <Container fluid py="xl" style={{ padding: "22px" }}>
        <Text className={classes.catalogue}>Library: {name} </Text>
        <div className={classes.navtype}>
          {loader === true ? (
            <Container py="xl" mt={100}>
              {" "}
              <Loader color="orange" size="xl" variant="dots" />
            </Container>
          ) : (
            <div style={{ display: "grid", minHeight: "90vh" }}>
              <div>
                <SimpleGrid
                  cols={4}
                  spacing="lg"
                  mt={20}
                  breakpoints={[
                    { maxWidth: "md", cols: 2 },
                    { maxWidth: "sm", cols: 1 },
                  ]}
                >
                  {features}
                </SimpleGrid>
              </div>
            </div>
          )}
        </div>
      </Container>
      {/* <div style={{ marginTop: "40px", marginBottom: "10px" }}>
        <nav className="d-flex justify-content-center">
          {pageCount === 1 ? null : (
            <ul className="pagination">
              {currentpage === 1 ? (
                <li style={{ fontSize: "16px", backgroundColor: "#B0C6F4" }}>
                  {" "}
                  &laquo;
                </li>
              ) : (
                <li
                  style={{ cursor: "pointer", fontSize: "16px" }}
                  onClick={() => {
                    handlePrev(currentpage - 1);
                  }}
                >
                  &laquo;
                </li>
              )}
              {pageCount === 1
                ? null
                : pages.map((num) => (
                    <>
                      <li
                        className={num === currentpage ? "active" : ""}
                        style={{ cursor: "pointer", fontSize: "16px" }}
                        onClick={() => {
                          handleclick(num);
                          setCurrentpage(num);
                        }}
                      >
                        {num}
                      </li>
                    </>
                  ))}

              {pages.length === currentpage ? (
                <li style={{ fontSize: "16px", backgroundColor: "#B0C6F4" }}>
                  &raquo;
                </li>
              ) : (
                <li
                  style={{ cursor: "pointer", fontSize: "16px" }}
                  onClick={() => {
                    handleNext(currentpage + 1);
                  }}
                >
                  &raquo;
                </li>
              )}
            </ul>
          )}
        </nav>
      </div> */}
    </div>
  );
}

export default Designaccess;
