import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LibraryContext from "./LibraryContext";

function LibraryState(props) {
  const [lib, setLib] = useState();
  const [svgDimensions, setSvgDimensions] = useState({
    width: null,
    height: null,
  });
  const navigate = useNavigate();
  const limit = 10;
  const getlibrary = async (num) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/libraries?limit=${limit}&offset=${num}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(),
        }
      );
      const libraries = await response.json();
      return libraries;
    } catch {
      console.log("library Not Found");
      navigate("/login");
    }
  };
  const getlibraryforSomepage = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/libraries`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(),
        }
      );
      const libraries = await response.json();
      return libraries;
    } catch {
      console.log("library Not Found");
      navigate("/login");
    }
  };

  const getlibByid = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/libraries/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const res = await response.json();

      return res;
    } catch {
      navigate("/login");
    }
  };
  const updatelib = async (id, name, status, data) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/libraries/${id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ name, status }),
        }
      );

      const a = await response.json();
      return a;
      // for (let index = 0; index < data.length; index++) {
      //   const element = data[index];

      //   if (element.id === id) {
      //     element.name = name;
      //   }
      // }
    } catch {
      navigate("/login");
    }
  };
  const deletelib = async (id, data) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/libraries/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      await response.json();

      const afterDelete = data.filter((color) => {
        return color.id !== id;
      });

      return afterDelete;
    } catch {
      navigate("/login");
    }
  };

  const addlibrary = async (name, status) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/libraries`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ name, status }),
        }
      );
      const libraries = await response.json();

      setLib(libraries);
    } catch {
      console.log("library Not Found");
      navigate("/login");
    }
  };
  return (
    <LibraryContext.Provider
      value={{
        getlibrary,
        lib,
        addlibrary,
        deletelib,
        updatelib,
        getlibByid,
        getlibraryforSomepage,
        setSvgDimensions,
        svgDimensions,
      }}
    >
      {props.children}
    </LibraryContext.Provider>
  );
}

export default LibraryState;
