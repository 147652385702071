import React from "react";

const ChevronUp = ({
  stroke = "#636364",
  width = 33,
  height = 32,
  className = "",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 32"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4998 28.3334C23.3113 28.3334 28.8332 22.8116 28.8332 16.0001C28.8332 9.18857 23.3113 3.66675 16.4998 3.66675C9.68832 3.66675 4.1665 9.18857 4.1665 16.0001C4.1665 22.8116 9.68832 28.3334 16.4998 28.3334Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8315 18.352L16.4996 13.6482L22.1677 18.352"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChevronUp;
