import React from "react";

const LibraryIcon = ({
  stroke = "white",
  width = 33,
  height = 33,
  className = "",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 33"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.0601 28.8334H7.94015C5.58015 28.8334 3.91348 26.5534 4.20682 23.8601L5.98015 7.54008C6.18015 5.64675 7.86015 4.16675 9.72682 4.16675H23.2735C25.1401 4.16675 26.8201 5.64675 27.0201 7.54008L28.7935 23.8601C29.0868 26.5534 27.4201 28.8334 25.0601 28.8334Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.89551 20.2468H6.60654H9.41987H11.0199C12.0599 20.2468 12.9265 20.8735 13.4199 21.7668C14.0065 22.8335 15.1665 23.5668 16.4999 23.5668C17.8332 23.5668 18.9932 22.8468 19.5799 21.7668C20.0599 20.8735 20.9399 20.2468 21.9799 20.2468H23.5799H26.3932H28.1098"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 8.26001V16.4733"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 16.4999L19.7933 13.2065"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4999 16.4999L13.2065 13.2065"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LibraryIcon;
