import React from "react";
import { useState } from "react";
import useStyles from "../../../Style/UseStyle";
import { TextInput } from "@mantine/core";
import SearchIcon from "../../../common/iconComponents/SearchIcon";

function Searchbar({ setSearchstring }) {
  const [search, setSearch] = useState("");

  const onChange = (e) => {
    setSearch(e.target.value);
    setSearchstring(e.target.value);
  };

  return (
    <TextInput
      radius={8}
      value={search}
      name="name"
      onChange={onChange}
      size="sm"
      style={{ height: "32px" }}
      styles={{
        root: { width: "100%" },
        input: {
          backgroundColor: "#F5F5F5",
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "19.2px",
          "::placeholder": { color: "#636364" },
          border: "1px solid #636364",
        },
      }}
      type="search"
      placeholder="Search design"
      aria-label="Search"
    />
  );
}

export default Searchbar;
