import { Text, useMantineTheme } from "@mantine/core";
import React, { useEffect, useRef, useState, useContext } from "react";
import useStyles from "../../Components/Style/UseStyle";
import { useForm, zodResolver } from "@mantine/form";
import {
  TextInput,
  PasswordInput,
  Group,
  Button,
  Divider,
  Image,
} from "@mantine/core";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { IconDownload, IconX } from "@tabler/icons";
import { useNavigate } from "react-router-dom";
import resetFormSchema from "../../Components/lockscreen/ValidationLogic/resetFormSchema";
import AddAccountSchema from "../../Components/lockscreen/ValidationLogic/AddAccountSchema";
import UserContext from "../../ContextFolder/UserContext/UserContext";
import uploadCloudIcon from "../../Images/svg/uploadCloudIcon.svg";

const UserAccountPage = () => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const context = useContext(UserContext);
  const [filesource, setFileSource] = useState();
  const [id, setId] = useState();
  const [data, setdata] = useState();
  const [active, setActive] = useState(false);

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    setId(userId);
    const userdata = async () => {
      const userdata = await context.getprofile();
      useracc.setValues({
        name: userdata?.fullName,
        email: userdata?.email,
      });
    };
    userdata();
  }, []);

  const useracc = useForm({
    validateInputOnChange: true,
    validate: zodResolver(AddAccountSchema),
    initialValues: {
      email: "",
      name: "",
    },
  });
  const resetpass = useForm({
    validateInputOnChange: true,
    validate: zodResolver(resetFormSchema),
    initialValues: {
      password: "",
      confirmPassword: "",
    },
  });
  const resetPassword = async (password) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/auth/user-profile`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ password }),
        }
      );
      await response.json();
    } catch {
      navigate("/login");
    }
  };

  const addlogo = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/auth/avatar/${id}`,
        {
          method: "POST",
          headers: {
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
          body: formData,
        }
      );
      await response.json();
    } catch {
      navigate("/login");
    }
  };

  const { classes, cx } = useStyles();
  const openRef = useRef(null);

  return (
    <div className="flex flex-col py-9 lg:flex-row  gap-y-3  lg:gap-x-11  lg:justify-center items-center lg:items-start">
      <div className="space-y-9 w-11/12 py-8 md:w-7/12 lg:w-[40%] xl:w-1/4  flex flex-col items-center">
        <Text className="text-4xl text-center">Account Details</Text>
        <form className="space-y-5 w-full" encType="multipart/form-data">
          <TextInput
            radius={8}
            label="Name:"
            styles={{
              input: {
                border: "1px solid #636364",
                color: "#636364",
                backgroundColor: "#F5F5F5",
                "::placeholder": { color: "#636364" },
              },
              label: {
                marginBottom: "13px",
                fontSize: "16px",
                lineHeight: "19.2px",
                fontWeight: 400,
                color: "#111827",
              },
            }}
            {...useracc.getInputProps("name")}
            readOnly
            size="lg"
          />
          <TextInput
            size="lg"
            label="Email:"
            radius={8}
            styles={{
              input: {
                border: "1px solid #636364",
                color: "#636364",
                backgroundColor: "#F5F5F5",
                "::placeholder": { color: "#636364" },
              },
              label: {
                marginBottom: "13px",
                fontSize: "16px",
                lineHeight: "19.2px",
                fontWeight: 400,
                color: "#111827",
              },
            }}
            {...useracc.getInputProps("email")}
            readOnly
          />
          <Text className="text-base">Upload Logo:</Text>
          {!filesource ? (
            <Dropzone
              maxSize={15000}
              openRef={openRef}
              onReject={(files) => {
                setActive(true);
              }}
              styles={{
                root: {
                  backgroundColor: "#F5F5F5",
                  border: "1px solid #636364",
                  color: "#636364",
                },
              }}
              onDrop={(acceptedFiles) => {
                acceptedFiles.map((file) => {
                  setdata(file); //API call
                  setFileSource(URL.createObjectURL(file));
                });
              }}
              className={cx({
                [classes.dropzoneActive]: active,
              })}
              radius={8}
              accept={[
                MIME_TYPES.png,
                MIME_TYPES.jpeg,
                MIME_TYPES.gif,
                MIME_TYPES.webp,
              ]}
            >
              <div style={{ pointerEvents: "none" }} className="space-y-5">
                <Group mt={25} position="center">
                  <Dropzone.Reject>
                    <IconX
                      size={50}
                      stroke={1.5}
                      color={
                        theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]
                      }
                    />
                  </Dropzone.Reject>
                  <Dropzone.Accept>
                    <IconDownload color="#D79963" size={30} />
                  </Dropzone.Accept>
                  <Dropzone.Idle>
                    <Image src={uploadCloudIcon} width={32} />
                  </Dropzone.Idle>
                </Group>

                <Text className="text-comet font-bold text-base text-center">
                  Click here to upload or drag and drop
                </Text>
                <Text className="text-base  text-comet text-center">
                  Supports: PNG, maximum size 150 px width and 50 px height
                </Text>
              </div>
            </Dropzone>
          ) : (
            <img src={filesource} alt="not found" height="150px" width="100%" />
          )}
          <Button
            radius={8}
            className="bg-whiskeySour  text-base  w-full h-13"
            onClick={async () => {
              await addlogo(data);
              window.location.reload();
            }}
          >
            Save
          </Button>
        </form>
      </div>
      <Divider
        variant="solid"
        className="bg-comet h-[1.7888px] w-11/12 md:w-5/6 flex lg:hidden "
        orientation="horizontal"
      />
      <Divider
        className="bg-comet w-[1.7888px] hidden lg:flex"
        orientation="vertical"
      />
      <form
        className="flex w-11/12 space-y-9 py-8  lg:w-[40%] xl:w-1/4  md:w-7/12 flex-col items-center"
        onSubmit={resetpass.onSubmit(async (values) => {
          await resetPassword(values.confirmPassword);
          alert("password Updated");
          resetpass.setValues({ password: "", confirmPassword: "" });
        })}
      >
        <Text className="text-4xl text-center">Password</Text>
        <div className="flex flex-col space-y-5 w-full ">
          <PasswordInput
            styles={{
              label: {
                marginBottom: "13px",
                fontSize: "16px",
                lineHeight: "19.2px",
                fontWeight: 400,
                color: "#111827",
              },
              innerInput: {
                "::placeholder": {
                  color: "#636364",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "19.2px",
                },
              },
              input: {
                backgroundColor: "#F5F5F5",
                border: "1px solid #636364",
              },
              visibilityToggle: { display: "none" },
            }}
            label="New Password:"
            placeholder="********"
            size="lg"
            radius={8}
            className="w-full"
            {...resetpass.getInputProps("password")}
          />
          <PasswordInput
            radius={8}
            styles={{
              label: {
                marginBottom: "13px",
                fontSize: "16px",
                lineHeight: "19.2px",
                fontWeight: 400,
                color: "#111827",
              },
              innerInput: {
                "::placeholder": {
                  color: "#636364",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "19.2px",
                },
              },
              input: {
                backgroundColor: "#F5F5F5",
                border: "1px solid #636364",
              },
              visibilityToggle: { display: "none" },
            }}
            label="Confirm Password:"
            placeholder="********"
            size="lg"
            className="w-full"
            {...resetpass.getInputProps("confirmPassword")}
          />
          <Button
            radius={8}
            className="bg-whiskeySour text-base w-full h-13"
            type="submit"
          >
            Update Password
          </Button>
        </div>
      </form>
    </div>
  );
};

export default UserAccountPage;

{
  /* <div className={classes.navtype} style={{ marginBottom: "20px" }}>
          <Text className={classes.catalogue}>My Account</Text>
        </div> */
}
// const { classes, cx } = useStyles();

{
  /* <Button
mt="md"
variant="outline"
style={{ padding: "10px" }}
onClick={() => {
  navigate("/ArticleCard");
}}
>
Back
</Button> */
}

// const [image, setImages] = useState();

// setImages(
//   // eslint-disable-next-line
//   acceptedFiles.map((file) => {
//     setdata(file); //API call
//     setFileSource(URL.createObjectURL(file));
//   })
// );
