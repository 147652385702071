import React from "react";

const EyeIcon = ({
  stroke = "white",
  width = 33,
  height = 33,
  className = "",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 33"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.2994 12.9494C29.1661 15.2027 29.1661 18.6694 27.2994 20.9227C24.6327 24.136 20.726 26.1894 16.366 26.1894C12.006 26.1894 8.1127 24.1494 5.4327 20.9227C3.56603 18.6694 3.56603 15.2027 5.4327 12.9494C8.09937 9.73602 12.006 7.68269 16.366 7.68269C20.726 7.68269 24.6194 9.72269 27.2994 12.9494Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.366 21.0427C18.6341 21.0427 20.4727 19.2041 20.4727 16.936C20.4727 14.668 18.6341 12.8294 16.366 12.8294C14.098 12.8294 12.2594 14.668 12.2594 16.936C12.2594 19.2041 14.098 21.0427 16.366 21.0427Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EyeIcon;
