import React from "react";

const CloseButtonIcon = ({
  stroke = "white",
  width = 32,
  height = 32,
  className = "",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 28.3333C22.8115 28.3333 28.3333 22.8115 28.3333 16C28.3333 9.18845 22.8115 3.66663 16 3.66663C9.18845 3.66663 3.66663 9.18845 3.66663 16C3.66663 22.8115 9.18845 28.3333 16 28.3333Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2533 13.26L18.7466 18.74"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7466 13.26L13.2533 18.74"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CloseButtonIcon;
