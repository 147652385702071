import React from "react";

const ShareIcon = ({
  stroke = "#636364",
  width = 21,
  height = 26,
  className = "",
  onClick = () => {},
}) => {
  return (
    <svg
      width={width}
      onClick={onClick}
      height={height}
      viewBox="0 0 21 26"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.92964 15.6933C5.46131 15.6933 6.70297 14.4875 6.70297 13C6.70297 11.5125 5.46131 10.3066 3.92964 10.3066C2.39797 10.3066 1.15631 11.5125 1.15631 13C1.15631 14.4875 2.39797 15.6933 3.92964 15.6933Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3697 6.33344C18.8571 6.33344 20.063 5.12758 20.063 3.6401C20.063 2.15261 18.8571 0.946777 17.3697 0.946777C15.8822 0.946777 14.6763 2.15261 14.6763 3.6401C14.6763 5.12758 15.8822 6.33344 17.3697 6.33344Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3697 25.0534C18.8571 25.0534 20.063 23.8476 20.063 22.3601C20.063 20.8726 18.8571 19.6667 17.3697 19.6667C15.8822 19.6667 14.6763 20.8726 14.6763 22.3601C14.6763 23.8476 15.8822 25.0534 17.3697 25.0534Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.91632 10.9202L14.383 5.72021"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.383 20.2801L6.91632 15.0801"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ShareIcon;
