import React, { useContext, useEffect, useRef, useState } from "react";
import useStyles from "../../Components/Style/UseStyle";
import {
  Text,
  TextInput,
  Button,
  ScrollArea,
  SimpleGrid,
  Flex,
  Input,
} from "@mantine/core";
import * as svg from "../Admins_Category/Designs/svg";
import { Stage, Layer, Rect } from "react-konva";
import { useNavigate, useParams } from "react-router-dom";
import useImage from "use-image";
import { IconCirclePlus } from "@tabler/icons";
import CatelogueContext from "../../ContextFolder/CatelogueContext/CatelogueContext";
import CompanycolorContext from "../../ContextFolder/CompanyColorContext/CompanycolorContext";
// import texture from "../../Images/Texture.svg";
function EditPageByUser() {
  const context = useContext(CatelogueContext);
  const contextcolor = useContext(CompanycolorContext);
  const { id } = useParams();
  const [colorMap, setColorMap] = useState([]);
  const [svgString, setSvgString] = useState("");

  const colors = svg.getColors(svgString);
  const [colorPickerSelectedColor, setColorPickerSelectedColor] =
    useState("#496738");
  const modifiedSVG = svg.replaceColors(svgString, colorMap);
  const [image] = useImage(svg.svgToURL(modifiedSVG));
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false);
  const showColorPicker = (toggle, oldColor) => {
    setDisplayColorPicker(toggle);
    setColorPickerSelectedColor(oldColor);
  };
  const [details, setDetails] = useState({
    name: "",
    libraries: [],
    precolors: [],
  });

  const [active, setActive] = useState("");
  // eslint-disable-next-line
  const [displaylib, setDisplaylib] = React.useState(false);
  const { classes, cx } = useStyles();
  const [colordata, setColordata] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const setNewColor = (oldColor, newColor) => {
    setColorMap({
      ...colorMap,
      [oldColor]: newColor,
    });
  };
  const divRef = useRef(null);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    if (divRef.current?.offsetHeight && divRef.current?.offsetWidth) {
      setDimensions({
        width: divRef.current.offsetWidth,
        height: divRef.current.offsetHeight,
      });
    }
    if (context?.getdesignbyid?.patternImage) {
      const fetchSVGString = async () => {
        setSvgString(context?.getdesignbyid?.patternImage);
      };
      fetchSVGString();
    }
    const getcolors = async () => {
      const companycolor = await contextcolor?.getallcolorsdataForsomePages({
        isActive: true,
      });
      setColordata(companycolor.companyColors);
    };

    const getaDesignbyid = async () => {
      const data = await context.getdesignId(id);
      const libraries = data.libraries.map((id) => `${id.id}`);
      setDetails({
        name: data?.title,
        libraries: libraries,
        precolors: data?.colors,
      });
    };

    getaDesignbyid();

    getcolors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.getdesignbyid?.patternImage]);

  const data = colors?.map((color) => colorMap[color] || color);

  const handleClick = async () => {
    if (!details.name) {
      alert("Please enter design name!");
    } else {
      const role = localStorage.getItem("role");
      if (role === "SuperAdmin") {
        // If SuperAdmin, pass all values
        await context.addDesign(
          details.name,
          details.designNumber, // SuperAdmin-specific
          details.picksMtr, // SuperAdmin-specific
          details.repeatSize, // SuperAdmin-specific
          data,
          modifiedSVG,
          details.libraries
        );
      } else {
        // If not SuperAdmin, exclude specific values
        await context.addDesign(
          details.name,
          data,
          modifiedSVG,
          details.libraries
        );
      }

      navigate("/MyLibrary");
    }
  };

  const handle = async () => {
    const role = localStorage.getItem("role");
    if (!details.name) {
      alert("please enter design name!");
    } else {
      const body = {
        name: details.name,
        colors: data,
        patternImage: modifiedSVG,
        libraries: details.libraries,
      };
      if (role === "SuperAdmin") {
        body.designNumber = details.designNumber;
        body.picksMtr = details.picksMtr;
        body.repeatSize = details.repeatSize;
      }
      await context.editDesign(id, body);
      navigate("/MyLibrary");
    }
  };
  const navigate = useNavigate();
  const onChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };
  // eslint-disable-next-line
  const [scrolled, setScrolled] = useState(false);

  const filteredColors = colordata?.filter((rawcolor) =>
    rawcolor.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const rawcolors = filteredColors.map((rawcolor) => (
    <>
      <div>
        <div
          key={rawcolor.id}
          style={{
            display: "flex",
            justifyContent: "normal",
            marginBottom: "5px",
          }}
        >
          <div
            style={{
              content: `${rawcolor.name}`,
              width: "24px",
              height: "24px",
              borderRadius: "4px",
              cursor: "pointer",
              backgroundColor: `${rawcolor.colorCode}`,
            }}
            onClick={() => {
              setNewColor(colorPickerSelectedColor, rawcolor.colorCode);
            }}
          ></div>

          <Text ml={5}>{rawcolor.name}</Text>
        </div>
      </div>
    </>
  ));
  const handeldelete = async () => {
    const response = window.confirm(
      "Are you sure to delete this Design pattern?"
    );
    if (response) {
      const DeleteDesign = await context.DeleteDesign(id);
      if (DeleteDesign) {
        navigate("/MyLibrary");
      }
    } else {
      navigate(`/editpage/library/${id}`);
    }
  };
  return (
    <>
      <div className={classes.designouter} style={{ display: "flex" }}>
        <div
          ref={divRef}
          style={{
            // padding: "5px",
            width: "70%",
            height: "100vh",
            overflow: "hidden",
          }}
        >
          <Stage width={dimensions.width} height={dimensions.height}>
            <Layer>
              <Rect
                width={dimensions.width}
                height={dimensions.height}
                fillPatternImage={image}
              />
            </Layer>
          </Stage>
        </div>
        {/* <div
          style={{
            background: `url(${texture})`,
            height: "100%",
            position: "absolute",
            width: "70%",
            top: "11%",
            left: "0",
          }}
          alt=""
        /> */}
        <div className={classes.d2c}>
          <div className={classes.colorchange}>
            <Text weight={600} color="#051C48">
              Colors
            </Text>
            <div className="p-2">
              <div style={{ display: "flex" }}>
                {colors.map((color) => {
                  const colorName = colordata.find(
                    (rawcolor) => rawcolor.colorCode === colorMap[color]
                  )?.name;

                  const targetColorCode = color;
                  const editData = colordata.find(
                    (rawcolor) =>
                      rawcolor.colorCode.toLowerCase() ===
                      targetColorCode.toLowerCase()
                  );
                  const editcolordata = editData ? editData.name : "";

                  return (
                    <div
                      key={color}
                      style={{
                        marginRight: "20px",
                        textAlign: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "58px",
                          height: "58px",
                          cursor: "pointer",
                          display: "inline-block",
                        }}
                        className={cx({ [classes.active2]: color === active })}
                        onClick={() => {
                          showColorPicker(true, color);
                          setActive(color);
                        }}
                      >
                        <div
                          className={cx({ [classes.active]: color === active })}
                          style={{
                            width: "50px",
                            height: "50px",
                            cursor: "pointer",
                            backgroundColor: colorMap[color] || color,
                          }}
                        ></div>
                      </div>

                      <div
                        style={{
                          marginTop: "2px",
                          wordBreak: "break-word",
                          width: "70px",
                        }}
                      >
                        <Text size={"sm"}>{colorName || editcolordata}</Text>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <Flex justify="space-between">
                <Text weight={600} mb={10} color="#051C48">
                  Choose Color
                </Text>
                <Input
                  type="text"
                  placeholder="Search color..."
                  hidden={!displayColorPicker}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  mb={"md"}
                />
              </Flex>
              {displayColorPicker && (
                <ScrollArea
                  sx={{ height: 200 }}
                  onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
                >
                  <SimpleGrid
                    style={{ backgroundColor: "white", padding: "10px" }}
                    cols={2}
                    spacing="xs"
                    breakpoints={[
                      { maxWidth: "md", cols: 2 },
                      { maxWidth: "sm", cols: 1 },
                    ]}
                  >
                    {rawcolors}
                  </SimpleGrid>
                </ScrollArea>
              )}

              <form>
                <div className="p-2">
                  <Text weight={600} mt={10} mb={10} size={18} color="#051C48">
                    Design Details
                  </Text>
                  <TextInput
                    value={details.name}
                    name="name"
                    onChange={onChange}
                    label="Name"
                    mb={10}
                    placeholder="Trellise"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "10px",
                  }}
                >
                  {" "}
                  <Button
                    style={{ width: "140px" }}
                    variant="default"
                    mt={5}
                    color="#051C48"
                    radius="sm"
                    size="sm"
                    onClick={() => {
                      navigate("/MyLibrary");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ width: "270px" }}
                    variant="default"
                    mt={5}
                    radius="sm"
                    size="sm"
                    color="#051C48"
                    ml={10}
                    onClick={handleClick}
                  >
                    Save as new design
                  </Button>
                </div>{" "}
                <Button
                  fullWidth
                  mt="md"
                  size="sm"
                  style={{ backgroundColor: "#E27612" }}
                  radius="sm"
                  onClick={handle}
                >
                  Save Changes
                </Button>
                <div
                  style={{
                    color: "#E27612",
                    fontWeight: "bold",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                    fontSize: "15px",
                  }}
                  onClick={handeldelete}
                >
                  <IconCirclePlus
                    style={{ transform: "rotate(315deg)", marginRight: "3px" }}
                  />
                  Delete Design
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditPageByUser;
