import React from "react";

const SearchIcon = ({
  stroke = "black",
  width = 32,
  height = 32,
  onClick = () => null,
  className = "",
}) => {
  return (
    <svg
      onClick={onClick}
      width={width}
      height={height}
      className={className}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6267 25.5868C8.57336 25.5868 3.66669 20.6801 3.66669 14.6268C3.66669 8.57342 8.57336 3.66675 14.6267 3.66675C20.68 3.66675 25.5867 8.57342 25.5867 14.6268C25.5867 20.6801 20.68 25.5868 14.6267 25.5868Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.3334 28.3335L22.8534 22.8535"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SearchIcon;
