import { Button, Container, Text } from "@mantine/core";
import { Link } from "react-router-dom";
import ColorsCard from "./Colors/ColorsCard";
import { AddButton } from "../../../Components/common/iconComponents/Icons";
import { validPaths } from "../../../utils/utils";

export default function CompanyColor() {
  return (
    <Container fluid py="xl" style={{ padding: "22px" }}>
      <div className="flex flex-row items-center justify-between px-3">
        <Text className="text-oxfordBlue md:text-2xl lg:text-3xl ">
          Company Colors
        </Text>
        <div>
          <Link to={validPaths.ADD_COLOR}>
            <Button
              rightIcon={<AddButton width={27} height={27} />}
              className="bg-whiskeySour text-base rounded-lg "
            >
              <Text color="white">Add New Color</Text>
            </Button>
          </Link>
        </div>
      </div>
      <ColorsCard />
    </Container>
  );
}
