import {
  Button,
  Container,
  MultiSelect,
  Switch,
  Text,
  TextInput,
} from "@mantine/core";
import React, { useCallback, useContext, useEffect, useState } from "react";
import CompanycolorContext from "../../../../ContextFolder/CompanyColorContext/CompanycolorContext";
import useStyles from "../../../../Components/Style/UseStyle";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useForm } from "@mantine/form";
import { IconCheck } from "@tabler/icons";
import { getallcolorCategoriesData } from "../../../../services/ColorCategory/colorCategory";

function Addcolor() {
  const context = useContext(CompanycolorContext);
  const { id } = useParams();
  const { updatedcolor } = context;
  const navigate = useNavigate();
  const form = useForm({
    initialValues: { name: "", colorCode: "", code: "", status: "" },
  });
  const [libraries, setLibraries] = useState([]);
  const [colorCategories, setColorCategories] = useState([]);

  useEffect(() => {
    const getcolors = async () => {
      const companycolor = await context.getcolorbyid(id);
      form.setValues({
        name: companycolor?.name,
        colorCode: companycolor?.colorCode,
        code: companycolor?.code,
        status: companycolor?.status,
      });
      const defaultvalue = companycolor?.colorCategories?.map(
        (ui) => `${ui.id}`
      );
      setLibraries(defaultvalue);
    };

    getcolors();
    // eslint-disable-next-line
  }, []);
  const getColorCategories = useCallback(async () => {
    const lib = await getallcolorCategoriesData(true);
    const categories = lib?.colorCategories?.map((ui) => ({
      label: ui.name,
      value: ui.id,
    }));
    setColorCategories(categories);
  }, []);

  const MemoizedMultiSelect = React.memo(MultiSelect);

  useEffect(() => {
    getColorCategories();
  }, [getColorCategories]);

  const { classes } = useStyles();
  return (
    <div className={classes.background}>
      <Container size="sm" py="xl">
        <div className={classes.uploadfile}>
          <Text size={25} mb={20} color="#051C48">
            Edit Color
          </Text>

          <Container mt={5}>
            <form
              onSubmit={form.onSubmit(async (values) => {
                if (!values.name || !values.colorCode || !values.code) {
                  alert("please enter Details");
                } else {
                  await updatedcolor(
                    id,
                    values.name,
                    values.colorCode,
                    values.code,
                    libraries,
                    values.status
                  );
                  navigate("/CompanyColor");
                }
              })}
            >
              <div
                style={{
                  height: "140px",
                  backgroundColor: ` ${form.values.colorCode}`,
                  color: `${form.values.colorCode}`,
                  marginBottom: "20px",
                  borderRadius: "6px",
                  border: "2px solid #7C93BC ",
                }}
              ></div>
              <TextInput
                placeholder="#496738"
                label="Hex Color code"
                color="#051C48"
                id="colorCode"
                name="colorCode"
                required
                {...form.getInputProps("colorCode")}
                sx={{ flex: 1 }}
                mb={20}
              />
              <TextInput
                mt="md"
                {...form.getInputProps("name")}
                label="Name"
                color="#051C48"
                id="name"
                name="name"
                required
                placeholder="Artichoke"
                sx={{ flex: 1 }}
                mb={10}
              />
              <TextInput
                mt="md"
                {...form.getInputProps("code")}
                color="#051C48"
                label="Code"
                id="code"
                required
                placeholder="16-1325 TCX"
                sx={{ flex: 1 }}
                mb={10}
              />
              <MemoizedMultiSelect
                data={colorCategories}
                value={libraries}
                label="Color Category"
                placeholder="Color Category"
                onChange={setLibraries}
              />
              <label style={{ alignItems: "center", marginTop: "5px" }}>
                <span style={{ marginRight: "10px" }}>status</span>
                <Switch
                  color="orange"
                  style={{ marginTop: "10px" }}
                  label="Active"
                  mb={20}
                  {...form.getInputProps("status", { type: "checkbox" })}
                />
              </label>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <Button
                  mt="md"
                  variant="outline"
                  style={{
                    width: "100%",
                    maxWidth: "280px", // Ensures button size is responsive
                    padding: "12px", // Adjust padding
                    borderColor: "#606060", // Adjust outline color
                    color: "#606060", // Text color
                  }}
                  onClick={() => {
                    navigate("/CompanyColor");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  mt="md"
                  style={{
                    width: "100%",
                    maxWidth: "280px", // Ensures button size is responsive
                    backgroundColor: "#E27612", // Custom background color
                    padding: "12px", // Adjust padding
                    color: "#fff", // Text color
                  }}
                  rightIcon={<IconCheck />}
                >
                  Save
                </Button>
              </div>
            </form>
          </Container>
        </div>
      </Container>
    </div>
  );
}

export default Addcolor;
