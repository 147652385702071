import React from "react";

const DeleteButton = ({
  stroke = "white",
  width = 20,
  height = 20,
  className = "",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.6699 6.91998V16.17C15.6699 17.87 14.2898 19.25 12.5898 19.25H6.41985C4.71985 19.25 3.33984 17.87 3.33984 16.17V6.91998"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.27979 3.83002H17.7198"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.41992 3.83002C6.41992 2.13002 7.79992 0.75 9.49992 0.75C11.1999 0.75 12.5799 2.13002 12.5799 3.83002"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.43994 10L11.5599 14.11"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5599 10L7.43994 14.11"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DeleteButton;
