import { z } from "zod";

const Schema = z.object({
  email: z
    .string()
    .refine((val) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(val), {
      message: "Invalid Email.",
    }),


  password: z
    .string()
    .refine(
      (val) =>
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/.test(
          val
        ),
      {
        message:
          "Password must contain at least 8 characters, one uppercase, one number and one special case character.",
      }
    ),
});

export default Schema;
