import React from "react";
import useStyles from "../../../../Components/Style/UseStyle";
import Design from "./UploadedDesign";
import UploadedDesignColors from "./UploadedDesignColors";
import UploadedDesignRepeat from "./UploadedDesignRepeat";
import { useMediaQuery } from "@mantine/hooks";
import { Button, Flex, Text } from "@mantine/core";
import { useNavigate } from "react-router-dom";

function DesignUploaded({ fileSource }) {
  const matches = useMediaQuery("(min-width: 770px)");
  const { classes } = useStyles();
  const navigate = useNavigate();
  return (
    <>
      {matches ? (
        <div
          style={{ display: "flex", backgroundColor: "white", width: "100%" }}
        >
          {" "}
          <div style={{ minWidth: "75%" }}>
            <UploadedDesignRepeat fileSource={fileSource} />
          </div>
          <div style={{ minWidth: "25%" }}>
            <hr style={{ marginTop: "0px", color: "#0B7285" }} />
            <div className={classes.uploadeddesign}>
              <Design fileSource={fileSource} />
              <UploadedDesignColors fileSource={fileSource} />
              <div style={{ marginTop: "53%", paddingRight: "10px" }}>
                {" "}
                <Button
                  fullWidth
                  size="md"
                  style={{ backgroundColor: "#E27612" }}
                  type="submit"
                  onClick={() => {
                    navigate("/ChangeColor");
                  }}
                >
                  Continue
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div style={{ padding: "18px", backgroundColor: "#F5F7FB" }}>
            <Text size={22} mb={8} weight={600} style={{ color: "#051C48" }}>
              Uploaded Design
            </Text>
            <div style={{ backgroundColor: "white" }}>
              {" "}
              <UploadedDesignRepeat fileSource={fileSource} />{" "}
              <Flex justify="center">
                <Button
                  mt={10}
                  mb={10}
                  style={{ width: 140, color: "#051C48" }}
                  variant="default"
                >
                  Upload New
                </Button>
              </Flex>
            </div>
            <UploadedDesignColors fileSource={fileSource} />

            <Flex justify="center">
              {" "}
              <Flex justify={{ sm: "center" }}>
                {" "}
                <Button
                  fullWidth
                  mt="xl"
                  size="lg"
                  color="orange"
                  type="submit"
                  onClick={() => {
                    navigate("/ChangeColor");
                  }}
                >
                  Continue
                </Button>
              </Flex>
            </Flex>
          </div>
        </>
      )}
    </>
  );
}

export default DesignUploaded;
