import React from "react";

const UserIcon = ({
  stroke = "black",
  width = 32,
  height = 32,
  className = "",
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7333 16.6133C17.5522 16.6133 19.0267 15.1388 19.0267 13.3199C19.0267 11.5011 17.5522 10.0266 15.7333 10.0266C13.9145 10.0266 12.44 11.5011 12.44 13.3199C12.44 15.1388 13.9145 16.6133 15.7333 16.6133Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.09332 25.72V23.84C9.09332 21.6533 10.8666 19.8933 13.04 19.8933H18.9467C21.1333 19.8933 22.8933 21.6666 22.8933 23.84V25.72"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 28.3334C22.8115 28.3334 28.3334 22.8116 28.3334 16.0001C28.3334 9.18857 22.8115 3.66675 16 3.66675C9.18851 3.66675 3.66669 9.18857 3.66669 16.0001C3.66669 22.8116 9.18851 28.3334 16 28.3334Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserIcon;
