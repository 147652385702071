import React, { useEffect, useState } from "react";
import { useForm, zodResolver } from "@mantine/form";
import useStyles from "../../../Components/Style/UseStyle";
import { Button, Container, MultiSelect, Switch, Text, TextInput } from "@mantine/core";
import UserContext from "../../../ContextFolder/UserContext/UserContext";
import { useContext } from "react";
import AddAccountSchema from "../../../Components/lockscreen/ValidationLogic/AddAccountSchema";
import { useNavigate } from "react-router-dom";
import LibraryContext from "../../../ContextFolder/LibraryContext/LibraryContext";

function AddAcc() {
  const navigate = useNavigate();
  const form = useForm({
    validateInputOnChange: true,
    validate: zodResolver(AddAccountSchema),
    initialValues: {
      name: "",
      email: "",
      status: true,
    },
  });
  const context = useContext(UserContext);
  const contextLib = useContext(LibraryContext);
  const [first, setFirst] = useState([]);
  const [libraries, setLibraries] = useState([]);


  useEffect(() => {
    const getlibrary = async () => {
      const lib = await contextLib?.getlibraryforSomepage();
      // setLibraries(lib.libraries);
      const a = lib?.libraries?.map((ui) => {
        return { label: `${ui.name}`, value: `${ui.id}` };
      });
      setFirst(a);
    };
    getlibrary();
// eslint-disable-next-line
  }, [])
  
  const { classes } = useStyles();
  return (
    <div className={classes.background}>
      <Container size="sm" py="xl">
        <div className={classes.uploadfile}>
          <Text size={25} mb={20}>
            Add Account
          </Text>
        
            <form
              onSubmit={form.onSubmit(async (values) => {
                if (!values.name) {
                  alert("pleaes enter name");
                } else {
                  await context.SignUp(
                    values.name,
                    values.email,
                    values.status,
                    libraries
                  );
                  navigate("/Account");
                }
              })}
            >
              <TextInput
                placeholder="John Doe"
                label="Name"
                withAsterisk
                sx={{ flex: 1 }}
                {...form.getInputProps("name")}
                mb={10}
              />
              <TextInput
                mt="md"
                label="Email"
                placeholder="Email"
                withAsterisk
                sx={{ flex: 1 }}
                {...form.getInputProps("email")}
                mb={10}
              />
               <MultiSelect
                data={first}
                
                value={libraries}
                label="Account Permission"
                placeholder="Account Permission"
                onChange={setLibraries}
              />
              <Switch
                label="Active"
                mt={16}
                mb={30}
                color="orange"
                {...form.getInputProps("status", {
                  type: "checkbox",
                })}
              />{" "}
              <hr />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                  mt="md"
                  variant="outline"
                  onClick={() => {
                    navigate("/Account");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  mt="md"
                  style={{ backgroundColor: "#E27612", padding:"10px" }}
                  type="submit"
                >
                  Save
                </Button>
              
              </div>
            </form>
       
        </div>
      </Container>
    </div>
  );
}

export default AddAcc;
