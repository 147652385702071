import * as React from "react";
const BackArrowIcon = (props) => (
  <svg
    width={33}
    height={32}
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.217 3.66666H16.5503C23.2703 3.66666 28.7103 9.11999 28.7103 15.8267V16.16C28.7103 22.88 23.257 28.32 16.5503 28.32H16.217C9.49701 28.32 4.05701 22.8667 4.05701 16.16V15.8267C4.05701 9.11999 9.51034 3.66666 16.217 3.66666Z"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5103 16H12.297"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.2704 16L15.5504 19.2933"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.2704 16L15.5504 12.7067"
      stroke="white"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default BackArrowIcon;
