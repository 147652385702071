import {
    Button,
    Container,
    Switch,
    Text,
    TextInput,
  } from "@mantine/core";
  import React, {  useEffect } from "react";
  
  import { useParams } from "react-router-dom";
  import { useNavigate } from "react-router-dom";
  import { useForm } from "@mantine/form";
  import { IconCheck } from "@tabler/icons";
    import { UpdatedColorCategory, getColorCategoryById } from "../../../services/ColorCategory/colorCategory";
import useStyles from "../../../Components/Style/UseStyle";
  
  function EditColorCategory() {
    const { id } = useParams();
    const navigate = useNavigate();
    const form = useForm({
      initialValues: { name: "", colorCode: "", status: "", order: 0 },
    });
  
    useEffect(() => {
      const getcolors = async () => {
        const companycolor = await getColorCategoryById(id);
        form.setValues({
          name: companycolor?.name,
          colorCode: companycolor?.colorCode,
          code: companycolor?.code,
          status: companycolor?.status,
          order: companycolor?.order || 0,
        });
      };
  
      getcolors();
      // eslint-disable-next-line
    }, []);
    // const getColorCategories = useCallback(async () => {
    //   const lib = await getallcolorCategoriesData(true);
    //   const categories = lib?.colorCategories?.map((ui) => ({
    //     label: ui.name,
    //     value: ui.id,
    //   }));
    //   setColorCategories(categories);
    // }, []);
  
    // const MemoizedMultiSelect = React.memo(MultiSelect);
  
    // useEffect(() => {
    //   getColorCategories();
    // }, [getColorCategories]);
  
    const { classes } = useStyles();
    return (
      <div className={classes.background}>
        <Container size="sm" py="xl">
          <div className={classes.uploadfile}>
            <Text size={25} mb={20} color="#051C48">
              Edit Color
            </Text>
  
            <Container mt={5}>
              <form
                onSubmit={form.onSubmit(async (values) => {
                  if (!values.name || !values.colorCode) {
                    alert("please enter Details");
                  } else {
                    await UpdatedColorCategory(
                      id,
                      values.name,
                      values.colorCode,
                      values.status,
                      values.order || 0
                    );
                    navigate("/ColorCategory");
                  }
                })}
              >
                <div
                  style={{
                    height: "140px",
                    backgroundColor: ` ${form.values.colorCode}`,
                    color: `${form.values.colorCode}`,
                    marginBottom: "20px",
                    borderRadius: "6px",
                    border: "2px solid #7C93BC ",
                  }}
                ></div>
                <TextInput
                  placeholder="#496738"
                  label="Hex Color code"
                  color="#051C48"
                  id="colorCode"
                  name="colorCode"
                  required
                  {...form.getInputProps("colorCode")}
                  sx={{ flex: 1 }}
                  mb={20}
                />
                <TextInput
                  mt="md"
                  {...form.getInputProps("name")}
                  label="Name"
                  color="#051C48"
                  id="name"
                  name="name"
                  required
                  placeholder="Artichoke"
                  sx={{ flex: 1 }}
                  mb={10}
                />
                <TextInput
                  mt="md"
                  {...form.getInputProps("order")}
                  label="Order"
                  color="#051C48"
                  id="order"
                  name="order"
                  placeholder="0"
                  sx={{ flex: 1 }}
                  mb={10}
                />
                <label style={{ alignItems: "center", marginTop: "5px" }}>
                  <span style={{ marginRight: "10px" }}>status</span>
                  <Switch
                    color="orange"
                    style={{ marginTop: "10px" }}
                    label="Active"
                    mb={20}
                    {...form.getInputProps("status", { type: "checkbox" })}
                  />
                </label>
  
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <Button
                    mt="md"
                    variant="outline"
                    style={{
                      width: "100%",
                      maxWidth: "280px", // Ensures button size is responsive
                      padding: "12px", // Adjust padding
                      borderColor: "#606060", // Adjust outline color
                      color: "#606060", // Text color
                    }}
                    onClick={() => {
                      navigate("/ColorCategory");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    mt="md"
                    style={{
                      width: "100%",
                      maxWidth: "280px", // Ensures button size is responsive
                      backgroundColor: "#E27612", // Custom background color
                      padding: "12px", // Adjust padding
                      color: "#fff", // Text color
                    }}
                    rightIcon={<IconCheck />}
                  >
                    Save
                  </Button>
                </div>
              </form>
            </Container>
          </div>
        </Container>
      </div>
    );
  }
  
  export default EditColorCategory;
  
