import React from "react";
import useStyles from "../../../../Components/Style/UseStyle";
import UploadedDesignColors from "./UploadedDesignColors";
import UploadedDesignRepeat from "./UploadedDesignRepeat";
import { useMediaQuery } from "@mantine/hooks";
import { Button, Flex, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

import { useNavigate } from "react-router-dom";

function DesignUploaded2({ fileSource }) {

  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width: 770px)");
  const { classes } = useStyles();

 
  const designdetails = useForm({
    initialValues: {
      name: "",
      // tags: [],
      // color: colors,
      // image: svgString,
    },
  });
  return (
    <>
      {matches ? (
        <div
          style={{ display: "flex", padding: "10px", backgroundColor: "white" }}
        >
          <UploadedDesignRepeat fileSource={fileSource} />
          <div className={classes.designdcs}>
            <div className={classes.uploadeddesign}>
              <UploadedDesignColors fileSource={fileSource} />

              <form
                onSubmit={designdetails.onSubmit(async () => {
                  
                  navigate("/ChangeColor");
                })}
              >
                <div className="p-2">
                  <Text weight={600} mt={10} mb={10} size={18}>
                    Design Details
                  </Text>
                  <TextInput
                  disabled
                    {...designdetails.getInputProps("name")}
                    label="Name"
                    mb={10}
                    placeholder="Trellise"
                  />
                  {/* <MultiSelect
                    data={data}
                    {...designdetails.getInputProps("tags")}
                    label="Large data set"
                    placeholder="Scroll to see all options"
                    maxDropdownHeight={50}
                  /> */}
                </div>
                <Flex justify="center" align="flex-end">
                  {" "}
                  <Button
                    style={{ width: "100%" }}
                    mt="xl"
                    size="lg"
                    color="orange"
                    type="submit"
                  >
                    Continue
                  </Button>
                </Flex>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div style={{ padding: "18px", backgroundColor: "#F5F7FB" }}>
            <Text size={22} mb={8} weight={600}>
              Uploaded Design
            </Text>
            <div style={{ backgroundColor: "white" }}>
              {" "}
              <UploadedDesignRepeat fileSource={fileSource} />{" "}
              <Flex justify="center">
                <Button
                  mt={10}
                  mb={10}
                  style={{ width: 140 }}
                  variant="default"
                >
                  Upload New
                </Button>
              </Flex>
            </div>
            <UploadedDesignColors fileSource={fileSource} />

            <Flex justify="center">
              {" "}
              <form
                onSubmit={designdetails.onSubmit(async (values) => {
                  
                  navigate("/ChangeColor");
                })}
              >
                <div className="p-2">
                  <Text weight={600} mt={10} mb={10} size={18}>
                    Design Details
                  </Text>
                  <TextInput
                  disabled
                    {...designdetails.getInputProps("name")}
                    label="Name"
                    mb={10}
                    placeholder="Trellise"
                  />
                  {/* <MultiSelect
                    data={data}
                    {...designdetails.getInputProps("tags")}
                    label="Large data set"
                    placeholder="Scroll to see all options"
                    maxDropdownHeight={50}
                  /> */}
                </div>
                <Flex justify={{ sm: "center" }}>
                  <Button
                    color="orange"
                    mt={5}
                    radius="xs"
                    type="submit"
                    size="md"
                  >
                    Continue
                  </Button>
                </Flex>
              </form>
            </Flex>
          </div>
        </>
      )}
    </>
  );
}

export default DesignUploaded2;
