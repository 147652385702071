import React from "react";
import { Text } from "@mantine/core";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { navigationActions } from "../../../utils/utils";

function Nav2menuitems() {
  const { pathname } = useLocation();
  return (
    <div className="flex flex-row gap-x-7">
      {navigationActions?.map(({ navigateTo, actionTitle }) => (
        <Link to={navigateTo}>
          <Text
            className={`${
              pathname === navigateTo ? "text-whiskeySour" : "text-white"
            } text-lg font-bold cursor-pointer `}
          >
            {actionTitle}
          </Text>
        </Link>
      ))}
    </div>
  );
}

export default Nav2menuitems;
