import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CatelogueContext from "./CatelogueContext";

function CatelogueState(props) {
  const navigate = useNavigate();
  const [getdesignbyid, setGetdesignbyid] = useState();
  const [requestSampleData, setRequsetSampleData] = useState();

  const limit = 16;

  const getallDesignForsumPages = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/designs`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const json = await response.json();
      return json;
    } catch {
      navigate("/login");
    }
  };

  const getallDesign = async (num, value, string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/designs?limit=${limit}&offset=${num}&libraries=${value}&q=${string}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const json = await response.json();
      return json;
    } catch {
      navigate("/login");
    }
  };

  const getdesignId = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/designs/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const res = await response.json();
      // console.log(res, "res");
      setGetdesignbyid(res);
      return res;
    } catch {
      navigate("/login");
    }
  };

  const addDesign = async (
    title,
    designNumber,
    picksMtr,
    repeatSize,
    colors,
    patternImage,
    libraries
  ) => {
    try {
      const role = localStorage.getItem("role");
      const body = {
        title,
        colors,
        patternImage,
        libraries,
      };

      // if (role === "SuperAdmin") {
      body.designNumber = designNumber;
      body.picksMtr = picksMtr;
      body.repeatSize = repeatSize;
      // }
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/designs`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(body),
        }
      );

      await response.json();
    } catch (error) {
      navigate("/login");
    }
  };

  const editDesign = async (id, body) => {
    try {
      const token = localStorage.getItem("token");

      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/designs/${id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(body),
        }
      );
      await response.json();
    } catch (error) {
      navigate("/login");
    }
  };

  const getallUserDesigninMylibraryPage = async (id) => {
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL
        }/designs/my-design/${id}?limit=${999}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const res = await response.json();

      return res;
    } catch {
      navigate("/login");
    }
  };

  const AddRequest = async ({
    sampleSize,
    designId,
    // productConstruction,
    // pileType,
  }) => {
    const response = await fetch(
      `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/sample-requests`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: ` Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          sampleSize,
          designId,
          // productConstruction,
          // pileType,
        }),
      }
    );

    const reqdata = await response.json();
    localStorage.setItem("requestId", reqdata.id);
    setRequsetSampleData(reqdata);
  };

  // const GetReqSample = async () => {
  //   const id = localStorage.getItem("requestId");
  //   const response = await fetch(
  //     `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/sample-requests/${id}`,
  //     {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: ` Bearer ${localStorage.getItem("token")}`,
  //       },
  //     }
  //   );

  //   const reqdata = await response.json();
  //   return reqdata;
  // };
  const EditRequest = async (sampleSize, designId) => {
    const response = await fetch(
      `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/sample-requests`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: ` Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ sampleSize, designId }),
      }
    );

    await response.json();
    // console.log(reqdata, "req");
  };

  const DeleteDesign = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/designs/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      return await response.json();
    } catch {
      navigate("/login");
    }
  };

  return (
    <CatelogueContext.Provider
      value={{
        addDesign,
        getallDesign,
        getallDesignForsumPages,
        getdesignId,
        getdesignbyid,
        editDesign,
        getallUserDesigninMylibraryPage,
        AddRequest,
        EditRequest,
        requestSampleData,
        // GetReqSample,
        DeleteDesign,
      }}
    >
      {props.children}
    </CatelogueContext.Provider>
  );
}

export default CatelogueState;
