import React from "react";

const CheckMarkIcon = ({
  stroke = "#D79963",
  className = "",
  width = 19.88,
  height = 19.88,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.2479 20.8644C5.75683 20.8644 1.30811 16.4157 1.30811 10.9246C1.30811 5.43359 5.75683 0.984863 11.2479 0.984863C16.7389 0.984863 21.1877 5.43359 21.1877 10.9246C21.1877 16.4157 16.7389 20.8644 11.2479 20.8644Z"
        stroke={stroke}
        strokeWidth="1.20889"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.35954 14.7016L5.58276 10.9248"
        stroke={stroke}
        strokeWidth="1.20889"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9132 7.14795L9.35962 14.7015"
        stroke={stroke}
        strokeWidth="1.20889"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckMarkIcon;
