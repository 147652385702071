import React from "react";

const TickMarkIcon = ({
  onClick,
  stroke = "white",
  width = 23,
  height = 15,
  className = "",
}) => (
  <svg
    onClick={onClick}
    width={width}
    height={height}
    viewBox="0 0 23 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M8.16667 14.1667L1.5 7.5"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.4998 0.833252L8.1665 14.1666"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TickMarkIcon;
