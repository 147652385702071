import React, { useContext, useEffect } from "react";
import { useForm } from "@mantine/form";
import { Group, MultiSelect } from "@mantine/core";
import { Container, Text, TextInput, Switch, Button } from "@mantine/core";
import { IconRefreshDot, IconSearch, IconTrash } from "@tabler/icons";
import useStyles from "../../../Components/Style/UseStyle";
import { useNavigate, useParams } from "react-router-dom";
import UserContext from "../../../ContextFolder/UserContext/UserContext";
import LibraryContext from "../../../ContextFolder/LibraryContext/LibraryContext";
import { useState } from "react";
import CatelogueContext from "../../../ContextFolder/CatelogueContext/CatelogueContext";

function Editacc({ setName, searchstring }) {
  const { classes } = useStyles();
  const { id } = useParams();
  const context = useContext(UserContext);
  const navigate = useNavigate();
  const contextLib = useContext(LibraryContext);
  const contextcatelogue = useContext(CatelogueContext);
  const [cunt, setCunt] = useState();
  const [first, setFirst] = useState([]);
  const [libraries, setLibraries] = useState([]);
  const form = useForm({
    initialValues: { name: "", email: "", status: false, role: "" },
  });
  useEffect(() => {
    const getprofileByid = async () => {
      const data = await context.getprofileByid(id);

      form.setValues({
        name: data?.fullName,
        email: data?.email,
        status: data.status,
        role: data?.role,
      });
      setName(data?.fullName);
      const defaultvalue = data?.libraries?.map((ui) => `${ui.id}`);

      setLibraries(defaultvalue);

      const fordesigncount =
        await contextcatelogue.getallUserDesigninMylibraryPage(id);
      setCunt(fordesigncount.count);
    };
    const getlibrary = async () => {
      const lib = await contextLib?.getlibraryforSomepage();
      const a = lib?.libraries?.map((ui) => {
        return { label: `${ui.name}`, value: `${ui.id}` };
      });
      setFirst(a);
    };
    getprofileByid();
    getlibrary();
    // eslint-disable-next-line
  }, []);

  const handleClick = () => {
    navigate(`/resetpassword/${id}`);
  };

  const DeleteAccount = async () => {
    const response = window.confirm("Are you sure to delete this User?");
    if (response) {
      await context.UserDeleteByAdmin(id, true);
      navigate("/Account");
    } else {
      navigate(`/Editacc/${id}`);
    }
  };

  return (
    <div className={classes.backgroundColor}>
      <Container size="sm" py="xl">
        <div className={classes.uploadfile}>
          <Text size={25} mb={20} color="#051C48">
            Edit Account
          </Text>

        
            <form
              onSubmit={form.onSubmit(async (values) => {
                if (!values.name || !values.email) {
                  alert("pleaes enter details");
                } else {
                  await context.updateProfileByAdmin(
                    id,
                    values.name,
                    values.email,
                    values.status,
                    libraries
                  );
                  navigate("/Account");
                }
              })}
            >
              <TextInput
                placeholder="John Doe"
                label="Name"
                withAsterisk
                sx={{ flex: 1 }}
                color="#051C48"
                {...form.getInputProps("name")}
                mb={10}
              />
              <TextInput
                mt="md"
                label="Email"
                placeholder="Email"
                withAsterisk
                color="#051C48"
                sx={{ flex: 1 }}
                {...form.getInputProps("email")}
                mb={10}
              />
              {form.values.role === "SuperAdmin" ? (
                <div></div>
              ) : (
                <MultiSelect
                  data={first}
                  value={libraries}
                  label="Account Permission"
                  placeholder="Account Permission"
                  // dropdownPosition="top"
                  onChange={async (value) => {
                    if (value.length !== 0) {
                      setLibraries(value);
                    } else {
                      setLibraries(value);
                      // setCunt(0);
                    }
                  }}
                />
              )}
              {form.values.role === "SuperAdmin" ? (
                <div></div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <div>
                    {" "}
                    <Switch
                      label="Active"
                      color="orange"
                      mt={15}
                      mb={20}
                      {...form.getInputProps("status", {
                        type: "checkbox",
                      })}
                    />
                  </div>
                  <div style={{ marginLeft: "30%" }}>
                    <Text weight={600} size={15} mb={10} color="#051C48">
                      User libraries
                    </Text>
                    
                    <Group>
                      <Text size={15}  >
                      {" "}
                     <text style={{color:"#E27612"}}>{cunt}</text>  <text style={{ marginLeft: "4px" }}>Designs</text>
                    </Text>
                      <div
                        onClick={() => {
                          navigate(`/viewdesign/${id}`);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        <IconSearch /> View
                      </div>
                    </Group>
                  </div>
                </div>
              )}
              <hr />
              <div className={classes.Switch}>
                <Button
                  variant="outline"
                  color="gray"
                  size="sm"
                  mt={10}
                  mb={10}
                  onClick={handleClick}
                >
                  <IconRefreshDot /> Reset Password
                </Button>
                {form.values.role === "SuperAdmin" ? (
                  <div></div>
                ) : (
                  <Button
                    variant="outline"
                    color="gray"
                    size="sm"
                    ml={10}
                    mt={10}
                    mb={10}
                    onClick={DeleteAccount}
                  >
                    <IconTrash /> Delete Account
                  </Button>
                )}
              </div>{" "}
              <hr />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                  mt="md"
                  variant="outline"
                  onClick={() => {
                    navigate("/Account");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  mt="md"
                  style={{ backgroundColor: "#E27612", padding:"10px" }}
                  type="submit"
                >
                  Save
                </Button>
              
              </div>
            </form>
      
        </div>
      </Container>
    </div>
  );
}

export default Editacc;
