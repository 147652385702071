import React, { useMemo, useRef, useState } from "react";
import { Card, Text, Loader, Image, Button, ScrollArea } from "@mantine/core";
import texture from "../../../Images/Texture.svg";
import CatelogueContext from "../../../ContextFolder/CatelogueContext/CatelogueContext";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import "../../../App";
import LibraryContext from "../../../ContextFolder/LibraryContext/LibraryContext";
import UserContext from "../../../ContextFolder/UserContext/UserContext";
import ArrowDownIcon from "../../../Images/svg/ArrowDownIcon.svg";
import ArrowUpIcon from "../../../Images/svg/ArrowUpIcon.svg";
import FilterIcon from "../../../Components/common/iconComponents/FilterIcon.js";
import { useMediaQuery } from "@mantine/hooks";
import DesignCard from "../../../Components/ArticleCard/DesignCard.js";
import Newdesigntag from "../../../Components/Header/Navbar1/smallcomponents/Newdesigntag.js";
import InfiniteScroll from "react-infinite-scroll-component";

const ArticleCard = ({ searchstring }) => {
  const limit = 16;
  const role = localStorage.getItem("role");
  const isTabletOrDesktop = useMediaQuery("(min-width: 768px)");
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [flag, setFlag] = useState(false);
  const [posts, setPosts] = useState([]);
  const [userLibraries, setUserLibraries] = useState();
  const [selectedLibrary, setSelectedLibrary] = useState([]);
  const [number, setNumber] = useState();
  const { getallDesign } = useContext(CatelogueContext);
  const [hasMore, setHasMore] = useState(true); // Set a local state for hasMore
  // const { getlibraryforSomepage } = useContext(LibraryContext);
  const [isNavigatingBack, setIsNavigatingBack] = useState(false);
  useEffect(() => {
    if (!isTabletOrDesktop) setIsOpen(false);

    if (!isNavigatingBack) {
      window.scrollTo(0, 0);
      setCurrentPage(1);
      getLibrary();
    }
    setIsNavigatingBack(false);
  }, [searchstring]);

  const designs = useMemo(
    () =>
      posts?.map((design) => {
        const blob = new Blob([design?.patternImage], {
          type: "image/svg+xml",
        });
        const url = URL.createObjectURL(blob);
        return {
          id: design?.id,
          url: url,
          title: design?.title,
          designNumber: design?.designNumber,
        };
      }),
    [posts]
  );

  useEffect(() => {
    const savedScrollPosition = localStorage.getItem("scrollPosition");
    const savedPage = localStorage.getItem("currentPage");
    const parsedPage = parseInt(savedPage, 10) || 1;

    if (parsedPage > 1) {
      setCurrentPage(parsedPage);
      loadDesignsUpToPage(parsedPage).then(() => {
        if (savedScrollPosition) {
          setTimeout(() => {
            window.scrollTo(0, parseInt(savedScrollPosition, 10) || 0);
            localStorage.removeItem("scrollPosition");
            localStorage.removeItem("currentPage");
          }, 100);
        }
      });
    } else {
      getLibrary();
    }
  }, [isNavigatingBack, selectedLibrary]);

  useEffect(() => {
    const getUserData = () => {
      try {
        setLoader(true);
        const storedUserData =
          JSON.parse(localStorage.getItem("userLibraries")) || [];
        setUserLibraries(storedUserData);

        // Map library IDs to strings
        const newLibraryIds = storedUserData.map((library) => `${library.id}`);
        setSelectedLibrary(newLibraryIds);
      } catch (error) {
        console.error("Error retrieving data from localStorage:", error);
      } finally {
        setLoader(false);
      }
    };

    getUserData();
  }, []);

  // Reset pagination and fetch library designs when search string or selectedLibrary changes

  // Initial load or page scroll restoration

  const loadDesignsUpToPage = async (page) => {
    try {
      setLoader(true);
      const pagePromises = [];
      for (let i = 1; i <= page; i++) {
        pagePromises.push(getallDesign(i, selectedLibrary, searchstring));
      }
      const results = await Promise.all(pagePromises);
      const loadedDesigns = results.flatMap((result) => result.designs || []);
      setPosts(loadedDesigns);
      setNumber(results[0]?.count || 0);
      setHasMore(page < Math.ceil((results[0]?.count || 0) / limit));
    } catch (error) {
      console.error("Error loading designs:", error);
    } finally {
      setLoader(false);
    }
  };

  const getLibrary = async () => {
    try {
      setLoader(true);
      const result = await getallDesign(1, selectedLibrary, searchstring);
      setPosts(result.designs || []);
      setNumber(result.count || 0);
      setHasMore(1 < Math.ceil(result.count / limit));
    } catch (error) {
      console.error("Error fetching designs:", error);
    } finally {
      setLoader(false);
    }
  };
  const loadMoreData = async () => {
    try {
      const nextPage = currentPage + 1;
      const newPost = await getallDesign(
        nextPage,
        selectedLibrary,
        searchstring
      );
      if (newPost?.designs) {
        setPosts((prevDesigns) => [...prevDesigns, ...newPost.designs]);
        setCurrentPage(nextPage);
        setHasMore(nextPage < Math.ceil(number / limit));
      }
    } catch (error) {
      console.error("Error loading more posts:", error);
    }
  };

  const handleClick = async (selectedValue) => {
    setCurrentPage(1);
    selectedValue === "All"
      ? setSelectedLibrary(userLibraries?.map((library) => `${library.id}`))
      : setSelectedLibrary([selectedValue]);
  };

  const totalPage = number ? Math.ceil(number / limit) : 0;

  return (
    <div className="flex   flex-col md:flex-row">
      {role === "User" && (
        <div className="w-full z-1 sticky top-12 md:top-0  md:relative md:w-[35%] lg:w-1/5 ">
          <div className="md:sticky md:top-0 md:left-0">
            <div
              onClick={() => !isTabletOrDesktop && setIsOpen(!isOpen)}
              className="flex bg-lightGrey1 flex-row py-3 px-6 justify-between items-center"
            >
              <Text className="text-base text-comet font-bold">Filter:</Text>
              {!isTabletOrDesktop && (
                <FilterIcon stroke={isOpen ? "#D79963" : "#636364"} />
              )}
            </div>
            {(isOpen || isTabletOrDesktop) && (
              <div className="bg-midGrey z-9 text-base text-comet absolute  flex w-full  ">
                <ScrollArea className="w-full h-screen">
                  <ul className="w-full">
                    <li
                      onClick={() => {
                        handleClick("All");
                        setFlag(false);
                      }}
                      className={`hover:bg-whiskeySour text-comet py-4 px-6 text-base  ${
                        userLibraries?.every((item) =>
                          selectedLibrary.includes(item?.id)
                        ) &&
                        !flag &&
                        "bg-whiskeySour"
                      }`}
                    >
                      All
                    </li>
                    {userLibraries?.map((library, index) => (
                      <li
                        className={`hover:bg-whiskeySour ${
                          // !(selectedLibrary?.length === userLibraries?.length) &&
                          // !userLibraries?.every((item) =>
                          //   selectedLibrary.includes(item?.id)
                          // ) &&
                          selectedLibrary.includes(library?.id) &&
                          flag &&
                          "bg-whiskeySour"
                        } py-3 px-7 text-base`}
                        key={library?.id}
                        onClick={() => {
                          handleClick(library?.id);
                          setFlag(true);
                        }}
                      >
                        {library?.name}
                      </li>
                    ))}
                  </ul>
                </ScrollArea>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="w-full z-0 md:w-[65%] lg:w-4/5 flex flex-col items-center md:mx-4 mt-3 md:mt-7">
        {loader ? (
          <Loader
            color="#D79963"
            size="lg"
            variant="oval"
            className="w-full flex justify-center items-center flex-row mt-44 md:mt-60"
          />
        ) : designs?.length === 0 ? (
          <Text className="text-2xl font-medium flex justify-center items-center flex-row mt-44 md:mt-60">
            No data
          </Text>
        ) : (
          <>
            <InfiniteScroll
              dataLength={designs.length}
              next={loadMoreData}
              hasMore={hasMore} // Use the local hasMore state
              // loader={
              //   <div className="flex justify-center items-center py-4">
              //     <Loader color="#D79963" size="lg" />
              //   </div>
              // }
            >
              <div className="grid grid-cols-1 md:gap-x-5 md:gap-y-3 lg:gap-y-0.5 xl:gap-y-1 md:grid-cols-2 lg:grid-cols-4 place-items-center">
                {designs.map((design, index) => (
                  <div key={`${design.id}-${index}`}>
                    <DesignCard
                      design={design}
                      currentPage={currentPage}
                      setIsNavigatingBack={setIsNavigatingBack}
                    />
                  </div>
                ))}
              </div>
            </InfiniteScroll>
            {designs?.length > 12 && (
              <div className="cursor-pointer fixed bottom-2 right-2 md:bottom-5 md:right-7">
                <Image
                  src={ArrowUpIcon}
                  alt="Scroll to top"
                  onClick={() =>
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    })
                  }
                  width={40}
                />
              </div>
            )}
          </>
        )}
      </div>
      {role === "SuperAdmin" && <Newdesigntag />}
    </div>
  );
};

export default ArticleCard;
