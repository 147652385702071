import React from "react";
import UserContext from "./UserContext";
import { useNavigate } from "react-router-dom";

export default function UserState(props) {
  const navigate = useNavigate();
  // account page_listing name,email_per page count
  const limit = 10;

  // log-in API
  const Login = async (values) => {
    try {
      const login = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/auth/sign-in`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );
      const login_Response = await login.json();

      if (login_Response.access_token) {
        // storing user access token in localstorage
        localStorage.setItem("token", login_Response.access_token);
        // calling getmyprofile api for role identification
        await getmyprofile(values);

        try {
          if (localStorage.getItem("role") === "SuperAdmin") {
            // admin logged In
            navigate("/ArticleCard");
          }
          if (localStorage.getItem("role") === "User") {
            // user-logged In
            navigate("/ArticleCard");
          }
        } catch {
          console.log("Role Not Found");
          navigate("/login");
        }
      } else {
        // if email or password are incorrect
        alert("Please Enter Valid Credentials");
      }
    } catch {
      // some problem in Api
      alert("Please Enter Valid Credentials");
      navigate("/login");
    }
  };

  // sign-up Api
  const SignUp = async (fullName, email, status, libraries) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/auth/sign-up`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ fullName, email, status, libraries }),
        }
      );
      await response.json();
    } catch {
      alert("Please Enter Valid Credentials");
      navigate("/login");
    }
  };

  // forget password API
  const ForgetPassword = async (email) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/auth/reset-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );
      const ForgetPassword_response = await response.json();
      // return success message and email-sent message
      return ForgetPassword_response;
    } catch {
      alert("something wrong!!");
      navigate("/login");
    }
  };

  // update-user profile by Admin API
  // edit-Account functionality
  const updateProfileByAdmin = async (
    userId,
    fullName,
    email,
    status,
    libraries
  ) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/auth/admin/update-profile`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ userId, fullName, email, status, libraries }),
        }
      );
      await response.json();
    } catch {
      console.log("something went wrong");
      navigate("/login");
    }
  };

  // Delete user-account by Admin
  // delete account API for admin
  const UserDeleteByAdmin = async (userId, isDeleted) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/auth/admin/update-profile`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ userId, isDeleted }),
        }
      );
      await response.json();
    } catch {
      console.log("something went wrong");
      navigate("/login");
    }
  };

  // reset-password API for Admin
  const resetpasswordByAdmin = async (userId, password) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/auth/admin/update-profile`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ userId, password }),
        }
      );
      await response.json();
    } catch {
      console.log("somwthing went wrong");
      navigate("/login");
    }
  };

  // get all profile
  // account page listing
  const getallProfile = async (num) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/auth/profiles?limit=${limit}&offset=${num}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(),
        }
      );
      const profiles = await response.json();
      return profiles;
    } catch {
      console.log("users Not Found");
      navigate("/login");
    }
  };

  // information of loggedin person
  const getmyprofile = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/auth/profile/me`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const myprofile = await response.json();

      // set deatils in localstorage
      localStorage.setItem(
        "user-libraries",
        JSON.stringify(myprofile.libraries)
      );
      localStorage.setItem("role", myprofile.role);
      localStorage.setItem("name", myprofile.fullName);
      localStorage.setItem("userId", myprofile.id);
      localStorage.setItem(
        "userLibraries",
        JSON.stringify(myprofile.libraries)
      ); // Use JSON.stringify here

      if (myprofile.avatarId) {
        localStorage.setItem("avatarId", myprofile.avatarId);
      }

      return myprofile;
    } catch {
      console.log("Profile Not Found");
      navigate("/login");
    }
  };

  const getprofile = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/auth/profile/me`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const myprofile = await response.json();

      return myprofile;
    } catch {
      console.log("profile not found");
    }
  };

  // profile-by-id API
  const getprofileByid = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/auth/profile/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const profile_info_by_Id = await response.json();

      return profile_info_by_Id;
    } catch {
      console.log("something went wrong!!");
    }
  };

  return (
    <UserContext.Provider
      value={{
        Login,
        getmyprofile,
        getallProfile,
        getprofileByid,
        SignUp,
        updateProfileByAdmin,
        UserDeleteByAdmin,
        ForgetPassword,
        resetpasswordByAdmin,
        getprofile,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
}
