import React from "react";

const ZoomOutIcon = ({
  stroke = "#636364", // Default stroke color
  width = 28, // Default width
  height = 28, // Default height
  className = "", // Additional class for styling
  onClick = () => {}, // Default onClick handler
}) => {
  return (
    <svg
      width={width}
      height={height}
      onClick={onClick}
      viewBox="0 0 28 28"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6266 23.5866C6.57329 23.5866 1.66663 18.68 1.66663 12.6266C1.66663 6.57329 6.57329 1.66663 12.6266 1.66663C18.68 1.66663 23.5866 6.57329 23.5866 12.6266C23.5866 18.68 18.68 23.5866 12.6266 23.5866Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.3333 26.3333L20.8533 20.8533"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.89331 12.6266H15.3733"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ZoomOutIcon;
