import React from "react";

const AddButton = ({
  stroke = "black",
  width = 32,
  height = 32,
  className = "",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9998 28.8334C22.8113 28.8334 28.3332 23.3116 28.3332 16.5001C28.3332 9.68857 22.8113 4.16675 15.9998 4.16675C9.18832 4.16675 3.6665 9.68857 3.6665 16.5001C3.6665 23.3116 9.18832 28.8334 15.9998 28.8334Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8247 12.8013L15.8812 19.9855"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2612 16.3652L19.4455 16.4218"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AddButton;
