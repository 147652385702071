import React, { useState } from "react";
import CompanycolorContext from "./CompanycolorContext";

function CompanycolorState(props) {
  const [colorsdata, setColorsdata] = useState();

  // listing colors data limit per-page
  const limit = 21;

  const getallcolorsdata = async (num) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/company-colors?limit=${limit}&offset=${num}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(),
        }
      );

      const colors = await response.json();
      return colors;
    } catch {
      console.log("something wrong!!!!");
    }
  };

  // color change page _ colors requirment
  const getallcolorsdataForsomePages = async ({
    isActive = "",
    colorCategory = "",
  }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/company-colors?isActive=${isActive}&colorCategory=${colorCategory}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(),
        }
      );

      const colors = await response.json();

      return colors;
    } catch {
      console.log("something wrong!!");
    }
  };

  const getcolorbyid = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/company-colors/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const colorbyid = await response.json();
      return colorbyid;
    } catch {
      console.log("something wrong!!");
    }
  };

  //add-color API
  const addcolor = async (name, colorCode, code, colorCategories) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/company-colors`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ name, colorCode, code, colorCategories }),
        }
      );

      const added_color = await response.json();
      setColorsdata(added_color);
    } catch {
      console.log("something wrong!!");
    }
  };

  //delete color-API
  const deletecolor = async (id, data) => {
    const response = await fetch(
      `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/company-colors/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: ` Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    await response.json();

    const afterDelete = data.filter((color) => {
      return color.id !== id;
    });
    return afterDelete;
  };

  // update-color API
  const updatedcolor = async (
    id,
    name,
    colorCode,
    code,
    colorCategories,
    status
  ) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/company-colors/${id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            name,
            colorCode,
            code,
            colorCategories,
            status,
          }),
        }
      );
      await response.json();
    } catch {
      console.log("something wrong!!");
    }
  };

  return (
    <CompanycolorContext.Provider
      value={{
        getallcolorsdata,
        addcolor,
        colorsdata,
        deletecolor,
        updatedcolor,
        setColorsdata,
        getcolorbyid,
        getallcolorsdataForsomePages,
      }}
    >
      {props.children}
    </CompanycolorContext.Provider>
  );
}

export default CompanycolorState;
