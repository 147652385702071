import React, { useEffect, useState, useContext } from "react";
import {
  CheckMarkIcon,
  ChevronUp,
  DownArrow,
  EyeIcon,
  SearchIcon,
  TickMarkIcon,
} from "../common/iconComponents/Icons";
import {
  Accordion,
  Text,
  Select,
  Button,
  Input,
  ScrollArea,
} from "@mantine/core";
import CompanyColorContext from "../../ContextFolder/CompanyColorContext/CompanycolorContext";
import CatelogueContext from "../../ContextFolder/CatelogueContext/CatelogueContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as svg from "../../RoleWisePagesAndComponents/Admins_Category/Designs/svg";
import { getallcolorCategoriesData } from "../../services/ColorCategory/colorCategory";
import { pileTypes, productConstructions, sampleSizes } from "./data";

const AccordionComponent = ({
  designDetails,
  colors,
  setSampleSize,
  sampleSize,
  openedMenu,
  setOpenedMenu,
  colorMap,
  setColorMap,
  isEditModeOn,
  setIsEditModeOn,
  onDesignNameChange,
  handle,
  addToLibrary,
  designName,
  isClickOnEditIcon,
  setIsClickOnEditIcon,
  accordionRef,
}) => {
  const navigate = useNavigate();
  const { title, designNumber, picksMtr, repeatSize } = designDetails;
  const { AddRequest } = useContext(CatelogueContext);
  const [colorData, setColorData] = useState([]);
  const location = useLocation();
  const { id } = useParams();

  const [companyColor, setCompanyColors] = useState([]);

  const { getallcolorsdataForsomePages } = useContext(CompanyColorContext);
  const [colorCategory, setColorCategory] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [value, setValue] = useState({
    productConstruction: "Brussels",
    pileType: "100% Wool",
    sampleSize: sampleSize,
    designId: designDetails.id,
  });
  const [showColorPicker, setShowColorPicker] = useState({
    selectedDefaultColorCode: "",
    selectedColorCategory: "",
    selectedCategoryId: "",
    selectedNewColor: "",
  });

  const specifications = [
    { label: "Design Name", value: title },
    { label: "Design Number", value: designNumber },
    // { label: "Picks/ MTR", value: picksMtr },
    { label: "Repeat Size", value: repeatSize },
  ];
  useEffect(() => {
    const getColors = async () => {
      const result = await getallcolorsdataForsomePages({
        isActive: true,
        colorCategory: showColorPicker.selectedCategoryId,
      });
      setCompanyColors(result?.companyColors);
      if (colorData.length === 0) setColorData(result?.companyColors);
    };

    getColors();
  }, [showColorPicker.selectedCategoryId]);

  useEffect(() => {
    const getAllColorCategory = async () => {
      const { colorCategories } = await getallcolorCategoriesData(true);
      setColorCategory(colorCategories);
    };

    getAllColorCategory();
  }, []);
  useEffect(() => {
    // If the path matches '/editpage/library/{id}', open the "Change Color" accordion
    if (location.pathname.startsWith("/editpage/library/")) {
      setOpenedMenu("changeColor");
    } else if (location.pathname.startsWith("/editpage/")) {
      setOpenedMenu("productSpecs");
    }
  }, [location]);

  const data = colors?.map((color) => colorMap[color] || color);
  const handleClickForReqSample = async () => {
    await AddRequest({
      // productConstruction: value?.productConstruction,
      // pileType: value?.pileType,
      sampleSize: sampleSize,
      designId: designDetails?.id,
    });
    navigate("/ThankYoupage");
  };

  const setNewColor = (oldColor, newColor) => {
    setColorMap({
      ...colorMap,
      [oldColor]: newColor,
    });
  };

  const filteredColors = companyColor?.filter((rawColor) => {
    const searchTerm = searchQuery?.toLowerCase();
    return (
      rawColor.name?.toLowerCase().includes(searchTerm) ||
      "" ||
      rawColor.code?.toLowerCase().includes(searchTerm) ||
      ""
    );
  });

  const rawColors = filteredColors?.map((rawColor) => (
    <div className="flex flex-col items-center mt-5">
      <div
        onClick={() => {
          setNewColor(
            showColorPicker.selectedDefaultColorCode,
            rawColor.colorCode
          );
        }}
        style={{
          backgroundColor: rawColor?.colorCode,
        }}
        className={`w-[54.68px] h-12  ${
          false ? "outline-[3.22] xl:outline-4 outline outline-black3  " : ""
        } rounded-md  `}
      />
      <div className="text-black3 my-2 text-center text-[10px]">
        <Text>{rawColor?.name}</Text>
        <Text>{rawColor?.code}</Text>
      </div>
    </div>
  ));

  return (
    <Accordion
      variant="separated"
      value={openedMenu}
      onChange={setOpenedMenu}
      multiple={false}
      radius="md"
      styles={{
        chevron: { width: 32 },
        content: { padding: 10 },
      }}
      chevron={<ChevronUp stroke="#636364" />}
    >
      <Accordion.Item
        className="space-y-4 border-none rounded-lg"
        value="productSpecs"
      >
        <Accordion.Control
          className={`border-2 px-4 py-1.5 rounded-lg border-midGrey border-solid ${
            openedMenu === "productSpecs" ? "text-orange-500" : "text-comet"
          }`}
        >
          Product Specifications
        </Accordion.Control>
        <Accordion.Panel className="border-2 rounded-lg border-midGrey">
          <table className="w-full my-3 text-comet">
            <tbody>
              {specifications?.map(({ value, label }) =>
                value ? (
                  <tr key={label}>
                    <td className="text-base font-thin py-2">{label}</td>
                    <td className="text-base font-bold">{value}</td>
                  </tr>
                ) : null
              )}
            </tbody>
          </table>
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item
        className="space-y-4 border-none rounded-lg"
        value="changeColor"
      >
        <Accordion.Control
          className={`border-2 px-4 py-1.5 rounded-lg border-midGrey border-solid ${
            openedMenu === "changeColor" ? "text-orange-500" : "text-comet"
          } font-extrabold`}
        >
          Customise Color
        </Accordion.Control>
        <Accordion.Panel className="border-2 rounded-lg border-midGrey">
          <Text className="text-base md:block text-center text-comet font-semibold">
            {location.pathname === `/editpage/${id}`
              ? "Default Color"
              : "Click to Change Color"}
          </Text>
          <div
            className={`grid w-full cursor-pointer md:w-10/12 lg:w-3/4 mx-auto mt-3 gap-y-3 ${
              colors?.length === 1
                ? "grid-cols-1 justify-center"
                : colors?.length === 2
                ? "grid-cols-2 justify-center"
                : "grid-cols-3"
            }`}
          >
            {colors?.map((color, index) => {
              const newColorName = colorData?.find(
                (rawColor) => rawColor.colorCode === colorMap[color]
              );
              const defaultColorName = colorData?.find(
                (rawColor) =>
                  rawColor.colorCode.toLowerCase() === color.toLowerCase()
              );

              const isSelectedColor =
                showColorPicker.selectedDefaultColorCode === color;
              return (
                <div
                  key={index} // Move the key prop here to avoid issues
                  className={`flex col-span-1 w-full py-5 ${
                    isSelectedColor
                      ? "border-whiskeySour border-2 rounded-lg "
                      : ""
                  } items-center justify-center gap-y-1.5 flex-col`}
                >
                  <div
                    onClick={() => {
                      setIsEditModeOn({
                        ...isEditModeOn,
                        colorEditMode: true,
                      });
                      setShowColorPicker({
                        ...showColorPicker,
                        selectedDefaultColorCode: color,
                      });
                    }}
                    style={{ backgroundColor: colorMap[color] || color }}
                    className="w-[45.13px] xl:w-14 xl:h-14 rounded-lg h-[45.13px] relative"
                  >
                    {isSelectedColor ? (
                      <CheckMarkIcon className="w-[19.88] h-[19.88] xl:w-[24.67px] xl:h-[24.67px] absolute -top-3.5 -right-4 md:-right-6" />
                    ) : null}
                  </div>
                  <Text className="text-comet text-[10px] lg:text-base">
                    {newColorName?.name || defaultColorName?.name}
                  </Text>
                  <Text className="text-comet text-[10px] lg:text-base">
                    {newColorName?.code || defaultColorName?.code}
                  </Text>
                </div>
              );
            })}
          </div>

          {showColorPicker.selectedDefaultColorCode && (
            <>
              <Text className="text-base mt-4 mb-2 font-bold text-comet ml-1 text-center">
                Select the Color
              </Text>
              <Input
                placeholder="Search color..."
                rightSection={<SearchIcon stroke="#636364" />}
                value={searchQuery}
                mt={8}
                mb={10}
                styles={{
                  rightSection: { marginRight: "10px" },
                  input: {
                    height: "48px",
                    backgroundColor: "#F5F5F5",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "19.2px",
                    border: "1px solid #E3E3E3",
                    "::placeholder": { color: "#636364" },
                  },
                }}
                radius={7}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <ScrollArea
                type="auto"
                styles={{
                  scrollbar: {
                    marginLeft: "10px",
                    marginRight: "10px",
                  },
                }}
                className="rounded-md bg-midGrey overflow-x-auto"
                scrollbarSize={2}
              >
                <div className="flex cursor-pointer	flex-row h-[41.91px] xl:h-14 items-center justify-center gap-x-[3px] md:gap-x-4 lg:gap-x-5 xl:gap-x-6 2xl:gap-x-7 mx-3">
                  {colorCategory?.map((category) => {
                    const isCategorySelected =
                      showColorPicker.selectedCategoryId === category?.id;
                    return (
                      <div
                        key={category.id}
                        onClick={() =>
                          setShowColorPicker({
                            ...showColorPicker,
                            selectedColorCategory: category?.colorCode,
                            selectedCategoryId: category.id,
                          })
                        }
                        style={{
                          backgroundColor: category?.colorCode,
                        }}
                        className={`w-[25.79px] xl:h-8 xl:w-8 ${
                          isCategorySelected
                            ? "outline-[3.22] xl:outline-4 outline outline-black3"
                            : ""
                        } rounded-full h-[25.79px]`}
                      />
                    );
                  })}
                </div>
              </ScrollArea>
            </>
          )}

          {showColorPicker.selectedDefaultColorCode && (
            <>
              <ScrollArea
                type="auto"
                scrollbarSize={8}
                className="max-h-[185px] w-full overflow-y-auto"
                styles={{
                  scrollbar: {
                    backgroundColor: "#E3E3E3",
                    borderRadius: "8px",
                  },
                }}
              >
                <div className="w-[98.88%] cursor-pointer lg:w-[99%] grid grid-cols-4 lg:grid-cols-6 xl:grid-cols-8">
                  {rawColors}
                </div>
              </ScrollArea>
            </>
          )}
          {!isClickOnEditIcon &&
            (isEditModeOn.colorEditMode ? (
              <div className="flex justify-center">
                <Button
                  radius={8}
                  onClick={() => {
                    setIsEditModeOn({
                      ...isEditModeOn,
                      saveDesignEditMode: true,
                      colorEditMode: false,
                    });
                    setIsClickOnEditIcon(false);
                  }}
                  mt={10}
                  rightIcon={
                    <TickMarkIcon stroke="white" className="md:w-6 xl:w-7" />
                  }
                  className="bg-whiskeySour h-12 md:text-xs lg:text-base md:h-10 xl:text-base xl:h-12 w-full md:w-[215px]"
                >
                  Save Changes
                </Button>
              </div>
            ) : isEditModeOn.saveDesignEditMode ? (
              <div className="flex w-full flex-col lg:flex-row lg:items-center lg:gap-x-2">
                <Input
                  placeholder="Name your design . . ."
                  mt={8}
                  mb={10}
                  value={designName}
                  name="name"
                  onChange={onDesignNameChange}
                  style={{ flex: "1" }}
                  styles={{
                    rightSection: { marginRight: "10px" },
                    input: {
                      height: "48px",
                      backgroundColor: "#F5F5F5",
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "19.2px",
                      border: "1px solid #636364",
                      "::placeholder": { color: "#636364" },
                    },
                  }}
                  radius={7}
                />
                <div className="flex flex-row w-full lg:w-[45%] gap-x-2 text-base">
                  <Button
                    onClick={() => {
                      if (location.pathname === `/editpage/library/${id}`) {
                        handle();
                      } else if (location.pathname === `/editpage/${id}`) {
                        addToLibrary();
                      }
                    }}
                    radius={8}
                    rightIcon={
                      <TickMarkIcon stroke="white" className="md:w-6 xl:w-7" />
                    }
                    className="bg-whiskeySour h-12 text-base w-full"
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => {
                      setIsEditModeOn({
                        ...isEditModeOn,
                        saveDesignEditMode: false,
                        colorEditMode: true,
                      });
                    }}
                    radius={8}
                    className="bg-comet h-12 text-base w-full"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            ) : null)}
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item
        className="space-y-4 border-none rounded-lg"
        value="sampleSize"
        ref={accordionRef}
      >
        <Accordion.Control
          className={`border-2 px-4 py-1.5 rounded-lg border-midGrey border-solid ${
            openedMenu === "sampleSize" ? "text-orange-500" : "text-comet"
          }`}
        >
          Sample Size
        </Accordion.Control>
        <Accordion.Panel className="border-2 rounded-lg  border-midGrey">
          <div className="w-full mt-2.5 py-4 xl:py-6 2xl:py-8 3xl:py-10 4xl:w-[70%]  3xl:w-9/12 md:w-11/12 xl:w-10/12 mx-auto">
            <div className="flex flex-row justify-center gap-x-3 w-full">
              {sampleSizes?.map(({ label, value }, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => setSampleSize(value)}
                    className={`border-2 ${
                      sampleSize === value
                        ? "border-whiskeySour text-whiskeySour"
                        : "border-midGrey text-comet"
                    } flex flex-col items-center text-base font-bold justify-center rounded-lg w-full h-[77.78px] 2xsm:h-[94.58px] xsm:h-[109.86] sm:h-[112px] 3xl:text-xl 3xl:h-[140px]`}
                  >
                    <Text>{label}</Text>
                    <Text>feet</Text>
                  </div>
                );
              })}
            </div>
            {/* <div className="flex flex-col mt-4.5 md:flex-row gap-y-3 gap-x-3 ">
              <Select
                value={value?.productConstruction}
                onChange={(e) => {
                  setValue({ ...value, productConstruction: e });
                }}
                label="Product Construction:"
                variant="filled"
                size="xl"
                radius={8}
                dropdownPosition="bottom"
                rightSection={
                  <DownArrow
                    className="cursor-pointer"
                    stroke="#636364"
                    width={20}
                    height={20}
                  />
                }
                styles={styles}
                data={productConstructions}
              />

              <Select
                value={value?.pileType}
                onChange={(e) => setValue({ ...value, pileType: e })}
                label="Pile Type:"
                variant="filled"
                radius={8}
                size="xl"
                dropdownPosition="bottom"
                rightSection={
                  <DownArrow
                    className="cursor-pointer"
                    stroke="#636364"
                    width={20}
                    height={20}
                  />
                }
                styles={styles}
                data={pileTypes}
              />
            </div> */}
            <Button
              fullWidth={true}
              onClick={handleClickForReqSample}
              radius={8}
              className="mt-7.5 mx-auto bg-whiskeySour h-12 xl:text-base"
            >
              Submit
            </Button>
          </div>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default AccordionComponent;

const styles = {
  root: { width: "100%" },
  rightSection: { pointerEvents: "none" },
  label: { fontSize: "16px", marginBottom: "13px" },
  input: {
    backgroundColor: "#F5F5F5",
    fontSize: "16px",
    border: "1px solid #636364",
  },
  item: {
    fontSize: "16px",
    padding: "6px",
    marginTop: "5px",
    "&[data-selected]": {
      backgroundColor: "#D79963",
    },
    "&[data-hovered]": {
      backgroundColor: "rgba(215, 153, 99, 0.6)",
    },
    "&[data-selected][data-hovered]": {
      backgroundColor: "#D79963",
    },
  },
};
