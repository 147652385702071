import { z } from "zod";

const AddAccountSchema = z.object({
  email: z
    .string()
    .refine((val) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(val), {
      message: "Invalid Email.",
    }),

name: z
.string()
 
});

export default AddAccountSchema;