import * as React from "react";
const NewCheckIcon = (props) => (
  <svg
    width={96}
    height={96}
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M48.0002 85.0002C27.5602 85.0002 11.0002 68.4402 11.0002 48.0002C11.0002 27.5602 27.5602 11.0002 48.0002 11.0002C68.4402 11.0002 85.0002 27.5602 85.0002 48.0002C85.0002 68.4402 68.4402 85.0002 48.0002 85.0002Z"
      stroke="#D79963"
      strokeWidth={4.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.9709 62.0595L26.9121 48.0007"
      stroke="#D79963"
      strokeWidth={4.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M69.0886 33.9419L40.9709 62.0595"
      stroke="#D79963"
      strokeWidth={4.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default NewCheckIcon;
