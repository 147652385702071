import React from "react";
import { MantineProvider } from "@mantine/core";
import { Route, Routes } from "react-router-dom";
import { useState } from "react";
import {
  publicRoutes,
  protectedRoutesForAdmin,
  protectedRoutesForUser,
} from "./Components/RoutesMiddleware/Routes/ForexportRoutes";
import RoutesMiddlewareAdmin from "./Components/RoutesMiddleware/RoutesMiddlewareAdmin";
import RoutesMiddlewareUser from "./Components/RoutesMiddleware/RoutesMiddlewareUser";
import { useDebouncedState } from "@mantine/hooks";
import NavBar from "./Components/Header/Navbar1/NavBar";
import { NotificationsProvider } from "@mantine/notifications";

function App() {
  const [fileSource, setFileSource] = useState("");
  const [photo, setPhoto] = useState("");
  const [name, setName] = useState("");
  const [searchstring, setSearchstring] = useDebouncedState("", 1000);

  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{ fontFamily: "franklin-gothic-atf, sans-serif" }}
    >
      <NotificationsProvider position="top-right">
        <Routes>
          {publicRoutes.map((route) => (
            <Route path={route.path} exact element={route.component()} />
          ))}
          {localStorage.getItem("role") === "SuperAdmin" &&
            protectedRoutesForAdmin.map(({ path, component: Component }) => (
              <Route
                path={path}
                exact
                element={
                  <RoutesMiddlewareAdmin>
                    <NavBar
                      setSearchstring={setSearchstring}
                      searchstring={searchstring}
                    />
                    <Component
                      setFileSource={setFileSource}
                      setPhoto={setPhoto}
                      photo={photo}
                      fileSource={fileSource}
                      name={name}
                      setName={setName}
                      setSearchstring={setSearchstring}
                      searchstring={searchstring}
                    />
                  </RoutesMiddlewareAdmin>
                }
              />
            ))}
          {localStorage.getItem("role") === "User" &&
            protectedRoutesForUser.map(({ path, component: Component }) => (
              <Route
                path={path}
                exact
                element={
                  <RoutesMiddlewareUser>
                    <NavBar
                      setSearchstring={setSearchstring}
                      searchstring={searchstring}
                    />
                    <Component
                      setPhoto={setPhoto}
                      photo={photo}
                      searchstring={searchstring}
                      setSearchstring={setSearchstring}
                    />
                  </RoutesMiddlewareUser>
                }
              />
            ))}
        </Routes>
      </NotificationsProvider>
    </MantineProvider>
  );
}

export default App;
