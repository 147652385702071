
import { z } from "zod";

const resetFormSchema = z
  .object({
    password: z
      .string()
      .refine(
        (val) =>
          /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/.test(
            val,
          ),
        {
          message:
            'Password must contain at least 8 characters, one uppercase, one number and one special case character.',
        },
      ),
    confirmPassword: z
      .string()
      .refine(
        (val) =>
          /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/.test(
            val,
          ),
        {
          message:
            'Password must contain at least 8 characters, one uppercase, one number and one special case character.',
        },
      ),
  })
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: 'custom',
        path: ['confirmPassword'],
        message: 'Password did not match.',
      });
    }
  });

  export default resetFormSchema;