import React from 'react'
import { Navigate } from 'react-router-dom';


const RoutesMiddlewareUser = ({children}) => {


    return localStorage.getItem("role") === "User" ? (
        children
      ) : (
        <Navigate to="/login"/>
      
      );
 
}

export default RoutesMiddlewareUser
