import React, { useState, useEffect, useContext } from "react";
import UserContext from "../../../ContextFolder/UserContext/UserContext";
import {
  Button,
  Text,
  TextInput,
  PasswordInput,
  Box,
  Image,
} from "@mantine/core";
import { Link } from "react-router-dom";
import { useForm, zodResolver } from "@mantine/form";
import Schema from "../ValidationLogic/Schema";
import backGroundCoverImage from "../../../Images/login-bg.png";
import wiltonLogo from "../../../Images/wilton-logo.png";
import formCoverImage from "../../../Images/formCoverImage.png";
import SplashPage from "./SplashPage";

export default function Register() {
  const context = useContext(UserContext);
  const [loading, setLoading] = useState(true);

  const signInForm = useForm({
    validateInputOnChange: true,
    validate: zodResolver(Schema),
    initialValues: {
      email: "",
      password: "",
    },
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Hide splash screen after 3 seconds (or any duration you want)
    }, 3000); // Adjust the duration as needed

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);

  if (loading) {
    return <SplashPage />;
  }

  return (
    <Box
      className="bg-center flex flex-row justify-center items-center h-screen w-full"
      style={{
        backgroundImage: `url(${backGroundCoverImage})`,
        backgroundRepeat: "repeat", // Repeats the image both horizontally and vertically
        backgroundSize: "auto", // Keeps the original size of the PNG
      }}
    >
      <div className="flex flex-row w-11/12 md:w-[53%] lg:w-203 xl:w-242.5 justify-between bg-white p-2 gap-3 rounded-3xl">
        <div className="lg:w-[45%] flex flex-col space-y-5 justify-evenly items-center ">
          <div className="w-40 md:w-39 lg:w-32  md:mt-10 xl:mt-15 xl:w-40 mt-5">
            <Image src={wiltonLogo} width={"100%"} />
          </div>
          <form
            onSubmit={signInForm.onSubmit(async (values) => {
              await context.Login(values);
            })}
            className="text-center w-11/12 md:w-10/12"
          >
            <Text className="text-center text-comet text-3xl xl:mt-6 mt-4 mb-9 md:mb-4 lg:mt-4 font-normal">
              Login
            </Text>
            <TextInput
              placeholder="Login"
              size="lg"
              required
              styles={{
                input: {
                  backgroundColor: "#F5F5F5",
                  fontSize: "15.5px",
                  fontWeight: 400,
                  lineHeight: "19.2px",
                  border: "1px solid #636364",
                  "::placeholder": { color: "#636364" },
                },
              }}
              fullWidth={true}
              radius={7}
              {...signInForm.getInputProps("email")}
            />
            <PasswordInput
              styles={{
                innerInput: {
                  "::placeholder": {
                    color: "#636364",
                    fontSize: "15.5px",
                    fontWeight: 400,
                    lineHeight: "19.2px",
                  },
                },
                input: {
                  backgroundColor: "#F5F5F5",
                  border: "1px solid #636364",
                },
              }}
              placeholder="Password"
              mt="md"
              required
              fullWidth={true}
              size="lg"
              radius={7}
              {...signInForm.getInputProps("password")}
              mb={20}
            />
            <Button
              fullWidth
              className="mt-7"
              size="lg"
              style={{
                backgroundColor: "#636364",
                fontSize: "16px",
                lineHeight: "19.2px",
              }}
              type="submit"
            >
              Sign in
            </Button>
            <div className="mt-6 md:mt-2 ">
              <Link
                className="text-sm md:text-xs hover:text-eerieBlack lg:text-xs xl:text-sm"
                to="/ForgetPass"
              >
                Forgot password?
              </Link>
            </div>
          </form>
          <Text className="text-center text-sm md:text-xs md:pt-3 xl:text-sm lg:pt-0 mb-3 lg:mb-0 pb-5  lg:pb-0 xl:pb-5 w-3/4 2xsm:w-[85%] lg:w-[98%] ">
            New user? Reach out to us at support@wilton.in to set up your
            account
          </Text>
        </div>
        <div className="w-[500px] xl:w-132.5 hidden lg:block">
          <Image src={formCoverImage} width={"100%"} />
        </div>
      </div>
    </Box>
  );
}
