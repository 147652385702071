import React from "react";
import { Button, Flex, Text } from "@mantine/core";
import useStyles from "../../../../Components/Style/UseStyle";
import { useNavigate } from "react-router-dom";
function Design({ fileSource }) {
  const navigate=useNavigate();
  const { classes } = useStyles();
  return (
    <>
      <Text ml={7} mb={10} weight={600} style={{color:"#051C48"}} size={17}>
          Uploaded Design
        </Text>
     <div style={{paddingLeft:"10px", paddingRight:"10px"}}>
   
      <div className={classes.backofdesign}>
      

        <Flex justify={{ sm: "center" }}>
          {" "}
          <div className={classes.design}
          style={{ backgroundImage: `url(${fileSource})`}}>
            {/* <img src={texture} alt="not found" className={classes.design} /> */}
          </div>
        
        </Flex>
        <Flex justify={{ sm: "center" }}>
          <Button mt={20} mb={20} style={{ width: 120, color:"#051c48", border:"1px solid #051c48" }} variant="default" onClick={()=>{navigate("/UploadFile")}}>
            Upload New
          </Button>
        </Flex>
      </div>
      </div>
    </>
  );
}

export default Design;
