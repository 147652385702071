import React from "react";
import { useForm, zodResolver } from "@mantine/form";
import { Container, Text, Button, PasswordInput } from "@mantine/core";
import useStyles from "../../../Components/Style/UseStyle";
import resetFormSchema from "../../../Components/lockscreen/ValidationLogic/resetFormSchema";
import { useNavigate, useParams } from "react-router-dom";
import { useContext } from "react";
import UserContext from "../../../ContextFolder/UserContext/UserContext";

function ResetPass() {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { id } = useParams();
  const form = useForm({
    validateInputOnChange: true,
    validate: zodResolver(resetFormSchema),
    initialValues: {
      password: "",
      confirmPassword: "",
      status: false,
    },
  });
  const context = useContext(UserContext);
  return (
    <div className={classes.background}>
      <Container size="sm" py="xl">
        <div className={classes.uploadfile}>
          <Text size={25} mb={20}>
            Reset Password
          </Text>

          <Container mt={5}>
            <form
              onSubmit={form.onSubmit(async (values) => {
                await context.resetpasswordByAdmin(id, values.confirmPassword);
                navigate(`/Editacc/${id}`)
              })}
            >
              <PasswordInput
                placeholder="*******"
                label="New Password"
                withAsterisk
                sx={{ flex: 1 }}
                {...form.getInputProps("password")}
                mb={10}
              />
              <PasswordInput
                mt="md"
                label="Confirm Password"
                placeholder="*******"
                withAsterisk
                sx={{ flex: 1 }}
                {...form.getInputProps("confirmPassword")}
                mb={40}
              />

              <hr />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button type="submit" mt="md"  style={{ backgroundColor: "#E27612" }}>
                  Save
                </Button>
                <Button
                  mt="md"
                  variant="outline"
                  onClick={() => {
                    navigate(`/Editacc/${id}`);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </Container>
        </div>
      </Container>
    </div>
  );
}

export default ResetPass;
