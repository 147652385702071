import { Button, Text, PasswordInput, Box, Image } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import resetFormSchema from "../ValidationLogic/resetFormSchema";
import { useSearchParams } from "react-router-dom";
import React from "react";
import backGroundCoverImage from "../../../Images/Frame.svg";
import wiltonLogo from "../../../Images/wilton-logo.png";
import formCoverImage from "../../../Images/formCoverImage.png";
export default function CreateNewPass() {
  const form = useForm({
    validateInputOnChange: true,
    validate: zodResolver(resetFormSchema),
    initialValues: {
      password: "",
      confirmPassword: "",
      status: false,
    },
  });

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const navigate = useNavigate();

  useEffect(() => {
    const varification = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/auth/verify`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ token }),
          }
        );
        const res = await response.json();
        if (!res.status) {
          console.log("user not found");
          alert("User not Found");
        }
      } catch {
        alert("Not Found");
        // navigate("/login");
      }
    };

    varification();
    // resetpassword();
    // eslint-disable-next-line
  }, []);

  const resetpassword = async (password, resetPasswordToken) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/auth/change-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ password, resetPasswordToken }),
        }
      );
      await response.json();
      // // console.log("dd",loginResponse)
      // if (!res.status) {
      //   console.log("user not found");
      //   alert("User not Found");
      // }
    } catch {
      alert("Something Wrong!!!");
      // navigate("/login");
    }
  };

  return (
    <>
      <Box
        className="bg-cover bg-center  flex flex-row justify-center items-center h-screen w-full"
        style={{ backgroundImage: `url(${backGroundCoverImage})` }}
      >
        <div className="flex flex-row w-11/12 md:w-1/2 lg:w-203 xl:w-242.5 justify-between bg-white p-2 gap-3 rounded-3xl">
          <div className="lg:w-[45%] flex flex-col space-y-5 justify-evenly  items-center ">
            <div className="w-40 md:w-39 lg:w-32  md:mt-10 xl:mt-15 xl:w-40  mt-5">
              <Image src={wiltonLogo} width={"100%"} />
            </div>
            <Text className="text-center text-comet text-3xl xl:mt-6 mt-4 mb-9 md:mb-4 lg:mt-4 font-normal">
              Create New Password
            </Text>
            <Text className="text-center text-xs xl:text-base">
              Your new password must be different <br />
              from previous used passwords.
            </Text>
            <form
              onSubmit={form.onSubmit(async (values) => {
                await resetpassword(values.confirmPassword, token);
                navigate("/login");
                // await context.Login(values);
                // await context.getmyprofile(values);
              })}
              className="w-11/12 md:w-10/12 lg:py-0 py-3 space-y-6"
            >
              <div className="space-y-5">
                <PasswordInput
                  label="New Password"
                  placeholder="*****"
                  required
                  size="lg"
                  // mt="md"
                  // radius={7}
                  styles={{
                    input: {
                      fontSize: "15.5px",
                      fontWeight: 400,
                      lineHeight: "19.2px",
                      backgroundColor: "#F5F5F5",
                      border: "1px solid #636364",
                      "::placeholder": { color: "#636364" },
                    },
                  }}
                  {...form.getInputProps("password")}
                />
                <PasswordInput
                  label="Confirm Password"
                  placeholder="*****"
                  required
                  // mt="md"
                  size="lg"
                  {...form.getInputProps("confirmPassword")}
                  mb={20}
                  // radius={7}
                  styles={{
                    input: {
                      fontSize: "15.5px",
                      fontWeight: 400,
                      lineHeight: "19.2px",
                      backgroundColor: "#F5F5F5",
                      border: "1px solid #636364",
                      "::placeholder": { color: "#636364" },
                    },
                  }}
                />
                <Button
                  fullWidth
                  size="lg"
                  style={{ backgroundColor: "#636364", fontSize: "15px" }}
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            </form>
            <Text className="text-center text-sm md:text-xs md:pt-3 xl:text-sm lg:pt-0 mb-3 lg:mb-0 pb-5  lg:pb-0 xl:pb-5 w-3/4 2xsm:w-[85%] lg:w-[98%] ">
              Don't have an account? Reach out to us at support@wilton.in to set
              up your account
            </Text>
          </div>
          <div className="w-[500px] xl:w-132.5 hidden lg:block">
            <Image src={formCoverImage} width={"100%"} />
          </div>
        </div>
      </Box>
    </>
  );
}
