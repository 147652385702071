import { Container, Flex, Button, Text, Group } from "@mantine/core";
import { useContext, useRef } from "react";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { IconCloudUpload, IconX, IconDownload } from "@tabler/icons";
import React from "react";
import useStyles from "../../../Components/Style/UseStyle";
import { Link, useNavigate } from "react-router-dom";
import LibraryContext from "../../../ContextFolder/LibraryContext/LibraryContext";

function UploadFile({ setFileSource }) {
  const navigate = useNavigate();
  const [images, setImages] = React.useState([]);
  const { classes, theme } = useStyles();
  const openRef = useRef();
  const context = useContext(LibraryContext);

  return (
    <>
      <div className={classes.backgroundColor}>
        <Container className={classes.containersize} py="xl">
          <div className={classes.uploadfile}>
            <div>
              <Dropzone
                openRef={openRef}
                onDrop={(acceptedFiles) => {
                  setImages(
                    acceptedFiles.map((file) => {
                      const fileURL = URL.createObjectURL(file);
                      setFileSource(fileURL);
                      navigate("/DesignUploaded");

                      // Read the file and extract SVG dimensions
                      const reader = new FileReader();
                      reader.onload = (event) => {
                        const parser = new DOMParser();
                        const svgDocument = parser.parseFromString(
                          event.target.result,
                          "image/svg+xml"
                        );
                        const svgElement = svgDocument.querySelector("svg");

                        if (svgElement) {
                          let width = svgElement.getAttribute("width");
                          let height = svgElement.getAttribute("height");

                          // If width/height are not available, extract from viewBox
                          if (!width || !height) {
                            const viewBox = svgElement.getAttribute("viewBox");
                            if (viewBox) {
                              const viewBoxValues = viewBox.split(" ");
                              width = viewBoxValues[2];
                              height = viewBoxValues[3];
                            }
                          }

                          // Convert width and height to inches (assuming 96 DPI)
                          const DPI = 96;
                          const widthInInches = width
                            ? (parseFloat(width) / DPI).toFixed(2)
                            : null;
                          const heightInInches = height
                            ? (parseFloat(height) / DPI).toFixed(2)
                            : null;
                          // Store the width and height in context
                          if (context && widthInInches && heightInInches) {
                            context.setSvgDimensions({
                              width: widthInInches,
                              height: heightInInches,
                            });
                          }
                        }
                      };
                      reader.readAsText(file);

                      return Object.assign(file, {
                        preview: fileURL,
                      });
                    })
                  );
                }}
                radius="md"
                accept={[MIME_TYPES.svg]}
                maxSize={30 * 1024 ** 2}
              >
                <div style={{ pointerEvents: "none" }}>
                  <Group position="center">
                    <Dropzone.Accept>
                      <IconDownload
                        size={50}
                        color={theme.colors[theme.primaryColor][6]}
                        stroke={1.5}
                      />
                    </Dropzone.Accept>
                    <Dropzone.Reject>
                      <IconX
                        size={50}
                        color={theme.colors.red[6]}
                        stroke={1.5}
                      />
                    </Dropzone.Reject>
                    <Dropzone.Idle>
                      <IconCloudUpload size={70} color="#E27612" stroke={2} />
                    </Dropzone.Idle>
                  </Group>

                  <Text align="center" weight={500} size={25} mt="xl">
                    <Dropzone.Reject></Dropzone.Reject>
                    <Dropzone.Idle>
                      <text style={{ color: "#E27612" }}> Click here</text> to
                      upload or drag and drop
                    </Dropzone.Idle>
                  </Text>
                  <Text align="center" size={12} mt="xs" color="dimmed">
                    Supports: SVG Image
                  </Text>
                  <Text align="center" size={18} color="#E27612" mt={50}>
                    Upload an SVG vector file for an accurate result.
                  </Text>
                </div>
              </Dropzone>
            </div>
            <Flex
              mih={70}
              gap="md"
              justify="center"
              align="center"
              direction="row"
              wrap="wrap"
            >
              <Link style={{ textDecoration: "none" }} to="/ArticleCard">
                <Button
                  style={{ width: 140, color: "#051C48" }}
                  fullWidth
                  variant="default"
                >
                  Cancel
                </Button>
              </Link>
            </Flex>
          </div>
          {/* <img src={images[0].preview} alt="ghvj"/> */}
        </Container>
      </div>
    </>
  );
}

export default UploadFile;
