import { useContext, useEffect, useState } from "react";
import '../../../App.css'
import {
  Table,
  Text,
  Container,
  Button,
  Badge,
  Avatar,
  Title,
} from "@mantine/core";
import useStyles from "../../../Components/Style/UseStyle";
import "../../../App.css";
import { IconCirclePlus} from "@tabler/icons";
import { Link, useNavigate } from "react-router-dom";
import LibraryContext from "../../../ContextFolder/LibraryContext/LibraryContext";
import Vectorleftsidearreow from "../../../Images/Vectorleftsidearreow.svg";
import editlibicon from "../../../Images/editlibicon.svg";
import deleteicon from "../../../Images/deleteicon.svg";

import Vectorrightsidearreow from "../../../Images/Vectorrightsidearreow.svg";
import bluedot from "../../../Images/bluedot.svg";
import orangedot from "../../../Images/orangedot.svg";

import _ from "lodash";
export default function Libraries() {
  const { classes } = useStyles();
  const [currentpage, setCurrentpage] = useState(1);
  const [posts, setPosts] = useState();
  const [size, setSize] = useState();
  const [number, setNumber] = useState();
  const context = useContext(LibraryContext);

  async function getData() {
    const result = await context.getlibrary(currentpage);
    setPosts(result.libraries);
    setNumber(result.count);
    setSize(result.libraries.length);
  }
  const navigate = useNavigate();
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);
  const pageCount = number ? Math.ceil(number / size) : 0;
  const pages = _.range(1, pageCount + 1);
  const handleclick = async (num) => {
    const g = await context.getlibrary(num);
    setPosts(g.libraries);
    setCurrentpage(num);
  };
  const handleNext = async (num) => {
    const g = await context.getlibrary(num);
    setPosts(g.libraries);
    setCurrentpage(num);
  };

  const handlePrev = async (num) => {
    const g = await context.getlibrary(num);
    setPosts(g.libraries);
    setCurrentpage(num);
  };

  const rows = posts?.map((row, index) => (
    <tr key={index}>
      <td style={{ display: "flex" }}>
        <Avatar size={36} variant="filled" color="cyan" radius={26} mr={14}>
          <Text size={17} color="white" weight={500}>
            {row.name.slice(0, 1).charAt(0).toUpperCase()}{" "}
          </Text>
        </Avatar>

        <Text
          size={18}
          weight={600}
          style={{ padding: "4px", color: "#051C48" }}
        >
          {row.name}
        </Text>
      </td>
      <td>
        {row.status === true ? (
          <Badge color="orange" size="lg" radius="lg">
            <img src={orangedot} alt="" srcset="" />{" "}
            <text style={{ marginLeft: "10px", marginRight: "7px" }}>
              {" "}
              Active
            </text>
          </Badge>
        ) : (
          <Badge color="blue" size="lg" radius="lg">
            <img src={bluedot} alt="" srcset="" />{" "}
            <text style={{ marginLeft: "10px" }}> Inactive</text>
          </Badge>
        )}
      </td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>

      <td>
        <div className={classes.emailflex}>
          <Link to={`/EditLib/${row.id}`}>
            {" "}
            <img
              src={editlibicon}
              alt=""
              srcset=""
              style={{
                cursor: "pointer",

                color: "#051C48",
              }}
            />
          </Link>

          <div
            style={{ color: "#051C48", cursor: "pointer", marginLeft: "5px", marginRight:"20px" }}
            onClick={async (e) => {
              e.preventDefault();
              const response = window.confirm(
                "Are you sure to delete this Library?"
              );
              if (response) {
                await context.deletelib(row.id, posts);
                const g = await context.getlibrary(currentpage);
                setPosts(g.libraries);
                setCurrentpage(currentpage);
              } else {
                navigate("/Libraries");
              }
            }}
          >
            {" "}
            <img src={deleteicon} alt="" srcset="" />
          </div>
        </div>
      </td>
    </tr>
  ));
  return (
    <>
      <div className={classes.backgroundColor}>
        <Container
          fluid
          py="xl"
          className={classes.inner}
          style={{ padding: "22px" }}
        >
          <div className={classes.navtype} style={{ marginBottom: "30px" }}>
            <Text className={classes.catalogue}>Libraries</Text>
            <Link to="/addlibrary">
              {" "}
              <Button
                style={{ backgroundColor: "#E27612", borderRadius: "32px" }}
              >
                <IconCirclePlus />
                <Text color="white" ml={5}>
                  Add New Library
                </Text>
              </Button>
            </Link>{" "}
          </div>
          {rows?.length === 0 ? (
            <Title size={30} align="center" m={100} weight="400">
              No library to show
            </Title>
          ) : (
            <div className={classes.contactlist}>
              <div style={{ height: "100%", marginTop: "20px" }}>
                <Table
                  highlightOnHover
                  striped
                  verticalSpacing="md"
                  horizontalSpacing="xl"
                  fontSize="xl"
                >
                  <thead>
                    <tr>
                      <th style={{ color: "#051C48" }}>Library</th>
                      <th style={{ color: "#051C48" }}>Status</th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>

                      <th></th>
                    </tr>
                  </thead>
                  <tbody>{rows}</tbody>
                </Table>
              </div>
            </div>
          )}

          {rows?.length === 0 ? (
            ""
          ) : (
            <div style={{ marginTop: "40px", marginBottom: "10px" }}>
              <nav className="d-flex justify-content-center">
                {pageCount === 1 ? null : (
                  <ul className="pagination">
                    {currentpage === 1 ? (
                      <li style={{ fontSize: "16px" }}>
                        {" "}
                        <img src={Vectorleftsidearreow} alt="" srcset="" />
                      </li>
                    ) : (
                      <li
                        style={{ cursor: "pointer", fontSize: "16px" }}
                        onClick={() => {
                          handlePrev(currentpage - 1);
                        }}
                      >
                        <img src={Vectorleftsidearreow} alt="" srcset="" />
                      </li>
                    )}
                    {pageCount === 1
                      ? null
                      : pages.map((num) => (
                          <li
                            className={num === currentpage ? "active" : ""}
                            style={{ cursor: "pointer", fontSize: "16px" }}
                            onClick={() => {
                              handleclick(num);
                            }}
                          >
                            {num}
                          </li>
                        ))}
                    {pages.length === currentpage ? (
                      <li style={{ fontSize: "16px" }}>
                        <img src={Vectorrightsidearreow} alt="" srcset="" />
                      </li>
                    ) : (
                      <li
                        style={{ cursor: "pointer", fontSize: "16px" }}
                        onClick={() => {
                          handleNext(currentpage + 1);
                        }}
                      >
                        <img src={Vectorrightsidearreow} alt="" srcset="" />
                      </li>
                    )}
                  </ul>
                )}
              </nav>
            </div>
          )}
        </Container>
      </div>
    </>
  );
}
