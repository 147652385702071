import { Button, Container, MultiSelect, Text, TextInput } from "@mantine/core";
import React, { useCallback, useContext, useEffect, useState } from "react";
import CompanycolorContext from "../../../../ContextFolder/CompanyColorContext/CompanycolorContext";
import useStyles from "../../../../Components/Style/UseStyle";
import { Link, useNavigate } from "react-router-dom";
import { getallcolorCategoriesData } from "../../../../services/ColorCategory/colorCategory";
import { IconCheck } from "@tabler/icons";
function Addcolor() {
  const context = useContext(CompanycolorContext);
  const [colorCategories, setColorCategories] = useState([]);
  const [libraries, setLibraries] = useState([]);

  const navigate = useNavigate();
  const { addcolor } = context;
  const [color, setColor] = useState({
    name: "",
    colorCode: "",
    code: "",
    colorCategories: [],
  });

  const handleClick = (e) => {
    e.preventDefault();
    if (!color.name || !color.colorCode) {
      alert("please enter Details");
    } else {
      addcolor(color.name, color.colorCode, color.code, libraries);
      setColor({
        name: "",
        colorCode: "",
        code: "",
        colorCategories: [],
      });
      navigate("/CompanyColor");
    }
  };
  const handleChange = (e) => {
    setColor({ ...color, [e.target.name]: e.target.value });
  };

  const getColorCategories = useCallback(async () => {
    const lib = await getallcolorCategoriesData(true);
    const categories = lib?.colorCategories?.map((ui) => ({
      label: ui.name,
      value: ui.id,
    }));
    setColorCategories(categories);
  }, []);

  const MemoizedMultiSelect = React.memo(MultiSelect);

  useEffect(() => {
    getColorCategories();
  }, [getColorCategories]);
  const { classes } = useStyles();
  return (
    <div className={classes.background}>
      <Container size="sm" py="xl">
        <div className={classes.uploadfile}>
          <Text size={25} mb={20} color="#051C48">
            Add Color
          </Text>

          <Container mt={5}>
            <form>
              <div
                style={{
                  // width: "250px",
                  height: "140px",
                  backgroundColor: `${color.colorCode}`,
                  color: `${color.colorCode}`,
                  marginBottom: "20px",
                  borderRadius: "6px",
                  border: "2px solid #7C93BC ",
                  // alignItems:"center"
                }}
              ></div>
              <TextInput
                placeholder="#496738"
                label="Hex Color code"
                id="colorCode"
                color="#051C48"
                name="colorCode"
                required
                value={color.colorCode}
                sx={{ flex: 1 }}
                onChange={handleChange}
                mb={10}
              />
              <TextInput
                mt="lg"
                onChange={handleChange}
                color="#051C48"
                label="Name"
                id="name"
                value={color.name}
                name="name"
                required
                placeholder="Artichoke"
                sx={{ flex: 1 }}
                mb={10}
              />

              <TextInput
                placeholder="16-1325 TCX"
                label="Code"
                id="code"
                color="#051C48"
                name="code"
                required
                value={color.code}
                sx={{ flex: 1 }}
                onChange={handleChange}
                mb={10}
              />
              <MemoizedMultiSelect
                data={colorCategories}
                value={libraries}
                label="Color Category"
                placeholder="Color Category"
                onChange={setLibraries}
              />

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <Button
                  mt="md"
                  variant="outline"
                  style={{
                    width: "100%",
                    maxWidth: "280px", // Ensures button size is responsive
                    padding: "12px", // Adjust padding
                    borderColor: "#606060", // Adjust outline color
                    color: "#606060", // Text color
                  }}
                  onClick={() => {
                    navigate("/CompanyColor");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  onClick={handleClick}
                  mt="md"
                  style={{
                    width: "100%",
                    maxWidth: "280px", // Ensures button size is responsive
                    backgroundColor: "#E27612", // Custom background color
                    padding: "12px", // Adjust padding
                    color: "#fff", // Text color
                  }}
                  rightIcon={<IconCheck />}
                >
                  Save
                </Button>
              </div>
            </form>
          </Container>
        </div>
      </Container>
    </div>
  );
}

export default Addcolor;
