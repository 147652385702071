import React from "react";
import Searchbar from "./smallcomponents/Searchbar";
import { Image, Text } from "@mantine/core";
import { useLocation, useNavigate, Link, useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import UserContext from "../../../ContextFolder/UserContext/UserContext";
import { validPaths, visibleAction } from "../../../utils/utils";
import wiltonLogo from "../../../Images/wilton-logo.png";
import SearchIcon from "../../common/iconComponents/SearchIcon";
import Admindropdownmenu from "./smallcomponents/Admindropdownmenu";
import Nav2menuitems from "../Navbar2/Nav2menuitems";
import FolderIcon from "../../common/iconComponents/FolderIcon";
import BackArrowIcon from "../../common/iconComponents/BackArrowIcon";

const NavBar = ({ setSearchstring, searchstring }) => {
  const navigate = useNavigate();
  const isSuperAdmin = localStorage.getItem("role") === "SuperAdmin";
  const { pathname } = useLocation();
  const [first, setfirst] = useState("");
  const [userData, setUserData] = useState(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const context = useContext(UserContext);
  const isArticleCardPage = pathname === validPaths.ARTICLE_CARD;

  useEffect(() => {
    const data = async () => {
      const userdata = await context.getmyprofile();
      setUserData(userdata);
      if (userdata.avatarId) {
        const response = await fetch(
          `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/database-files/${userdata.avatarId}`,
          {
            method: "GET",
            headers: {
              Authorization: ` Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const all = await response;

        setfirst(all.url);
        return all;
      }
    };
    data();
  }, []);

  useEffect(() => {
    setIsSearchOpen(false);
  }, [pathname]);

  const { id } = useParams();
  const isLibraryPage = pathname === `/editpage/library/${id}`;
  const isEditPage = pathname === `/editpage/${id}`;

  const buttonText =
    isLibraryPage || isEditPage || pathname === "/view" ? "Back" : "Collection";
  const [lastVisitedPath, setLastVisitedPath] = useState("");

  const handleBackClick = () => {
    if (pathname === "/view" && lastVisitedPath) {
      navigate(lastVisitedPath);
    } else if (isLibraryPage) {
      navigate("/MyLibrary");
    } else if (isEditPage) {
      navigate("/ArticleCard");
    }
  };

  useEffect(() => {
    if (pathname !== "/view") {
      setLastVisitedPath(pathname);
    }
  }, [pathname]);

  return (
    <>
      {/* Logo */}
      <div className="flex flex-row justify-center items-center py-5 bg-white">
        {userData && !userData.avatarId && (
          <Image
            onClick={() => {
              navigate("/ArticleCard");
            }}
            className="w-36 cursor-pointer"
            src={wiltonLogo}
            alt="Wilton Logo"
          />
        )}
        {userData && userData.avatarId && first && (
          <img
            onClick={() => {
              navigate("/ArticleCard");
            }}
            src={first}
            className="cursor-pointer"
            style={{
              width: "150px",
              height: "50px",
            }}
            alt="user logo"
          />
        )}
      </div>

      {/* NavBar */}
      <div className="flex flex-row bg-comet sticky top-0 z-40 items-center justify-between py-2 px-6">
        <div className="flex flex-row items-center gap-x-7">
          <Text
            onClick={
              isLibraryPage || isEditPage || pathname === "/view"
                ? handleBackClick
                : () => navigate(validPaths.ARTICLE_CARD)
            }
            className={`${
              isArticleCardPage ? "text-whiskeySour" : "text-white"
            } text-lg font-bold cursor-pointer flex items-center`}
          >
            {(isLibraryPage || isEditPage || pathname === "/view") && (
              <BackArrowIcon size={20} className="mr-2" />
            )}
            {buttonText}
          </Text>
          {visibleAction.includes(pathname) && isSuperAdmin && (
            <Nav2menuitems />
          )}
        </div>
        <div className="flex flex-row items-center gap-4">
          {isSearchOpen && isArticleCardPage && (
            <div className="absolute top-13 md:top-1.5 right-10 md:right-40 z-10">
              <Searchbar
                setSearchstring={setSearchstring}
                searchstring={searchstring}
              />
            </div>
          )}
          {isArticleCardPage && (
            <SearchIcon
              stroke="white"
              className={"cursor-pointer"}
              onClick={() => {
                setIsSearchOpen(!isSearchOpen);
              }}
            />
          )}
          {visibleAction.includes(pathname) && !isSuperAdmin && (
            <Link to="/MyLibrary">
              <FolderIcon
                stroke={
                  pathname === validPaths.MY_LIBRARY ? "#D79963" : "white"
                }
              />
            </Link>
          )}
          <Admindropdownmenu />
        </div>
      </div>
    </>
  );
};

export default NavBar;

{
  /* <AdminLogomobile /> */
}
{
  /* {visibleAction.includes(currentPath.pathname) ? <Nav2 /> : null} */
}
{
  /* {currentPath.pathname === "/ArticleCard" ? (
                <Searchbar
                  setSearchstring={setSearchstring}
                  searchstring={searchstring}
                />
              ) : null} */
}
{
  /* <Sideitem /> */
}
