import { Text } from "@mantine/core";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import useStyles from "../../../../Components/Style/UseStyle";
import * as svg from "../svg";
import CompanycolorContext from "../../../../ContextFolder/CompanyColorContext/CompanycolorContext";
function UploadedDesignColors({ fileSource }) {
  const [svgString, setSvgString] = useState("");
  const colors = svg.getColors(svgString);
  const contextcolor = useContext(CompanycolorContext);
  const [colordata, setColordata] = useState([]);

  useEffect(() => {
    if (fileSource) {
      const fetchSVGString = async () => {
        const mySvgRaw = await fetch(fileSource);
        const mySvgText = await mySvgRaw.text();
        setSvgString(mySvgText);
      };
      fetchSVGString();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const getcolors = async () => {
      const companycolor = await contextcolor?.getallcolorsdataForsomePages({
        isActive: true,
      });
      setColordata(companycolor.companyColors);
    };
    getcolors();
  }, []);

  const { classes } = useStyles();
  return (
    <div>
      <Text mt={10} ml={10} size={17} weight={600} style={{ color: "#051C48" }}>
        Colors
      </Text>

      {colors.map((color) => {
        const targetColorCode = color;
        const editData = colordata.find(
          (rawcolor) =>
            rawcolor.colorCode.toLowerCase() === targetColorCode.toLowerCase()
        );
        const finalData = editData ? editData.name : "";
        return (
          <>
            <div style={{ display: "inline-flex" }}>
              <div
                key={color}
                style={{
                  textAlign: "center",
                }}
              >
                <div>
                  <div
                    style={{
                      width: "60px",
                      height: "60px",
                      backgroundColor: color,
                    }}
                    className={classes.colors}
                  ></div>
                </div>

                <div
                  style={{
                    marginTop: "2px",
                    wordBreak: "break-word",
                    width: "70px",
                  }}
                >
                  <Text size={"sm"}>{finalData}</Text>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
}

export default UploadedDesignColors;
