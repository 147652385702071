import { Text, Flex, Box, Image } from "@mantine/core";
import { IconCircleCheck } from "@tabler/icons";

import React from "react";
import backGroundCoverImage from "../../../Images/Frame.svg";
import wiltonLogo from "../../../Images/wilton-logo.png";
import formCoverImage from "../../../Images/formCoverImage.png";
import { Link } from "react-router-dom";
import NewCheckIcon from "../../common/iconComponents/NewCheckIcon";

export default function ForgetPassSub() {
  return (
    <>
      <Box
        className="bg-cover bg-center flex flex-row justify-center items-center h-screen w-full"
        style={{ backgroundImage: `url(${backGroundCoverImage})` }}
      >
        <div className="flex flex-row w-11/12 md:w-1/2 lg:w-203 xl:w-242.5 justify-center bg-white p-2 gap-3 rounded-3xl">
          <div className="lg:w-1/2 flex flex-col space-y-6 justify-center items-center">
            <div className="w-28 md:w-36 lg:w-40 mt-5">
              <Image src={wiltonLogo} width={"100%"} />
            </div>
            <Flex justify={"center"} align={"center"}>
              <NewCheckIcon />
            </Flex>
            <Text
              color="#051C48"
              style={{ color: "#D79963" }}
              className="text-center text-3xl mt-4 font-normal"
            >
              Check your mail
            </Text>
            <Text align="center" mt="md" color="#051C48">
              We have sent a password recover <br />
              instructions to your email.
            </Text>
            <Text align="center" mt="md" color="#051C48">
              Did not receive the email? Check your spam <br />
              filter, or try{" "}
              <Link
                className="text-sm text-center xl:text-sm hover:text-eerieBlack md:text-xs lg:text-xs"
                to="/ForgetPass"
              >
                <span style={{ color: "#D79963" }}> another email address</span>
              </Link>
            </Text>
          </div>
          <div className="w-full lg:w-1/2 hidden lg:block">
            <Image src={formCoverImage} width={"100%"} />
          </div>
        </div>
      </Box>
    </>
  );
}
