import React from "react";
import { Button, Text, TextInput, Box, Image } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { Link, useNavigate } from "react-router-dom";
import ForgotPassSchema from "../ValidationLogic/ForgotPassSchema";
import { useContext } from "react";
import UserContext from "../../../ContextFolder/UserContext/UserContext";
import backGroundCoverImage from "../../../Images/Frame.svg";
import wiltonLogo from "../../../Images/wilton-logo.png";
import formCoverImage from "../../../Images/formCoverImage.png";

export default function ForgetPass() {
  const navigate = useNavigate();
  const context = useContext(UserContext);
  const forgotpassword = useForm({
    validateInputOnChange: true,
    validate: zodResolver(ForgotPassSchema),

    initialValues: {
      email: "",
    },
  });

  return (
    <Box
      className="bg-cover bg-center  flex flex-row justify-center items-center h-screen w-full"
      style={{ backgroundImage: `url(${backGroundCoverImage})` }}
    >
      <div className="flex flex-row w-11/12 md:w-1/2 lg:w-203 xl:w-242.5 justify-between bg-white p-2 gap-3 rounded-3xl">
        <div className="lg:w-[45%] flex flex-col space-y-5 justify-evenly  items-center ">
          <div className="w-40 md:w-39 lg:w-32  md:mt-10 xl:mt-15 xl:w-40  mt-5">
            <Image src={wiltonLogo} width={"100%"} />
          </div>
          <form
            className="text-center w-11/12 md:w-10/12 lg:py-0 py-3 space-y-6"
            onSubmit={forgotpassword.onSubmit(async (values) => {
              const result = await context.ForgetPassword(values.email);

              if (!result.status) {
                console.log("Something went Wrong !! please try again letter.");
                alert("Something went Wrong !! please try again letter.");
                // navigate("/login");
              } else {
                // console.log("click", values)
                navigate("/ForgetPassSub");
              }
            })}
          >
            <Text className="text-center text-comet text-3xl mt-4 font-normal">
              Forget Password
            </Text>
            <Text className="text-center text-xs xl:text-base ">
              Enter the email associated with your account and an instruction
              will be sent to your email for resetting of the password
            </Text>
            <div className="space-y-5">
              <TextInput
                placeholder="Enter your email"
                size="lg"
                required
                styles={{
                  input: {
                    fontSize: "15.5px",
                    fontWeight: 400,
                    lineHeight: "19.2px",
                    backgroundColor: "#F5F5F5",
                    border: "1px solid #636364",
                    "::placeholder": { color: "#636364" },
                  },
                }}
                radius={7}
                {...forgotpassword.getInputProps("email")}
              />

              <Button
                fullWidth
                size="lg"
                style={{ backgroundColor: "#636364", fontSize: "15px" }}
                type="submit"
              >
                Submit
              </Button>
            </div>
          </form>
          <div className="w-full flex flex-col pb-4 items-center space-y-4">
            <Link
              className="text-sm text-center xl:text-sm hover:text-eerieBlack md:text-xs lg:text-xs"
              to="/login"
            >
              Back to login
            </Link>
            <Text className="text-center  text-sm md:text-xs w-[75%] xl:text-sm 2xsm:w-[85%] md:w-[90%] lg:w-[98%]  ">
              Don’t have an account write an email to support@wilton.in to
              create a new account for you
            </Text>
          </div>
        </div>
        <div className="w-[500px] xl:w-132.5 hidden lg:block">
          <Image src={formCoverImage} width={"100%"} />
        </div>
      </div>
    </Box>
  );
}
