export const validPaths = {
  MY_LIBRARY: "/MyLibrary",
  ACCOUNT: "/Account",
  LIBRARIES: "/Libraries",
  COMPANY_COLOR: "/CompanyColor",
  ARTICLE_CARD: "/ArticleCard",
  USER_ACCOUNT: "/Useraccount",
  COLOR_CATEGORY: "/ColorCategory",
  LOGIN: "/login",
  ADD_COLOR: "/Addcolor",
  UPDATE_COLOR_ID: "/Updatecolor/",
  VIEW: "/view",
  EDIT_PAGE: "/editpage",
};

export const visibleAction = [
  validPaths.MY_LIBRARY,
  validPaths.ACCOUNT,
  validPaths.LIBRARIES,
  validPaths.COMPANY_COLOR,
  validPaths.ARTICLE_CARD,
  validPaths.USER_ACCOUNT,
  validPaths.COLOR_CATEGORY,
  validPaths.ADD_COLOR,
];

export const navigationActions = [
  {
    actionTitle: "Libraries",
    navigateTo: validPaths.LIBRARIES,
  },
  {
    actionTitle: "Company Colors",
    navigateTo: validPaths.COMPANY_COLOR,
  },
  {
    actionTitle: "Color Category",
    navigateTo: validPaths.COLOR_CATEGORY,
  },
  {
    actionTitle: "Account",
    navigateTo: validPaths.ACCOUNT,
  },
];

export const isSuperAdmin = localStorage?.getItem("role") === "SuperAdmin";

export const validSampleSizes = {
  FEET12: "FEET_1x2",
  FEET23: "FEET_2x3",
  FEET34: "FEET_3x4",
};
