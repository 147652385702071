import React from "react";
import cover from "../../../Images/sofaimage.png"

function Bg() {
 
  return (
    <>
      <img src={cover} style={{height:"100vh", width:"100%"}} alt="not found" />
    </>
  );
}

export default Bg;
