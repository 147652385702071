import React, { useContext, memo, useState } from "react";
import { Card, Text, Image, Skeleton } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import texture from "../../Images/Texture.svg";
import CatelogueContext from "../../ContextFolder/CatelogueContext/CatelogueContext";
import { EyeIcon } from "../common/iconComponents/Icons";

const DesignCard = ({
  design,
  isMyLibraryPage,
  currentPage,
  setIsNavigatingBack,
}) => {
  const navigate = useNavigate();
  const { id, url, designNumber } = design;
  const { getdesignId } = useContext(CatelogueContext);
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleClick = async () => {
    await getdesignId(id);
    const scrollPosition = window.scrollY;
    localStorage.setItem("scrollPosition", scrollPosition);
    localStorage.setItem("currentPage", currentPage);

    if (isMyLibraryPage) {
      navigate(`/editpage/library/${id}`);
    } else {
      navigate(`/editpage/${id}`);
    }
    setIsNavigatingBack(false);
  };
  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <Card
      style={{ borderRadius: "0px" }}
      className="group cursor-pointer"
      onClick={handleClick}
    >
      <Card.Section className="relative">
        {!imageLoaded && (
          <Skeleton
            height="100%"
            width="100%"
            className="absolute inset-0"
            radius={0}
          />
        )}
        <div className="flex transition-opacity duration-500 group-hover:opacity-100 opacity-0 absolute bottom-0 flex-row py-1 w-full px-2 bg-eerieBlack bg-opacity-80 justify-between items-center">
          <Text className="text-white1">View Design</Text>
          <EyeIcon />
        </div>
        <div
          style={{ backgroundImage: `url(${url})` }}
          className={`transition-opacity duration-500 ${
            imageLoaded ? "opacity-100" : "opacity-0"
          }`}
        >
          <Image src={texture} onLoad={handleImageLoad} />
        </div>
      </Card.Section>
      <Text
        className={`text-center my-2 md:my-1 text-comet text-base font-semibold ${
          imageLoaded ? "opacity-100" : "opacity-0"
        } transition-opacity duration-500`}
      >
        {designNumber}
      </Text>
    </Card>
  );
};

export default memo(DesignCard);
