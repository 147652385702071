import React from "react";

const FolderIcon = ({ stroke = "black", width = 32, height = 32 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.12 9.79993H20.0133C19.0533 9.79993 18.16 9.29326 17.6667 8.46659L16.8 7.01327C16.3067 6.1866 15.4133 5.67993 14.4533 5.67993H6.41335C4.89335 5.67993 3.66669 6.9066 3.66669 8.4266V12.5332L3.66669 12.973C3.66669 13.1254 3.66669 12.8399 3.66669 12.9999V23.0666C3.66669 24.8399 5.10669 26.2799 6.88003 26.2799H25.12C26.8933 26.2799 28.3334 24.8399 28.3334 23.0666V12.9999C28.3334 11.2399 26.8933 9.79993 25.12 9.79993Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.12 9.7998H3.66669V23.0931C3.66669 24.8665 5.10669 26.3065 6.88003 26.3065H25.12C26.8933 26.3065 28.3334 24.8665 28.3334 23.0931V13.0264C28.3334 11.2398 26.8933 9.7998 25.12 9.7998Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FolderIcon;
