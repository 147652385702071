import { useEffect, useState } from "react";
import {
  getallcolorCategoriesData,
  DeleteColorCategory,
  updateOrder,
} from "../../../services/ColorCategory/colorCategory";
import {
  Button,
  Group,
  Loader,
  ScrollArea,
  Switch,
  Table,
  Text,
  Modal,
  TextInput,
} from "@mantine/core";
import { IconPencil, IconTrash } from "@tabler/icons";
import { Link, useNavigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications"; // Optional: to show success/error notifications

const ColorCategoryPage = () => {
  const [colorCategories, setColorCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [orderUpdates, setOrderUpdates] = useState({});
  const navigate = useNavigate();

  // Fetch color categories on component load
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const data = await getallcolorCategoriesData();
      if (data) {
        setColorCategories(data);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  // Delete category function
  const handleDelete = async () => {
    try {
      await DeleteColorCategory(selectedCategoryId);
      showNotification({
        title: "Success",
        message: "Color category deleted successfully",
        color: "green",
      });

      // Remove the deleted category from the list
      setColorCategories((prevCategories) => ({
        ...prevCategories,
        colorCategories: prevCategories.colorCategories.filter(
          (category) => category.id !== selectedCategoryId
        ),
      }));

      // Close modal
      setDeleteModalOpen(false);
    } catch (error) {
      showNotification({
        title: "Error",
        message: "Failed to delete color category",
        color: "red",
      });
    }
  };

  const handleOrderChange = (id, newOrder) => {
    setOrderUpdates((prevUpdates) => ({
      ...prevUpdates,
      [id]: newOrder,
    }));
  };

  const handleSaveOrder = async () => {
    const updates = Object.entries(orderUpdates).map(([id, order]) => ({
      id,
      order: parseInt(order, 10),
    }));

    try {
      await updateOrder(updates);
      showNotification({
        title: "Success",
        message: "Order updated successfully",
        color: "green",
      });

      const data = await getallcolorCategoriesData();
      if (data) {
        setColorCategories(data);
      }

      // Clear the order updates
      setOrderUpdates({});
    } catch (error) {
      showNotification({
        title: "Error",
        message: "Failed to update order",
        color: "red",
      });
    }
  };
  const rows = colorCategories?.colorCategories?.map((category) => (
    <tr key={category.id}>
      <td>{category.name}</td>
      <td>
        <Group>
          <div
            style={{
              width: 30,
              height: 30,
              backgroundColor: category.colorCode,
              borderRadius: "0%",
            }}
          />
          <Text>{category.colorCode}</Text>
        </Group>
      </td>
      <td>
        <TextInput
          type="number"
          value={orderUpdates[category.id] ?? category.order}
          onChange={(e) => handleOrderChange(category.id, e.target.value)}
          style={{ width: "50px" }} // Set the desired width
        />
      </td>
      <td>
        <Switch
          checked={category.status}
          onLabel="Active"
          offLabel="Inactive"
        />
      </td>
      <td className="actions-cell">
        <Group spacing="xs">
          <Button
            variant="subtle"
            size="xs"
            onClick={() => navigate(`/editColorCategory/${category.id}`)}
          >
            <IconPencil size={16} />
          </Button>
          <Button
            variant="subtle"
            size="xs"
            color="red"
            onClick={() => {
              setSelectedCategoryId(category.id); // Set the selected category id
              setDeleteModalOpen(true); // Open delete confirmation modal
            }}
          >
            <IconTrash size={16} />
          </Button>
        </Group>
      </td>
    </tr>
  ));

  return (
    <div style={{ padding: "20px" }}>
      <Group position="apart" mb="md">
        <Text weight={700} size="xl">
          Color Category
        </Text>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            style={{
              marginLeft: "10px",
              backgroundColor: "#E27612",
              borderRadius: "32px",
            }}
            onClick={() => {
              handleSaveOrder();
            }}
          >
            Save Order
          </Button>
          <Link
            to="/AddColorCategory"
            style={{
              color: "black",
              textDecoration: "none",
              display: "flex",
              marginLeft: "31px",
            }}
          >
            <Button
              style={{ backgroundColor: "#E27612", borderRadius: "32px" }}
            >
              Add New Color Category
            </Button>
          </Link>
        </div>
      </Group>

      <ScrollArea style={{ minHeight: 400 }}>
        <Table highlightOnHover>
          <thead>
            <tr>
              <th>Color Category</th>
              <th>Color Icon / Hex Color code</th>
              <th>Order</th>
              <th>Status</th>
              <th className="actions-cell">Actions</th>
            </tr>
          </thead>
          <tbody>{loading ? <Loader /> : rows}</tbody>
        </Table>
      </ScrollArea>

      {/* Delete confirmation modal */}
      <Modal
        opened={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        title="Confirm Delete"
        centered
      >
        <Text>Are you sure you want to delete this color category?</Text>
        <Group position="right" mt="md">
          <Button variant="outline" onClick={() => setDeleteModalOpen(false)}>
            Cancel
          </Button>
          <Button variant="outline" color="red" onClick={handleDelete}>
            Delete
          </Button>
        </Group>
      </Modal>
    </div>
  );
};

export default ColorCategoryPage;
