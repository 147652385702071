import React from "react";
import { Text, Menu, Flex, Button } from "@mantine/core";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import avatar from "../../../../Images/avatar.svg";
import VectordownArreow from "../../../../Images/VectordownArreow.svg";
import UserIcon from "../../../common/iconComponents/UserIcon";
import { validPaths } from "../../../../utils/utils";

function Admindropdownmenu() {
  const { pathname } = useLocation();

  const isSuperAdmin = localStorage?.getItem("role") === "SuperAdmin";
  return (
    <div>
      <Menu
        position="bottom-end"
        transition="pop-top-right"
        classNames={{
          item: "hover:bg-transparent",
        }}
      >
        <Menu.Target>
          <span>
            <UserIcon
              className="cursor-pointer"
              stroke={
                pathname === validPaths.USER_ACCOUNT ? "#D79963" : "white"
              }
            />
          </span>
        </Menu.Target>
        <Menu.Dropdown className="w-55">
          {isSuperAdmin ? null : (
            <Menu.Item>
              <Flex justify="center" align="center">
                <Link
                  to="/Useraccount"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  My Account
                </Link>
              </Flex>
            </Menu.Item>
          )}

          <Menu.Item>
            <Flex justify="center" align="center">
              <Link
                to={validPaths.LOGIN}
                className="w-full"
                onClick={() => localStorage.clear()}
              >
                <Button fullWidth={true} className="bg-whiskeySour">
                  <Text color="white" ml={5}>
                    Sign out
                  </Text>
                </Button>
              </Link>
            </Flex>
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
}
export default Admindropdownmenu;
