import React from "react";
// import useStyles from "../../../Style/UseStyle";
function UploadedDesignRepeat({ fileSource }) {
  // const { classes } = useStyles();
  return (
    <div
      // className={classes.bgdesign}
      style={{
        backgroundImage: `url(${fileSource})`,
        backgroundSize: "auto",
        backgroundRepeat: "repeat",
        height: "100%",
        minWidth: "750px",
      }}
    ></div>
  );
}

export default UploadedDesignRepeat;
