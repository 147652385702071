import React from "react";

const FilterIcon = ({ stroke = "black", width = 32, height = 32 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="#F5F5F5"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.5867 6.46655H28.3333"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.66669 6.46655H20.1067"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.8534 9.18658C24.3703 9.18658 25.6001 7.9688 25.6001 6.46658C25.6001 4.96437 24.3703 3.74658 22.8534 3.74658C21.3365 3.74658 20.1067 4.96437 20.1067 6.46658C20.1067 7.9688 21.3365 9.18658 22.8534 9.18658Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.5867 25.5332H28.3333"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.66669 25.5332H20.1067"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.8534 28.2532C24.3703 28.2532 25.6001 27.0354 25.6001 25.5332C25.6001 24.031 24.3703 22.8132 22.8534 22.8132C21.3365 22.8132 20.1067 24.031 20.1067 25.5332C20.1067 27.0354 21.3365 28.2532 22.8534 28.2532Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8934 16H28.3334"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.66669 16H6.41335"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.14669 18.72C10.6636 18.72 11.8934 17.5022 11.8934 16C11.8934 14.4978 10.6636 13.28 9.14669 13.28C7.62975 13.28 6.40002 14.4978 6.40002 16C6.40002 17.5022 7.62975 18.72 9.14669 18.72Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FilterIcon;
