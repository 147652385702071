import React, { useContext } from "react";
import { useForm } from "@mantine/form";
import { Container, Text, TextInput, Switch, Button } from "@mantine/core";
import useStyles from "../../../Components/Style/UseStyle";
import LibraryContext from "../../../ContextFolder/LibraryContext/LibraryContext";
import { useNavigate } from "react-router-dom";

function AddLib() {
  const context = useContext(LibraryContext);
  const { classes } = useStyles();
  const form = useForm({
    initialValues: {
      name: "",
      status: true,
    },
  });
  const navigate = useNavigate();
  return (
    <div className={classes.Addlibbackground}>
      <Container size="sm" py="xl">
        <div className={classes.Addlibuploadfile}>
          <Text size={25} mb={20} color="#051C48">
            Add Library
          </Text>

     
            <form
              onSubmit={form.onSubmit(async (values) => {
                if (!values.name) {
                  alert("please enter library");
                } else {
                  await context.addlibrary(values.name, values.status);
                  navigate("/Libraries");
                }
              })}
            >
              <Text weight={500} size={15} color="#051C48" mb={10}>
                Name
              </Text>
              <TextInput
                placeholder="Enter Name"
                required
                // color="orange"
                withAsterisk
                sx={{ flex: 1 }}
                {...form.getInputProps("name")}
                mb={20}
              />
              <Text weight={500} size={14} color="#051C48">
                Status
              </Text>
              <Switch
                label="Active"
                color="orange"
                mb={30}
                {...form.getInputProps("status", {
                  type: "checkbox",
                })}
              />

              <hr style={{color:"#A5BBE2"}}/>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  mt="md"
                  variant="outline"
                //  style={{color:"#A5BBE2", backgroundColor:"#A5BBE2"}}

                  onClick={() => {
                    navigate("/Libraries");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  mt="md"
                  style={{ backgroundColor: "#E27612", padding: "10px" }}
                >
                  Save
                </Button>
              </div>
            </form>
        
        </div>
      </Container>
    </div>
  );
}

export default AddLib;
