import { Button, Container, Text, TextInput } from "@mantine/core";
import React, { useState, useContext } from "react";
import { IconCheck } from "@tabler/icons";
import { useNavigate } from "react-router-dom";
import { AddColorCategory } from "../../../services/ColorCategory/colorCategory";

function AddcolorCategory() {
  const navigate = useNavigate();

  const [color, setColor] = useState({
    name: "",
    colorCode: "",
    order: 0,
  });

  const isValidHexColor = (colorCode) => {
    const hexColorPattern = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/;
    return hexColorPattern.test(colorCode);
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (!color.name || !color.colorCode) {
      alert("Please enter all details");
    } else if (!isValidHexColor(color.colorCode)) {
      alert("Please enter a valid hexadecimal color code (e.g., #FF0000 or #F00)");
    } else {
      AddColorCategory(color.name, color.colorCode, color.order || 0);
      setColor({
        name: "",
        colorCode: "",
        order: 0,
      });
      navigate("/ColorCategory");
    }
  };

  const handleChange = (e) => {
    setColor({ ...color, [e.target.name]: e.target.value });
  };

  return (
    <div style={{ background: "#f7f9fc", padding: "20px" }}>
      <Container size="sm" py="xl">
        <div>
          <Text size={25} mb={20} color="#051C48">
            Add Color Category
          </Text>

          <Container mt={5}>
            <form>
              {/* Color Preview */}
              <div
                style={{
                  height: "160px",
                  backgroundColor: color.colorCode,
                  color: color.colorCode,
                  marginBottom: "20px",
                  borderRadius: "6px",
                  border: "2px solid #7C93BC ",
                }}
              ></div>

              {/* Hex Color Input */}
              <TextInput
                placeholder="#FF0000"
                label="Color Icon / Hex Color code"
                id="colorCode"
                color="#051C48"
                name="colorCode"
                required
                value={color.colorCode}
                onChange={handleChange}
                mb={10}
              />

              {/* Name Input */}
              <TextInput
                mt="lg"
                onChange={handleChange}
                color="#051C48"
                label="Name"
                id="name"
                value={color.name}
                name="name"
                required
                placeholder="Artichoke"
                mb={10}
              />

              {/* Order Input */}
              <TextInput
                mt="lg"
                onChange={handleChange}
                color="#051C48"
                label="Order"
                id="order"
                value={color.order}
                name="order"
                placeholder="0"
                mb={10}
              />

              {/* Action Buttons */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                  marginTop: "20px",
                }}
              >
                {/* Cancel Button */}
                <Button
                  variant="outline"
                  style={{
                    width: "100%",
                    maxWidth: "280px",
                    padding: "12px",
                    borderColor: "#606060",
                    color: "#606060",
                  }}
                  onClick={() => {
                    navigate("/ColorCategory");
                  }}
                >
                  Cancel
                </Button>

                {/* Save Button */}
                <Button
                  type="submit"
                  onClick={handleClick}
                  style={{
                    width: "100%",
                    maxWidth: "280px",
                    backgroundColor: "#E27612",
                    padding: "12px",
                    color: "#fff",
                  }}
                  rightIcon={<IconCheck />}
                >
                  Save
                </Button>
              </div>
            </form>
          </Container>
        </div>
      </Container>
    </div>
  );
}

export default AddcolorCategory;
