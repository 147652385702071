import React from "react";

const ZoomPlusIcon = ({
  stroke = "#636364",
  width = 27,
  height = 28,
  className = "",
  onClick = () => {},
}) => {
  return (
    <svg
      width={width}
      onClick={onClick}
      height={height}
      viewBox="0 0 27 28"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2363 23.5868C6.18297 23.5868 1.27631 18.6801 1.27631 12.6268C1.27631 6.57342 6.18297 1.66675 12.2363 1.66675C18.2896 1.66675 23.1963 6.57342 23.1963 12.6268C23.1963 18.6801 18.2896 23.5868 12.2363 23.5868Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.943 26.3335L20.463 20.8535"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.50299 12.6267H14.983"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2363 9.89331V15.3733"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ZoomPlusIcon;
