import React, { useContext, useEffect, useRef, useState } from "react";
import useStyles from "../../../Components/Style/UseStyle";
import {
  Text,
  TextInput,
  Button,
  ScrollArea,
  SimpleGrid,
  MultiSelect,
  Modal,
  Container,
  Flex,
  Input,
  Divider,
  Grid,
  Select,
  Group,
} from "@mantine/core";
import * as svg from "../Designs/svg";
import { Stage, Layer, Rect } from "react-konva";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useImage from "use-image";
import CatelogueContext from "../../../ContextFolder/CatelogueContext/CatelogueContext";
import CompanycolorContext from "../../../ContextFolder/CompanyColorContext/CompanycolorContext";
import LibraryContext from "../../../ContextFolder/LibraryContext/LibraryContext";
import {
  IconCirclePlus,
  IconCloudUpload,
  IconDownload,
  IconX,
} from "@tabler/icons";
import { useDisclosure } from "@mantine/hooks";
import AccordionComponent from "../../../Components/EditPage/AccordionComponent";
import {
  EyeIcon,
  AddButton,
  LibraryIcon,
  SearchIcon,
  TickMarkIcon,
  EditButton,
} from "../../../Components/common/iconComponents/Icons";
import { validSampleSizes } from "../../../utils/utils";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";

const SavenewChanges = ({ setFileSource }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const divRef = useRef(null);
  const [colorMap, setColorMap] = useState([]);
  const [svgString, setSvgString] = useState("");
  const [valuee, setValue] = useState([]);
  const [sampleSize, setSampleSize] = useState(validSampleSizes.FEET12);
  const [colorPickerSelectedColor, setColorPickerSelectedColor] =
    useState("#496738");
  const modifiedSVG = svg.replaceColors(svgString, colorMap);
  const [image] = useImage(svg.svgToURL(modifiedSVG));
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false);
  const [opened, { close, open }] = useDisclosure(false);
  const [openedMenu, setOpenedMenu] = useState(null);
  const [isClickOnEditIcon, setIsClickOnEditIcon] = useState(false);
  const { theme } = useStyles();

  const colors = svg.getColors(svgString);
  const {
    getdesignbyid,
    getdesignId,
    addDesign,
    editDesign,
    DeleteDesign,
    AddRequest,
  } = useContext(CatelogueContext);
  const contextcolor = useContext(CompanycolorContext);
  const [active, setActive] = useState("");
  const [dataa, setData] = useState([]);
  const { classes, cx } = useStyles();
  const [colorData, setColorData] = useState([]);
  const contextLib = useContext(LibraryContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [designName, setDesignName] = useState("");
  const [isEditModeOn, setIsEditModeOn] = useState({
    colorEditMode: false,
    saveDesignEditMode: false,
  });
  // const [selectedColor, setSelectedColor] = useState(false);
  const [designDetails, setDesignDetails] = useState({
    title: "",
    designNumber: "",
    productConstruction: "",
    picksMtr: "",
    pileType: "",
    repeatSize: "",
    libraries: [],
    id: "",
  });
  const location = useLocation();
  const [modalOpen, setModalOpen] = useState(false);
  const openRef = useRef();
  const accordionRef = useRef(null);

  const showColorPicker = (toggle, oldColor) => {
    setDisplayColorPicker(toggle);
    setColorPickerSelectedColor(oldColor);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (divRef.current?.offsetHeight && divRef.current?.offsetWidth) {
      setDimensions({
        width: divRef.current.offsetWidth,
        height: divRef.current.offsetHeight,
      });
    }
    if (getdesignbyid?.patternImage) {
      const fetchSVGString = async () => {
        setSvgString(getdesignbyid?.patternImage);
      };
      fetchSVGString();
    }
    const getcolors = async () => {
      const companyColor = await contextcolor?.getallcolorsdataForsomePages({
        isActive: true,
      });
      setColorData(companyColor?.companyColors);
    };

    const getaDesignbyid = async () => {
      const data = await getdesignId(id);
      const assignedLibraries = data?.libraries?.map((ui) => `${ui.id}`);
      setDesignDetails({
        ...designDetails,
        number: data?.number,
        picksMtr: data?.picksMtr,
        repeatSize: data?.repeatSize,
        title: data?.title,
        libraries: assignedLibraries,
        id: data?.id,
        designNumber: data?.designNumber,
      });

      setDesignName(data?.title);
      setValue(assignedLibraries);
    };
    const getlibrary = async () => {
      const lib = await contextLib?.getlibraryforSomepage();
      const a = lib?.libraries?.map((ui) => {
        return { label: `${ui.name}`, value: `${ui.id}` };
      });
      setData(a);
    };
    getaDesignbyid();
    getlibrary();
    getcolors();
  }, [getdesignbyid?.patternImage]);
  const handleClickForReqSample = () => {
    setOpenedMenu("sampleSize");
    if (window.innerWidth <= 768) {
      accordionRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const setNewColor = (oldColor, newColor) => {
    setColorMap({
      ...colorMap,
      [oldColor]: newColor,
    });
  };

  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });

  const addlibrary = async () => {
    await contextLib.addlibrary(first.name, first.status);
    setfirst({ name: "" });

    const lib = await contextLib?.getlibraryforSomepage();
    const addedlibrarysetfunction = lib?.libraries?.map((ui) => {
      return { label: `${ui.name}`, value: `${ui.id}` };
    });
    setData(addedlibrarysetfunction);
  };

  const handleClick = async () => {
    const role = localStorage.getItem("role");
    if (!designDetails?.title) {
      alert("Please enter design name!");
    } else {
      const title = role === "SuperAdmin" ? designDetails.title : designName;

      const body = {
        title: title,
        data, // You can assign this to colors
        modifiedSVG, // You can assign this to patternImage
        libraries: designDetails?.libraries,
      };

      // Add SuperAdmin specific fields if applicable
      if (role === "SuperAdmin") {
        body.designNumber = designDetails?.designNumber;
        body.picksMtr = designDetails?.picksMtr;
        body.repeatSize = designDetails?.repeatSize;
      }

      // Pass the entire body to addDesign
      await addDesign(
        body.title,
        role === "SuperAdmin" ? body.designNumber : null, // Only pass designNumber if SuperAdmin
        role === "SuperAdmin" ? body.picksMtr : null, // Only pass picksMtr if SuperAdmin
        role === "SuperAdmin" ? body.repeatSize : null, // Only pass repeatSize if SuperAdmin
        body.data, // colors (required)
        body.modifiedSVG, // patternImage (required)
        body.libraries // libraries
      );
      navigate("/ArticleCard");
    }
  };

  const addToLibrary = async () => {
    const role = localStorage.getItem("role");

    if (!designDetails?.title) {
      alert("Please enter design name!");
    } else {
      const body = {
        title: designName,
        data, // You can assign this to colors
        modifiedSVG, // You can assign this to patternImage
        libraries: designDetails?.libraries,
      };

      // Add SuperAdmin specific fields if applicable
      if (role === "SuperAdmin") {
        body.designNumber = designDetails?.designNumber;
        body.picksMtr = designDetails?.picksMtr;
        body.repeatSize = designDetails?.repeatSize;
      }

      // Pass the entire body to addDesign
      await addDesign(
        body.title,
        (body.designNumber = designDetails?.designNumber),
        (body.picksMtr = designDetails?.picksMtr),
        (body.repeatSize = designDetails?.repeatSize),
        // role === "SuperAdmin" ? body.designNumber : null, // Only pass designNumber if SuperAdmin
        // role === "SuperAdmin" ? body.picksMtr : null, // Only pass picksMtr if SuperAdmin
        // role === "SuperAdmin" ? body.repeatSize : null, // Only pass repeatSize if SuperAdmin
        body.data, // colors (required)
        body.modifiedSVG, // patternImage (required)
        body.libraries // libraries
      );
      navigate("/ArticleCard");
    }
  };

  const handle = async () => {
    const role = localStorage.getItem("role");
    const { designNumber, picksMtr, repeatSize } = designDetails || {};
    if (!designName) {
      alert("Please enter a design name!");
      return;
    }
    const title = role === "SuperAdmin" ? designDetails.title : designName;
    const body = {
      title,
      colors: data,
      patternImage: modifiedSVG,
      libraries: valuee,
      ...(role === "SuperAdmin" && { designNumber, picksMtr, repeatSize }),
    };
    try {
      await editDesign(id, body);
      navigate("/ArticleCard");
    } catch (error) {
      console.error("Failed to edit design:", error);
    }
  };

  const handeldelete = async () => {
    const response = window.confirm(
      "Are you sure to delete this Design pattern?"
    );
    if (response) {
      const deleteDesign = await DeleteDesign(id);
      if (deleteDesign) {
        navigate("/ArticleCard");
      }
    } else {
      navigate(`/editpage/${id}`);
    }
  };

  const [first, setfirst] = useState({
    name: "",
    status: true,
  });

  const handleChange = (e) => {
    setfirst({ ...first, [e.target.name]: e.target.value });
  };

  const onDesignNameChange = (e) => {
    setDesignName(e.target.value);
  };

  const data = colors?.map((color) => colorMap[color] || color);

  const filteredColors = colorData?.filter((rawColor) =>
    rawColor.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const rawColors = filteredColors?.map((rawColor) => (
    <>
      <div>
        <div
          key={rawColor.id}
          style={{
            display: "flex",
            justifyContent: "normal",
            marginBottom: "5px",
          }}
        >
          <div
            style={{
              content: `${rawColor.name}`,
              width: "24px",
              height: "24px",
              borderRadius: "4px",
              cursor: "pointer",
              backgroundColor: `${rawColor.colorCode}`,
            }}
            onClick={() => {
              setNewColor(colorPickerSelectedColor, rawColor.colorCode);
            }}
          ></div>

          <Text ml={5}>{rawColor.name}</Text>
        </div>
      </div>
    </>
  ));

  const onChange = (e) => {
    const { name, value } = e.target;
    setDesignDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };
  const handleSelectChange = (value) => {
    setDesignDetails((prevDetails) => ({
      ...prevDetails,
      picksMtr: value,
    }));
  };
  async function blobUrlToSvg(fileURL) {
    try {
      // Fetch the blob data from the URL
      const response = await fetch(fileURL);
      const blob = await response.blob();
      // Create a FileReader to read the blob as text
      const reader = new FileReader();
      return await new Promise((resolve) => {
        reader.onload = (event) => {
          // Extract SVG content from the result
          const svgContent = event.target.result;
          resolve(svgContent);
        };
        // Read the blob as text
        reader.readAsText(blob);
      });
    } catch (error) {
      console.error("Error converting Blob URL to SVG:", error);
    }
  }

  return (
    <div className="flex flex-col md:flex-row w-full gap-y-7 ">
      <div
        ref={divRef}
        className="w-full h-[320px] md:h-[250px] md:w-[40%] lg:w-[458px] lg:h-[376px] xl:h-screen xl:w-[59%]"
      >
        <Stage
          width={dimensions.width}
          height={dimensions.height}
          style={{ pointerEvents: "none" }} // This allows scroll events to pass through.
        >
          <Layer>
            <Rect
              width={dimensions.width}
              height={dimensions.height}
              fillPatternImage={image}
            />
          </Layer>
        </Stage>
      </div>

      <div className="flex flex-col md:mt-3 xl:mt-4 w-11/12 md:w-[57%] mx-auto lg:w-[52%]  xl:w-[38%] ">
        {localStorage.getItem("role") === "User" && (
          <div className="flex flex-row font-bold md:font-thin gap-x-1.5 text-2xl text-comet">
            {/* <Text>{designDetails?.title}</Text>
            {designDetails?.designNumber ? (
              <Divider
                orientation="vertical"
                className="bg-comet mt-2 h-7 md:h-6 lg:mt-2 lg:h-8"
                size="md"
              />
            ) : null} */}
            <Text>{designDetails?.designNumber}</Text>
          </div>
        )}
        {localStorage.getItem("role") === "User" && (
          <div
            className={`flex flex-col md:flex-row mt-4 gap-y-2 gap-x-1.5 xl:gap-x-3 ${
              isEditModeOn ? "justify-start" : "justify-center"
            }`}
          >
            {isClickOnEditIcon && isEditModeOn.saveDesignEditMode && (
              <div className="flex w-full flex-col lg:flex-row lg:items-center lg:gap-x-2">
                <Input
                  placeholder="Name your design . . ."
                  mt={8}
                  mb={10}
                  value={designName}
                  name="name"
                  onChange={onDesignNameChange}
                  style={{ flex: "1" }}
                  styles={{
                    rightSection: { marginRight: "10px" },
                    input: {
                      height: "48px",
                      backgroundColor: "#F5F5F5",
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "19.2px",
                      border: "1px solid #636364",
                      "::placeholder": { color: "#636364" },
                    },
                  }}
                  radius={7}
                />
                <div className="flex flex-row w-full lg:w-[45%] gap-x-2 text-base">
                  <Button
                    onClick={() => {
                      if (location.pathname === `/editpage/library/${id}`) {
                        handle();
                      } else if (location.pathname === `/editpage/${id}`) {
                        addToLibrary();
                      }
                    }}
                    radius={8}
                    rightIcon={
                      <TickMarkIcon stroke="white" className="md:w-6 xl:w-7" />
                    }
                    className="bg-whiskeySour h-12 text-base w-full"
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => {
                      setIsEditModeOn({
                        ...isEditModeOn,
                        saveDesignEditMode: false,
                        colorEditMode: false,
                      });
                      setIsClickOnEditIcon(false);
                    }}
                    radius={8}
                    className="bg-comet h-12 text-base w-full"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            )}
            {!isEditModeOn.colorEditMode &&
              !isEditModeOn.saveDesignEditMode && (
                <>
                  <Button
                    onClick={handleClickForReqSample}
                    radius={8}
                    rightIcon={
                      <AddButton stroke="white" className="md:w-6 xl:w-7" />
                    }
                    className="bg-whiskeySour h-12 md:text-xs md:h-10 xl:text-base xl:h-12 w-full"
                  >
                    Order Sample
                  </Button>
                  {location.pathname === `/editpage/${id}` && (
                    <Button
                      radius={8}
                      onClick={addToLibrary}
                      rightIcon={
                        <LibraryIcon stroke="white" className="md:w-6" />
                      }
                      className="bg-comet h-12 md:text-xs xl:text-base md:h-10 xl:h-12 w-full"
                    >
                      Add to my library
                    </Button>
                  )}
                  <Button
                    radius={8}
                    onClick={async () => {
                      await getdesignId(id);
                      navigate("/view");
                    }}
                    rightIcon={<EyeIcon stroke="white" className="md:w-6" />}
                    className="bg-comet h-12 md:text-xs md:h-10 xl:text-base xl:h-12 w-full"
                  >
                    Interior View
                  </Button>

                  {location.pathname === `/editpage/library/${id}` && (
                    <Button
                      radius={8}
                      onClick={() => {
                        setIsEditModeOn({
                          ...isEditModeOn,
                          saveDesignEditMode: true,
                          colorEditMode: false,
                        });
                        setIsClickOnEditIcon(true);
                      }}
                      rightIcon={
                        <EditButton stroke="white" className="md:w-6" />
                      }
                      className="bg-comet h-12 md:text-xs xl:text-base md:h-10 xl:h-12 w-full"
                    >
                      Edit
                    </Button>
                  )}
                </>
              )}
          </div>
        )}
        {localStorage.getItem("role") === "User" && (
          <div className="mt-7 pb-5">
            <AccordionComponent
              designDetails={designDetails}
              colors={colors}
              setSampleSize={setSampleSize}
              sampleSize={sampleSize}
              setOpenedMenu={setOpenedMenu}
              openedMenu={openedMenu}
              setColorMap={setColorMap}
              colorMap={colorMap}
              isEditModeOn={isEditModeOn}
              setIsEditModeOn={setIsEditModeOn}
              onDesignNameChange={onDesignNameChange}
              handle={handle}
              addToLibrary={addToLibrary}
              designName={designName}
              isClickOnEditIcon={isClickOnEditIcon}
              setIsClickOnEditIcon={setIsClickOnEditIcon}
              accordionRef={accordionRef}
            />
          </div>
        )}
        {localStorage.getItem("role") === "SuperAdmin" && (
          <>
            <Text weight={600} color="#051C48">
              Colors
            </Text>
            <div className="p-2">
              <div style={{ display: "flex" }}>
                {colors?.map((color) => {
                  const colorName = colorData?.find(
                    (rawcolor) => rawcolor.colorCode === colorMap[color]
                  )?.name;

                  const targetColorCode = color;
                  const editData = colorData?.find(
                    (rawcolor) =>
                      rawcolor.colorCode.toLowerCase() ===
                      targetColorCode.toLowerCase()
                  );
                  const editcolordata = editData ? editData.name : "";

                  return (
                    <div
                      key={color}
                      style={{
                        marginRight: "20px",
                        textAlign: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "58px",
                          height: "58px",
                          cursor: "pointer",
                          display: "inline-block",
                        }}
                        className={cx({ [classes.active2]: color === active })}
                        onClick={() => {
                          showColorPicker(true, color);
                          setActive(color);
                        }}
                      >
                        <div
                          className={cx({ [classes.active]: color === active })}
                          style={{
                            width: "50px",
                            height: "50px",
                            cursor: "pointer",
                            backgroundColor: colorMap[color] || color,
                          }}
                        ></div>
                      </div>

                      <div
                        style={{
                          marginTop: "2px",
                          wordBreak: "break-word",
                          width: "70px",
                        }}
                      >
                        <Text size={"sm"}>{colorName || editcolordata}</Text>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <Flex justify="space-between">
                <Text weight={600} mb={10} color="#051C48">
                  Choose Color
                </Text>
                <Input
                  type="text"
                  placeholder="Search color..."
                  hidden={!displayColorPicker}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  mb={"md"}
                />
              </Flex>
              {displayColorPicker && (
                <ScrollArea
                  sx={{ height: 170 }}
                  style={{
                    backgroundColor: "white",
                    padding: "10px",
                    marginRight: "10px",
                  }}
                >
                  <SimpleGrid
                    cols={2}
                    spacing="xs"
                    breakpoints={[
                      { maxWidth: "md", cols: 2 },
                      { maxWidth: "sm", cols: 1 },
                    ]}
                  >
                    {rawColors}
                  </SimpleGrid>
                </ScrollArea>
              )}

              {
                localStorage.getItem("role") === "SuperAdmin" ? (
                  <>
                    <form>
                      <div className="p-2">
                        <Text
                          weight={600}
                          mt={10}
                          mb={10}
                          size={18}
                          color="#051C48"
                        >
                          Design Details
                        </Text>
                        <Grid>
                          <Grid.Col span={6}>
                            <TextInput
                              value={designDetails.title}
                              name="title"
                              onChange={onChange}
                              label="Design Name"
                              mb={10}
                              placeholder="Trellise"
                            />
                          </Grid.Col>
                          <Grid.Col span={6}>
                            <TextInput
                              value={designDetails.designNumber} // Make sure this is set
                              name="designNumber"
                              onChange={onChange} // Make sure onChange is assigned
                              label="Design Number"
                              mb={10}
                              placeholder="Design Number"
                            />
                          </Grid.Col>
                          <Grid.Col span={6}>
                            <Select
                              name="picksMtr"
                              value={designDetails.picksMtr}
                              label="Picks/MTR"
                              onChange={handleSelectChange} // Handle select change
                              mb={10}
                              placeholder="Picks/MTR"
                              data={[
                                { value: "230", label: "230" },
                                { value: "270", label: "270" },
                                { value: "320", label: "320" },
                                { value: "360", label: "360" },
                                { value: "400", label: "400" },
                              ]}
                            />
                          </Grid.Col>
                          <Grid.Col span={6}>
                            <TextInput
                              value={designDetails.repeatSize} // Make sure this is set
                              name="repeatSize"
                              onChange={onChange} // Make sure onChange is assigned
                              label="Repeat Size"
                              mb={10}
                              placeholder="Repeat Size"
                            />
                          </Grid.Col>
                        </Grid>
                        <div
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          {" "}
                          <Text
                            weight={400}
                            mt={10}
                            mb={8}
                            size={13}
                            color="black"
                          >
                            Choose Library
                          </Text>
                          <MultiSelect
                            data={dataa}
                            value={valuee}
                            placeholder="Choose Library"
                            onChange={setValue}
                          />
                          <div
                            onClick={open}
                            style={{
                              display: "flex",
                              marginLeft: "5px",
                              marginTop: "10px",
                              cursor: "pointer",
                            }}
                          >
                            <IconCirclePlus />
                            <Text color="black">Add Library</Text>
                          </div>
                        </div>
                        <div style={{ marginTop: "3%" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "10px",
                              flexWrap: "wrap",
                            }}
                          >
                            <Button
                              style={{ flex: "1" }}
                              variant="default"
                              // mt={5}
                              color="#051C48"
                              radius="sm"
                              size="sm"
                              onClick={() => {
                                navigate("/ArticleCard");
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              style={{ flex: "2" }}
                              variant="default"
                              radius="sm"
                              size="sm"
                              color="#051C48"
                              onClick={handleClick}
                            >
                              Save as new design
                            </Button>
                            <Button
                              style={{ flex: "1.5" }}
                              variant="default"
                              radius="sm"
                              size="sm"
                              color="#051C48"
                              onClick={() => setModalOpen(true)}
                            >
                              Upload New
                            </Button>

                            <Modal
                              opened={modalOpen}
                              onClose={() => setModalOpen(false)}
                              title="Upload an SVG File"
                              size="lg"
                              centered
                            >
                              <Dropzone
                                openRef={openRef}
                                onDrop={async (acceptedFiles) => {
                                  for (const file of acceptedFiles) {
                                    const fileURL = URL.createObjectURL(file);

                                    // Await the SVG content from the Blob URL conversion
                                    const svgData = await blobUrlToSvg(fileURL);

                                    setSvgString(svgData); // Store the new SVG content for preview
                                    setFileSource(fileURL); // Store the file URL if needed for other purposes

                                    // Extract dimensions and colors from the SVG content
                                    const reader = new FileReader();
                                    reader.onload = (event) => {
                                      const parser = new DOMParser();
                                      const svgDocument =
                                        parser.parseFromString(
                                          event.target.result,
                                          "image/svg+xml"
                                        );
                                      const svgElement =
                                        svgDocument.querySelector("svg");

                                      if (svgElement) {
                                        let width =
                                          svgElement.getAttribute("width");
                                        let height =
                                          svgElement.getAttribute("height");

                                        // If width/height are not available, extract from viewBox
                                        if (!width || !height) {
                                          const viewBox =
                                            svgElement.getAttribute("viewBox");
                                          if (viewBox) {
                                            const viewBoxValues =
                                              viewBox.split(" ");
                                            width = viewBoxValues[2];
                                            height = viewBoxValues[3];
                                          }
                                        }

                                        // Convert width and height to inches (assuming 96 DPI)
                                        const DPI = 96;
                                        const widthInInches = width
                                          ? (parseFloat(width) / DPI).toFixed(2)
                                          : null;
                                        const heightInInches = height
                                          ? (parseFloat(height) / DPI).toFixed(
                                              2
                                            )
                                          : null;

                                        // Store the dimensions in your context or state
                                        if (
                                          contextLib &&
                                          widthInInches &&
                                          heightInInches
                                        ) {
                                          contextLib.setSvgDimensions({
                                            width: widthInInches,
                                            height: heightInInches,
                                          });
                                        }
                                      }

                                      // Extract colors from the SVG
                                      const colors = svg.getColors(
                                        event.target.result
                                      );
                                      setColorMap(colors);
                                    };
                                    reader.readAsText(file);
                                  }
                                  // Close the modal after processing files
                                  setModalOpen(false);
                                }}
                                radius="md"
                                accept={[MIME_TYPES.svg]}
                                maxSize={30 * 1024 ** 2}
                              >
                                <div style={{ pointerEvents: "none" }}>
                                  <Group position="center">
                                    <Dropzone.Accept>
                                      <IconDownload
                                        size={50}
                                        color={
                                          theme.colors[theme.primaryColor][6]
                                        }
                                        stroke={1.5}
                                      />
                                    </Dropzone.Accept>
                                    <Dropzone.Reject>
                                      <IconX
                                        size={50}
                                        color={theme.colors.red[6]}
                                        stroke={1.5}
                                      />
                                    </Dropzone.Reject>
                                    <Dropzone.Idle>
                                      <IconCloudUpload
                                        size={70}
                                        color="#E27612"
                                        stroke={2}
                                      />
                                    </Dropzone.Idle>
                                  </Group>
                                  <Text
                                    align="center"
                                    weight={500}
                                    size={25}
                                    mt="xl"
                                  >
                                    <Dropzone.Idle>
                                      <span style={{ color: "#E27612" }}>
                                        Click here
                                      </span>{" "}
                                      to upload or drag and drop
                                    </Dropzone.Idle>
                                  </Text>
                                  <Text
                                    align="center"
                                    size={12}
                                    mt="xs"
                                    color="dimmed"
                                  >
                                    Supports: SVG Image
                                  </Text>
                                  <Text
                                    align="center"
                                    size={18}
                                    color="#E27612"
                                    mt={50}
                                  >
                                    Upload an SVG vector file for an accurate
                                    result.
                                  </Text>
                                </div>
                              </Dropzone>
                            </Modal>
                          </div>

                          <Button
                            fullWidth
                            mt="xs"
                            size="sm"
                            style={{ backgroundColor: "#E27612" }}
                            radius="sm"
                            onClick={handle}
                          >
                            Save Changes
                          </Button>
                          <div
                            style={{
                              color: "#E27612",
                              fontWeight: "bold",
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "15px",
                              fontSize: "15px",
                            }}
                            onClick={handeldelete}
                          >
                            <IconCirclePlus
                              style={{
                                transform: "rotate(315deg)",
                                marginRight: "5px",
                              }}
                            />
                            Delete Design
                          </div>
                        </div>
                      </div>
                    </form>
                    <Modal
                      opened={opened}
                      onClose={close}
                      size="xl"
                      title="Add library"
                    >
                      <Container size={700} py="xl">
                        <Text>Add Library</Text>
                        <TextInput
                          name="name"
                          value={first.name}
                          onChange={handleChange}
                          mb={20}
                        />
                        <Button
                          onClick={addlibrary}
                          mt="sm"
                          style={{
                            backgroundColor: "#E27612",
                            marginLeft: "90%",
                          }}
                        >
                          Save
                        </Button>
                      </Container>
                    </Modal>
                  </>
                ) : null
                // <div>
                //   <>
                //     <Text weight={600} mt={10} mb={10} size={18} color="#051C48">
                //       Design Details
                //     </Text>
                //     <TextInput
                //       value={designName}
                //       name="name"
                //       onChange={onDesignNameChange}
                //       label="Name"
                //       mb={10}
                //       placeholder="Trellise"
                //     />
                //     <Button
                //       fullWidth
                //       variant="default"
                //       mt={50}
                //       color="#051C48"
                //       radius="sm"
                //       size="sm"
                //       onClick={() => {
                //         navigate("/ArticleCard");
                //       }}
                //     >
                //       Cancel
                //     </Button>
                //     <Button
                //       fullWidth
                //       mt="xs"
                //       size="sm"
                //       style={{ backgroundColor: "#E27612" }}
                //       radius="sm"
                //       onClick={addToLibrary}
                //     >
                //       Save to my Library
                //     </Button>
                //   </>
                // </div>
              }
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SavenewChanges;
