export const downloadMergedImage = (
  canvasElement,
  mainImagePath,
  backgroundImagePathOrSvg,
  shouldDownload = false
) => {
  return new Promise((resolve, reject) => {
    if (!canvasElement) {
      console.error("Canvas element is not available, creating a new one.");
      canvasElement = document.createElement("canvas");
    }

    const ctx = canvasElement.getContext("2d");
    const mainImage = new window.Image();
    mainImage.crossOrigin = "Anonymous";
    mainImage.src = mainImagePath;

    mainImage.onload = () => {
      const scaleFactor = 0.5; // Adjust this value to reduce image size (0.5 = 50% smaller)
      const canvasWidth = (mainImage.width || 3000) * scaleFactor;
      const canvasHeight = (mainImage.height || 2000) * scaleFactor;
      canvasElement.width = canvasWidth;
      canvasElement.height = canvasHeight;

      const isSvgString = backgroundImagePathOrSvg.trim().startsWith("<svg");
      let backgroundImageSrc;

      if (isSvgString) {
        const encodedSvg = encodeURIComponent(backgroundImagePathOrSvg);
        backgroundImageSrc = `data:image/svg+xml;base64,${btoa(
          decodeURIComponent(encodedSvg)
        )}`;
      } else {
        backgroundImageSrc = backgroundImagePathOrSvg;
      }

      const background = new window.Image();
      background.crossOrigin = "Anonymous";
      background.src = backgroundImageSrc;

      background.onload = () => {
        const patternCanvas = document.createElement("canvas");
        const patternCtx = patternCanvas.getContext("2d");
        const patternWidth = 200;
        const patternHeight = 100;
        patternCanvas.width = patternWidth;
        patternCanvas.height = patternHeight;

        const patternImage = new Image();
        patternImage.crossOrigin = "Anonymous";
        patternImage.src = backgroundImageSrc;

        patternImage.onload = () => {
          patternCtx.drawImage(patternImage, 0, 0, patternWidth, patternHeight);
          const pattern = ctx.createPattern(patternCanvas, "repeat");
          ctx.fillStyle = pattern;
          ctx.fillRect(0, 0, canvasWidth, canvasHeight);
          ctx.drawImage(mainImage, 0, 0, canvasWidth, canvasHeight);

          const dataUrl = canvasElement.toDataURL("image/jpeg", 0.9); // Adjust quality (0.1 - 1)

          if (shouldDownload) {
            const link = document.createElement("a");
            link.href = dataUrl;
            link.download = "design.jpg"; // Save as .jpg for smaller file size
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }

          resolve(dataUrl.split(",")[1]); // Return the Base64 image data
        };

        patternImage.onerror = (e) => {
          console.error("Error loading pattern image:", e);
          reject(new Error("Error loading pattern image"));
        };
      };

      background.onerror = (e) => {
        console.error("Error loading background image:", e);
        reject(new Error("Error loading background image"));
      };
    };

    mainImage.onerror = (e) => {
      console.error("Error loading main image:", e);
      reject(new Error("Error loading main image"));
    };
  });
};
