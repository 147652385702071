import React from "react";

const DownloadIcon = ({
  stroke = "#636364",
  width = 27,
  height = 28,
  className = "",
  ref,
  onClick = () => {},
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 27 28"
      ref={ref}
      fill="none"
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.9429 14V22.16C25.99 23.2167 25.6167 24.2491 24.9045 25.0312C24.1924 25.8133 23.1994 26.2815 22.1429 26.3333H5.07625C4.01976 26.2815 3.02681 25.8133 2.31466 25.0312C1.60251 24.2491 1.22914 23.2167 1.27625 22.16V14"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6096 1.66675V18.0001"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6096 18.0001L18.9429 12.6667"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6096 18.0001L8.27625 12.6667"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DownloadIcon;
