import React, { useEffect, useState } from "react";
import { Text, Card, Title, Pagination, Loader } from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import CompanyColorContext from "../../../../ContextFolder/CompanyColorContext/CompanycolorContext";
import _ from "lodash";
import "../../../../App.css";
import { EditButton } from "../../../../Components/common/iconComponents/Icons";
import { DeleteButton } from "../../../../Components/common/iconComponents/Icons";
import { validPaths } from "../../../../utils/utils";

function ColorsCard() {
  const [isLoading, setIsLoading] = useState(false);
  const { getallcolorsdata, deletecolor } = useContext(CompanyColorContext);
  const [number, setNumber] = useState();
  const storedPage =
    parseInt(localStorage.getItem("currentColorPage"), 10) || 1;
  const [currentPage, setCurrentPage] = useState(storedPage);
  const [posts, setPosts] = useState();
  const limit = 21;

  useEffect(() => {
    const getColors = async () => {
      try {
        setIsLoading(true);
        const companyColor = await getallcolorsdata(currentPage);
        setPosts(companyColor.companyColors);
        setNumber(companyColor.count);
      } catch (error) {
        console.error("Error while fetching color data", error);
      } finally {
        setIsLoading(false);
      }
    };
    getColors();
  }, []);

  const pageCount = number ? Math.ceil(number / limit) : 0;
  const pages = _.range(1, pageCount + 1);
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("currentColorPage", currentPage.toString());
  }, [currentPage]);

  const setPage = async (num) => {
    const g = await getallcolorsdata(num);
    setPosts(g.companyColors);
    setCurrentPage(num);
  };

  const features = posts?.map((feature, index) => (
    <Card key={index} className="bg-lightGrey1 text-comet text-base">
      <Card.Section className="flex flex-col gap-y-1 items-center">
        <div
          style={{
            width: "100%",
            height: "82px",
            backgroundColor: `${feature.colorCode}`,
            color: `${feature.colorCode}`,
          }}
        />
        <Text>{feature.name}</Text>
        <div className="flex flex-row mt-2 w-full justify-evenly pb-3.5 ">
          <Link
            className="no-underline flex-row gap-x-1.5 flex"
            to={`/Updatecolor/${feature.id}`}
          >
            <EditButton stroke="#636364" />
            <Text>Edit</Text>
          </Link>
          <div
            className="cursor-pointer flex flex-row gap-x-1.5"
            onClick={async (e) => {
              e.preventDefault();
              const response = window.confirm(
                "Are you sure to delete this color?"
              );
              if (response) {
                const dataarray = await deletecolor(feature.id, posts);
                setPosts(dataarray);
              } else {
                navigate(validPaths.COMPANY_COLOR);
              }
            }}
          >
            <DeleteButton stroke="#636364" />
            <Text>Delete</Text>
          </div>
        </div>
      </Card.Section>
    </Card>
  ));
  return (
    <div className="flex flex-col px-3 mt-5">
      {isLoading ? (
        <Loader
          color="#D79963"
          size="lg"
          variant="oval"
          className="w-full flex justify-center items-center lex-row mt-44 md:mt-60"
        />
      ) : features?.length === 0 ? (
        <Title className="text-2xl font-extralight text-comet md:mt-49 xl:mt-54 text-center">
          No data
        </Title>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-8 gap-4 ">
          {features}
        </div>
      )}

      <div
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          marginTop: "40px",
          marginBottom: "10px",
        }}
      >
        {features?.length === 0 ? null : (
          <Pagination
            styles={{
              item: {
                "&[data-active]": { backgroundColor: "#D79963" },
              },
            }}
            page={currentPage}
            onChange={setPage}
            total={pages.length}
            withEdges={true}
          />
        )}
      </div>
    </div>
  );
}

export default ColorsCard;
