import {  Button, Text, Container } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import Bg from "../Image/Bg";
import Logo from "../Logo/Logo";

export default function NewAccount() {
 
  const navigate = useNavigate();
  return (
    <div style={{ display: "flex", width: "100%", height: "100vh" }}>
      <div style={{ height: "100vh", width: "100%", backgroundColor: "white" }}>
        {" "}
        <Logo />
        <Container size={500} style={{ marginTop: "200px" }}>
          <Text
            order={2}
            size={40}
            weight={500}
            align="center"
            mt="md"
            mb={10}
            color="#051C48"
          >
            New Account?
          </Text>
          <Text size={20}  weight={400} align="center" mb={50} color="#051C48">
            Write an email to support@wilton.in <br /> to get a new account for
            you.
          </Text>

          <Button
            fullWidth
            mt="lg"
            size="md"
            style={{ backgroundColor: "#E27612" }}
            onClick={() => {
              navigate("/login");
            }}
          >
            Sign in
          </Button>
        </Container>{" "}
      </div>{" "}
      <div style={{ height: "100%", width: "90%" }}>
        <Bg />
      </div>
    </div>
  );
}
