import { validSampleSizes } from "../../utils/utils";

export const productConstructions = [
  "Brussels",
  "Double density Brussels",
  "Float",
  "Double density Float",
  "Sculpture Loop",
  "Sculpture Cut and Loop",
  "Cut and Loop",
  "Full Cut",
]?.map((item) => ({
  label: item,
  value: item,
}));

export const pileTypes = ["100% Wool"].map((item) => ({
  label: item,
  value: item,
}));

export const sampleSizes = [
  { label: "1 X 2", value: validSampleSizes.FEET12 },
  { label: "2 X 3", value: validSampleSizes.FEET23 },
  { label: "3 X 4", value: validSampleSizes.FEET34 },
];
