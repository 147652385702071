import React from "react";
import Splashgif from "../../../Images/PreviewImage/logo-animation-final.gif";

const SplashPage = () => {
  return (
    <div
      className="flex items-center justify-center min-h-screen"
      style={{ backgroundColor: "#F5F5F5" }}
    >
      <div className="text-center">
        <img
          src={Splashgif}
          alt="Loading..."
          className="w-full max-w-xs md:max-w-md lg:max-w-lg mx-auto"
        />
      </div>
    </div>
  );
};

export default SplashPage;
