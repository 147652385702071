import React from "react";
import logo from "../../../Images/logo.png";

function Logo() {
  return (
    <>
      <img
        src={logo}
        style={{ width: "120px", marginTop: "10px", marginLeft: "10px" }}
        alt="not found"
      />
    </>
  );
}

export default Logo;
