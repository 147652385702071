import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Text,
  ScrollArea,
  Modal,
  TextInput,
  Group,
} from "@mantine/core";
import { useLocation, useNavigate } from "react-router-dom";
import CatelogueContext from "../../../ContextFolder/CatelogueContext/CatelogueContext";
import aircraft from "../../../Images/PreviewImage/aircraft-highquality.png";
import livingRoom from "../../../Images/PreviewImage/interior-highquality.png";
import "../../../App.css";
import * as svg from "../Designs/svg";
import CompanycolorContext from "../../../ContextFolder/CompanyColorContext/CompanycolorContext";
import {
  BurgerMenuIcon,
  CloseIcon,
  DownloadIcon,
  ShareIcon,
  ZoomInIcon,
  ZoomOutIcon,
} from "../../../Components/common/iconComponents/Icons";
import { validPaths } from "../../../utils/utils";
import { downloadMergedImage } from "../../../Components/common/function";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons";

const Designview = () => {
  const canvasRef = useRef(null);
  const [svgString, setSvgString] = useState("");
  const { getdesignbyid } = useContext(CatelogueContext);
  const navigate = useNavigate();
  const { getallcolorsdataForsomePages } = useContext(CompanycolorContext);
  const [colorData, setColorData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const zoomRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false); // State for loading
  const location = useLocation();

  useEffect(() => {
    if (getdesignbyid?.patternImage) {
      const fetchSVGString = async () => {
        setSvgString(`${getdesignbyid?.patternImage}`);
      };
      fetchSVGString();
    }
  }, [getdesignbyid?.patternImage]);

  const pathname = location.pathname === "/view";

  useEffect(() => {
    const getColors = async () => {
      const companyColor = await getallcolorsdataForsomePages({
        isActive: true,
      });
      setColorData(companyColor.companyColors);
    };
    getColors();
  }, [getallcolorsdataForsomePages, pathname]);

  const handleImageChange = (index) => {
    setSelectedImage(index);
    // Open the component only if the screen width is 1024px or greater
    if (window.innerWidth >= 1024) {
      setIsOpen(true);
    }
    // Other reset logic
    zoomRef.current?.resetTransform();
    setIsVisible(false);
  };

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      // Open the component if the screen width is 1024px or greater
      if (width >= 1024) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    };

    // Call the function once to set the initial state
    handleResize();

    // Listen for window resize events
    window.addEventListener("resize", handleResize);
    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const designId = getdesignbyid?.id;

  const blob = new Blob([svgString], {
    type: "image/svg+xml",
  });
  const url = URL.createObjectURL(blob);
  const colors = svg.getColors(svgString);

  const ImageCollection = [
    { imgPath: aircraft },
    { imgPath: livingRoom },
    // { imgPath: staircase },
  ];
  const handleEmailChange = (event) => {
    setEmail(event.currentTarget.value);
  };
  const handleShareDesign = async () => {
    setLoading(true); // Start loading
    const controller = new AbortController();
    const timeout = setTimeout(() => {
      controller.abort(); // Abort the fetch request if it takes too long
    }, 600000); // 10 minutes timeout (600,000 milliseconds)

    try {
      const canvasElement = canvasRef.current; // Ensure you have a reference to the canvas
      const mainImagePath = ImageCollection[selectedImage]?.imgPath;
      const backgroundImagePath = url;

      // Get Base64 image without downloading
      const base64Image = await downloadMergedImage(
        canvasElement,
        mainImagePath,
        backgroundImagePath,
        false // Do not download
      );
      // Make API call to share design with a custom timeout
      const response = await fetch(
        `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/designs/share-design`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ email, base64Image }),
          signal: controller.signal, // Attach the abort signal
        }
      );

      clearTimeout(timeout); // Clear the timeout when the request completes

      if (response.ok) {
        // Display success notification
        showNotification({
          title: "",
          message: "Your design has been shared successfully.",
          color: "green",
          icon: <IconCheck />, // Optional: add a checkmark icon
        });

        setModalVisible(false);
        setEmail(""); // Reset email input
      } else {
        alert("Error sharing design.");
      }
    } catch (error) {
      if (error.name === "AbortError") {
        alert("Request timed out.");
      } else {
        console.error("Error:", error);
        alert("An error occurred while sharing the design.");
      }
    } finally {
      setLoading(false); // Stop loading regardless of success or failure
    }
  };
  return (
    <div className="h-screen w-full flex flex-col  md:flex-row">
      <div className="ml-5 my-4.5 md:hidden block">
        <Button
          radius={8}
          onClick={() => {
            navigate(`${validPaths.EDIT_PAGE}/${designId}`);
          }}
          rightIcon={<CloseIcon width={27} />}
          className="bg-comet px-5 h-12 text-base "
        >
          Exit Interior View
        </Button>
      </div>
      <div className="w-full md:w-[55%] relative lg:w-[60%] h-screen xl:w-[70%] 2xl:w-[75%] flex flex-col md:gap-y-10">
        <div className="w-full z-9 sticky top-12">
          <div className="md:sticky  md:top-0 md:left-0">
            <div className="flex bg-midGrey flex-row h-16 px-6 justify-between items-center">
              <BurgerMenuIcon
                onClick={() => setIsOpen(!isOpen)}
                // stroke={isOpen ? "#D79963" : "#636364"}
                stroke={"#636364"}
                className="cursor-pointer"
              />
              <div className="flex gap-x-6">
                <canvas ref={canvasRef} className="hidden" />
                <ZoomInIcon
                  onClick={() => {
                    zoomRef?.current?.zoomIn();
                    !isVisible && setIsVisible(true);
                  }}
                  className="cursor-pointer"
                />
                <ZoomOutIcon
                  onClick={() => {
                    zoomRef?.current?.zoomOut();
                    !isVisible && setIsVisible(true);
                  }}
                  className="cursor-pointer"
                />
                {isVisible && (
                  <Button
                    size="xs"
                    onClick={() => {
                      zoomRef?.current?.resetTransform();
                      setIsVisible(false);
                    }}
                    radius={8}
                    className="bg-comet h-"
                  >
                    Reset Zoom
                  </Button>
                )}
                <ShareIcon
                  className="cursor-pointer"
                  onClick={() => setModalVisible(true)}
                />
                <Modal
                  opened={isModalVisible}
                  onClose={() => setModalVisible(false)}
                  title="Share Design"
                  centered
                >
                  <TextInput
                    label="Email"
                    placeholder="Enter email"
                    value={email}
                    onChange={handleEmailChange}
                    required="true"
                  />
                  <Group position="right" mt="md">
                    <Button
                      variant="outline"
                      onClick={() => setModalVisible(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleShareDesign}
                      loading={loading} // Add loading prop
                      disabled={!email} // Disable button if email is empty
                      variant="outline"
                    >
                      Share
                    </Button>
                  </Group>
                </Modal>
                <DownloadIcon
                  className="cursor-pointer"
                  onClick={async () => {
                    const canvasElement = canvasRef?.current;
                    const mainImagePath =
                      ImageCollection[selectedImage]?.imgPath;
                    const backgroundImagePath = url;

                    // Download the image
                    await downloadMergedImage(
                      canvasElement,
                      mainImagePath,
                      backgroundImagePath,
                      true
                    );
                  }}
                />
              </div>
            </div>
            {isOpen && (
              <div className="bg-lightGrey1 px-4 text-base text-comet space-y-3.5 py-3.5 absolute h-screen lg:w-[35%] xl:w-[25%]  ">
                <Text className="text-lg md:text-xl font-semibold">
                  Change Room
                </Text>
                <ScrollArea
                  className=" h-screen px-2"
                  type="hover"
                  scrollbarSize={6}
                  scrollHideDelay={50}
                >
                  <div className="flex h-screen flex-col items-center space-y-4">
                    {ImageCollection?.map((img, index) => (
                      <div
                        onClick={() => {
                          setSelectedImage(index);
                          setIsOpen(false);
                          zoomRef?.current?.resetTransform();
                          setIsVisible(false);
                          handleImageChange(index);
                        }}
                        className={` px-5 py-4 border bg-white ${
                          index === selectedImage
                            ? "border-whiskeySour"
                            : "border-midGrey"
                        }`}
                      >
                        {/* <img
                          src={img?.imgPath}
                          alt="Design preview"
                          className="w-full "
                          style={{
                            backgroundImage: `url(${url})`,
                            objectFit: "contain",
                          }}
                        /> */}

                        <div
                          className={`${
                            img.imgPath ===
                            "/static/media/interior-highquality.6c3ef2af686f502a7511.png"
                              ? "situation-box22"
                              : "situation-box11"
                          }`}
                        >
                          <div
                            className={`${
                              img.imgPath ===
                              "/static/media/interior-highquality.6c3ef2af686f502a7511.png"
                                ? "situation22"
                                : "situation11"
                            }`}
                          >
                            <div
                              className={`${
                                img.imgPath ===
                                "/static/media/interior-highquality.6c3ef2af686f502a7511.png"
                                  ? "situation-pattern-container22"
                                  : "situation-pattern-container11"
                              }`}
                            >
                              <div
                                className={`${
                                  img.imgPath ===
                                  "/static/media/interior-highquality.6c3ef2af686f502a7511.png"
                                    ? "situation-pattern22"
                                    : "situation-pattern11"
                                }`}
                                style={{ backgroundImage: `url(${url})` }}
                              ></div>
                            </div>
                          </div>
                          <div className="situation-image-box">
                            <img
                              src={img?.imgPath}
                              className="situation-image"
                              alt="not found"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </ScrollArea>
              </div>
            )}
          </div>
        </div>
        <div className="w-full md:w-11/12  flex flex-row justify-center  mx-auto">
          <TransformWrapper
            wheel={{ wheelDisabled: true, touchPadDisabled: true }}
            pinch={{ disabled: true }}
            doubleClick={{ disabled: true }}
            panning={{ disabled: !isVisible }} // Enable panning only if the reset button is visible
            ref={zoomRef}
            initialScale={1}
          >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <>
                <TransformComponent>
                  <div
                    className={`${
                      ImageCollection[selectedImage]?.imgPath ===
                      "/static/media/interior-highquality.6c3ef2af686f502a7511.png"
                        ? "situation-box2"
                        : "situation-box1"
                    }`}
                  >
                    <div
                      className={`${
                        ImageCollection[selectedImage]?.imgPath ===
                        "/static/media/interior-highquality.6c3ef2af686f502a7511.png"
                          ? "situation2"
                          : "situation1"
                      }`}
                    >
                      <div
                        className={`${
                          ImageCollection[selectedImage]?.imgPath ===
                          "/static/media/interior-highquality.6c3ef2af686f502a7511.png"
                            ? "situation-pattern-container2"
                            : "situation-pattern-container1"
                        }`}
                      >
                        <div
                          className={`${
                            ImageCollection[selectedImage]?.imgPath ===
                            "/static/media/interior-highquality.6c3ef2af686f502a7511.png"
                              ? "situation-pattern2"
                              : "situation-pattern1"
                          }`}
                          style={{ backgroundImage: `url(${url})` }}
                        ></div>
                      </div>
                    </div>
                    <div className="situation-image-box">
                      <img
                        src={ImageCollection[selectedImage]?.imgPath}
                        className="situation-image"
                        alt="not found"
                      />
                    </div>
                  </div>
                </TransformComponent>
              </>
            )}
          </TransformWrapper>
        </div>
      </div>
      <div className="w-full relative md:w-[45%] lg:w-[40%] xl:w-[30%] 2xl:w-[25%] mt-10 md:mt-4 md:order-first ">
        <Button
          radius={8}
          onClick={() => {
            navigate(`${validPaths.EDIT_PAGE}/${designId}`);
          }}
          rightIcon={<CloseIcon width={27} />}
          className="bg-comet hidden sticky top-15  md:block px-5 ml-5 h-12 mb-8 text-base "
        >
          Exit Interior View
        </Button>
        <div
          style={{ background: `url(${url})` }}
          className="w-[320px] h-[320px] mx-auto"
        />
        <Text className="text-center text-comet text-base mt-6 font-semibold">
          Preview (1 m x 1 m)
        </Text>
        <div
          className={`grid w-11/12 mx-auto mt-13 gap-y-3 gap-x-3 ${
            colors?.length === 1
              ? "grid-cols-1 justify-center"
              : colors?.length === 2
              ? "grid-cols-2 justify-center"
              : "grid-cols-3"
          }`}
        >
          {colors?.map((color, index) => {
            const colorName = colorData?.find(
              (rawColor) => rawColor.colorCode === color
            );
            return (
              <div
                key={index}
                className={`flex col-span-1 w-full py-5 mx-auto items-center justify-center gap-y-1.5 flex-col`}
              >
                <div
                  style={{ backgroundColor: color }}
                  className="w-14 rounded-lg h-14"
                />
                <Text className="text-comet text-xs">{colorName?.name}</Text>
                <Text className="text-comet text-xs">{colorName?.code}</Text>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default Designview;
