import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  header: {
    position: "sticky",
    top: 0,

    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    transition: "box-shadow 150ms ease",

    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: `1px solid ${
        theme.colorScheme === "dark"
          ? theme.colors.dark[3]
          : theme.colors.gray[2]
      }`,
    },
  },
  foravtar: {},
  backgroundColor: {
    backgroundColor: "#EBEFF7",
    minHeight: "100vh",
  },

  scrolled: {
    boxShadow: "revert",
  },
  inner: {
    minHeight: "50vh",
  },

  contactlist: {
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "7px",
    height: "100%",
  },

  emailflex: {
    display: "-webkit-box",
    justifyContent: "flex-end",
  },
  background: {
    backgroundColor: "#EBEFF7",
    minHeight: "100vh",
  },

  uploadfile: {
    [theme.fn.smallerThan("sm")]: {
      padding: "20px",
      marginTop: "20px",
    },
    [theme.fn.smallerThan("xs")]: {
      padding: "10px",
      marginTop: "20px",
    },
    marginTop: "50px",
    backgroundColor: "white",
    padding: "30px",

    [theme.fn.smallerThan("md")]: {
      padding: "30px",
      marginTop: "70px",
    },
  },
  containersize: {
    minHeight: "100vh",
  },

  Addlibbackground: {
    backgroundColor: "#EBEFF7",
    minHeight: "100vh",
  },

  Addlibuploadfile: {
    marginTop: "90px",
    backgroundColor: "white",
    padding: "50px",
    minHeight: "50vh",
    minWidth: "10vh",
  },
  title: {
    fontSize: 34,
    fontWeight: 900,
    [theme.fn.smallerThan("sm")]: {
      fontSize: 24,
    },
  },
  cardDisplay: {
    display: "flex",
    marginTop: "6px",
    justifyContent: "space-around",
  },

  description: {
    maxWidth: 600,
    margin: "auto",

    "&::after": {
      content: '""',
      display: "block",
      // backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  card: {
    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
  },

  cardTitle: {
    "&::after": {
      content: '""',
      display: "block",

      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
    },
  },
  image: {
    minHeight: "50px",
    minWidth: "50px",
  },
  imageSection: {
    paddingRight: "3px",
    paddingLeft: "3px",
    paddingBottom: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  catalogue: {
    /* 25 / Normal */
    color: "#051C48",
    fontFamily: "",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "25px",
  },
  navtype: {
    display: "flex",
    marginTop: "10px",
    justifyContent: "space-between",
  },
  menu: {
    marginRight: "10px",
    background: "#FFFFFF",
    color: "#7C93BC",
    alignItems: "center",
    padding: "10px 15px",
    borderRadius: "4px",
    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[2]
    }`,
    // [theme.fn.smallerThan("sm")]: {

    // },
  },
  carddisplay: {
    display: "flex",
    justifyContent: "space-between",
  },
  innercard: {
    display: "flex",
    justifyContent: "space-between",
    minHeight: "100vh",
    [theme.fn.smallerThan("lg")]: {
      minHeight: "70vh",
    },
    [theme.fn.smallerThan("md")]: {
      minHeight: "50vh",
    },
    [theme.fn.smallerThan("sm")]: {
      minHeight: "40vh",
    },
    [theme.fn.smallerThan("xs")]: {
      minHeight: "30vh",
    },
  },

  head: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `system-ui, ${theme.fontFamily}`,
    fontSize: "39px",
    lineHeight: "120%",
    fontWeight: 500,
    fontStyle: "normal",

    [theme.fn.smallerThan("xs")]: {
      fontSize: 28,
    },
  },

  ige: {
    // objectFit: "cover",
    width: " 100%",
    height: "100%",
    [theme.fn.smallerThan("lg")]: {
      minHeight: "70vh",
    },
    [theme.fn.smallerThan("md")]: {
      minHeight: "50vh",
    },
    [theme.fn.smallerThan("sm")]: {
      minHeight: "40vh",
    },
    [theme.fn.smallerThan("xs")]: {
      minHeight: "30vh",
    },
    [theme.fn.smallerThan("lg")]: {
      display: "none",
    },
  },

  form: {
    borderRight: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    // minHeight: "70vh",
    minWidth: "50%",
    [theme.fn.smallerThan("lg")]: {
      minHeight: "50vh",
    },
    [theme.fn.smallerThan("md")]: {
      minHeight: "50vh",
    },
    [theme.fn.smallerThan("sm")]: {
      minHeight: "30vh",
    },
    [theme.fn.smallerThan("xs")]: {
      minHeight: "10vh",
    },
    [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
      minWidth: "100%",
    },
  },
  buttonColor: {
    background: " #E27612",
  },
  margintop: {
    marginTop: "150px",
    [theme.fn.smallerThan("sm")]: {
      marginTop: "70px",
    },
    [theme.fn.smallerThan("xs")]: {
      marginTop: "0px",
    },
    [theme.fn.smallerThan("lg")]: {
      marginTop: "100px",
    },
  },
  logo: {
    fontFamily: "system-ui",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "30.8375px",
    marginLeft: "5px",
    /* or 51px */

    letterSpacing: "0.005em",

    /* Black */

    color: "#051C48",
  },
  searchnav: {
    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
  },
  logonav: {
    fontFamily: "system-ui",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20.8375px",
    marginLeft: "5px",
    /* or 51px */

    letterSpacing: "0.005em",

    /* Black */

    color: "#051C48",
  },
  logo2: {
    fontFamily: "system-ui",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "15.8053px",
    marginTop: "-15px",
    marginLeft: "10px",
    /* identical to box height, or 25px */

    letterSpacing: "0.295em",

    /* Black */

    color: "#051C48",
  },
  logo2nav: {
    fontFamily: "system-ui",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "15.8053px",
    marginTop: "-15px",
    marginLeft: "10px",
    /* identical to box height, or 25px */

    letterSpacing: "0.295em",

    /* Black */

    color: "#051C48",
  },
  editaccuploadfile: {
    marginTop: "70px",
    backgroundColor: "white",
    padding: "50px",
    minHeight: "70vh",
    minWidth: "10vh",
  },
  Switch: {
    display: "flex",
    justifyContent: "flex-start",
    [theme.fn.smallerThan("xs")]: {
      display: "grid",
    },
  },
  user: {
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
    padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
    borderRadius: theme.radius.sm,
    transition: "background-color 100ms ease",

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
    },

    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
  },
  userActive: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
  },
  button: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  menuControl: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    border: 0,
    borderLeft: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white
    }`,
  },
  DesignUploaded: {
    backgroundColor: "white",
  },

  designdcs: {
    paddingLeft: "10px",
    minWidth: "25%",
    [theme.fn.smallerThan("xl")]: {
      minWidth: "25%",
    },
    [theme.fn.smallerThan("lg")]: {
      minWidth: "27%",
    },
    [theme.fn.smallerThan("md")]: {
      minWidth: "30%",
    },
    [theme.fn.smallerThan("sm")]: {
      minWidth: "30%",
    },
    [theme.fn.smallerThan("xs")]: {
      minWidth: "10%",
    },
  },
  d2c: { paddingLeft: "1%", width: "30%", height: "100vh" },
  active: {
    // padding:"5px",

    // border: "2px solid grey ",
    // borderColor: "grey",
    borderRadius: "7px",
  },
  activeforsituation: {
    border: "7px solid #E27612 ",
  },
  active2: {
    border: "2px solid #051C48 ",
    padding: "2.2px",
    // paddingTop: "2px",
    // paddingLeft: "2px",
    borderRadius: "10px",
  },
  designouter: {
    [theme.fn.smallerThan("xl")]: {
      minWidth: "75%",
    },
    [theme.fn.smallerThan("lg")]: {
      minWidth: "72%",
    },
    [theme.fn.smallerThan("md")]: {
      minWidth: "70%",
    },
    [theme.fn.smallerThan("sm")]: {
      minWidth: "60%",
      minHeight: "80vh",
    },
    [theme.fn.smallerThan("xs")]: {
      minWidth: "50%",
      minHeight: "60vh",
    },

    // backgroundColor: "white",

    // minHeight: "90vh",
    // minWidth: "75%",
  },

  bgdesign: {
    [theme.fn.smallerThan("xl")]: {
      minWidth: "75%",
    },
    [theme.fn.smallerThan("lg")]: {
      minWidth: "72%",
    },
    [theme.fn.smallerThan("md")]: {
      minWidth: "70%",
    },
    [theme.fn.smallerThan("sm")]: {
      minWidth: "60%",
      minHeight: "80vh",
    },
    [theme.fn.smallerThan("xs")]: {
      minWidth: "50%",
      minHeight: "60vh",
    },

    backgroundColor: "white",

    minHeight: "90vh",
    minWidth: "75%",
  },
  mainLinkActive: {},
  uploadeddesign: {
    height: "100%",
    width: "100%",
    backgroundColor: "white",
    padding: "10px",
  },
  backofdesign: {
    padding: "10px",
    backgroundColor: "#F5F7FB",
  },
  colors: {
    width: "40px",
    height: "40px",
    border: "2px solid rgba(0, 0, 0, 0.19)",
    borderRadius: "12px",
    display: "inline-block",
    margin: "10px",
  },
  design: {
    marginTop: "20px",
    marginBottom: "5px",
    width: "190px",
    height: "220px",
  },

  colorchange: {
    height: "100%",

    backgroundColor: "#F5F7FB",
    padding: "20px",
  },
  pointer: {
    cursor: "pointer",
  },
  logobetween: {
    [theme.fn.smallerThan("md")]: {
      justify: "center",
      align: "center",
    },
  },

  dropzoneActive: {
    border: "2px solid red",
    backgroundColor: "#ffad99",
  },
  checkboxdiv: {
    backgroundColor: "#EBEFF7",
    height: "80px",
    width: "80px",
    borderRadius: "5px",
    border: "1px solid #A5BBE2",
    fontSize: "12px",
    textAlign: "center",
  },

  checkboxactive: {
    backgroundColor: "#FBE8D6",
    height: "80px",
    width: "80px",
    borderRadius: "5px",
    border: "1px solid #E27612",
    fontSize: "12px",
    color: "#E27612",
    textAlign: "center",
  },
}));

export default useStyles;
