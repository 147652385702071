import React from "react";
import { Link } from "react-router-dom";
import { Button, Text } from "@mantine/core";
import { IconCirclePlus } from "@tabler/icons";

function Newdesigntag() {
  return (
    <div
      style={{
        position: "absolute", // fix the button at a specific position
        right: "20px", // adjust the right position
        marginTop: "10px", // add some space from the top
      }}
    >
      <Link to="/UploadFile">
        <Button style={{ backgroundColor: "#E27612", borderRadius: "32px" }}>
          <IconCirclePlus />
          <Text color="white" ml={5} mr={5}>
            New designs
          </Text>
        </Button>
      </Link>
    </div>
  );
}

export default Newdesigntag;
