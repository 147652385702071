import { z } from "zod";

const ForgotPassSchema = z.object({
  email: z
    .string()
    .refine((val) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(val), {
      message: "Invalid Email.",
    }),


 
});

export default ForgotPassSchema;