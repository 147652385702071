import React from 'react'
import { Navigate } from 'react-router-dom';


const RoutesMiddlewareAdmin = ({children}) => {
 
    return localStorage.getItem("role") === "SuperAdmin" ? (
        children
      ) : (
      <Navigate to="/login"/>
      );
 
}

export default RoutesMiddlewareAdmin
