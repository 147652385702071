import React, { useContext, useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import { Container, Text, TextInput, Switch, Button } from "@mantine/core";
import useStyles from "../../../Components/Style/UseStyle";
import { useNavigate, useParams } from "react-router-dom";
import LibraryContext from "../../../ContextFolder/LibraryContext/LibraryContext";

function EditLib() {
  const { classes } = useStyles();
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const context = useContext(LibraryContext);
  const { id } = useParams();

  const form = useForm({ initialValues: { name: "", status: false } });
  useEffect(() => {
    const getlibByid = async () => {
      const data = await context.getlibByid(id);
      form.setValues({ name: data?.name, status: data?.status });
    };

    getlibByid();
    // eslint-disable-next-line
  }, []);
  return (
    <div className={classes.Addlibbackground}>
      <Container size="sm" py="xl">
        <div className={classes.uploadfile}>
          <Text size={25} mb={20} color="#051C48">
            Edit Library
          </Text>

          <form
            onSubmit={form.onSubmit(async (values) => {
              if (!values.name) {
                alert("please enter library");
              } else {
                await context.updatelib(id, values.name, values.status);
                navigate("/Libraries");
              }
            })}
          >
            <Text weight={500} size={14} color="#051C48" mb={10}>
              Name
            </Text>
            <TextInput
              placeholder="Enter Name"
              // label="Name"
              color="#051C48"
              required
              name="name"
              id="name"
              sx={{ flex: 1 }}
              mb={20}
              {...form.getInputProps("name")}
            />
            <Text weight={500} size={14} color="#051C48">
              Status
            </Text>
            <Switch
              label="Active"
              color="orange"
              mb={30}
              name="status"
              id="status"
              
              {...form.getInputProps("status", {
                type: "checkbox",
              })}
            />

            <hr style={{ color: "#A5BBE2" }} />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                mt="md"
                variant="outline"
                onClick={() => {
                  navigate("/Libraries");
                }}
              >
                Cancel
              </Button>
              <div style={{ justifyContent: "flex-end" }}>
                {" "}
                <Button
                  mt="md"
                  mr={4}
                  variant="default"
                  color="#051C48"
                  onClick={async (e) => {
                    e.preventDefault();
                    const response = window.confirm(
                      "Are you sure to delete this Library?"
                    );
                    if (response) {
                      const dataarray = await context.deletelib(id, data);
                      setData(dataarray);
                      navigate("/Libraries");
                    } else {
                      navigate(`/EditLib/${id}`);
                    }
                  }}
                >
                  Delete
                </Button>
                <Button
                  type="submit"
                  mt="md"
                  style={{
                    backgroundColor: "#E27612",
                    padding: "10px",
                    marginLeft: "4px",
                  }}
                >
                  Save
                </Button>{" "}
              </div>
            </div>
          </form>
        </div>
      </Container>
    </div>
  );
}

export default EditLib;
