export const getallcolorCategoriesData = async (isActive = "") => {
  try {
    const token = localStorage.getItem("token");

    const response = await fetch(
      `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/color-category?limit=999&offset=1&isActive=${isActive}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const colorCategories = await response.json();
    return colorCategories;
  } catch (error) {
    console.error("Error occurred:", error); // Log any errors
    return null; // Return null or some default value if error occurs
  }
};

export const AddColorCategory = async (name, colorCode, order = 0) => {
  try {
    const parsedOrder = Number(order);
    const response = await fetch(
      `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/color-category`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: ` Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ name, colorCode, order: parsedOrder }),
      }
    );
    const res = await response.json();
    return res;
  } catch {
    console.log("something wrong!!");
  }
};

export const UpdatedColorCategory = async (
  id,
  name,
  colorCode,
  status,
  order = 0
) => {
  try {
    const parsedOrder = Number(order);

    const response = await fetch(
      `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/color-category/${id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: ` Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          name,
          colorCode,
          status,
          order: parsedOrder,
        }),
      }
    );
    await response.json();
  } catch {
    console.log("something wrong!!");
  }
};

export const getColorCategoryById = async (id) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/color-category/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: ` Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    const colorbyid = await response.json();
    return colorbyid;
  } catch {
    console.log("something wrong!!");
  }
};

export const DeleteColorCategory = async (id) => {
  const response = await fetch(
    `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/color-category/${id}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: ` Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  await response.json();
};

export const updateOrder = async (updateOrderDto) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_WILTON_BACKEND_API_BASE_URL}/color-category/update-order`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(updateOrderDto),
      }
    );
    if (!response.ok) {
      const errorText = await response.text();
      console.error("Error response:", errorText);
      throw new Error("Failed to update order");
    }
    const contentType = response.headers.get("Content-Type");
    if (contentType && contentType.includes("application/json")) {
      return await response.json();
    }
    return {}; // Handle empty response case
  } catch (error) {
    console.error("Error occurred:", error);
    throw error;
  }
};
